import { BaseModel } from "./base-model";

export class AppointmentModel extends BaseModel {

  uuidCustomer: string = ""
  begin: Date = new Date()
  end: Date = new Date()
  durationHours: number = 0
  subject: string = ""
  location: string = ""
  remark: string = ""

  constructor(params: any = null) {
    super(params)
    this.copyFromObject(params)
  }

  static override newModel(object: any = null) {
    return new AppointmentModel(object)
  }
}
