import { CommonsService } from "../commons-service";
import { BaseModel } from "./base-model";

export class TimeRecordingModel extends BaseModel {
  uuidOrder: string = ""
  uuidMaintenance: string = ""
  uuidCustomer: string = ""
  begin: Date = new Date();
  textBegin = ""
  end = new Date();
  textEnd = ""
  duration: number = 0 // hours
  textDuration = ""

  constructor(params: any = null) {
    super(params)
    this.copyFromObject(params)

    this.textBegin = CommonsService.formatDateTime0(this.begin)
    this.textEnd = CommonsService.formatDateTime0(this.end)

    this.textDuration = CommonsService.formatHoursMinutes0(this.duration)
  }

  static override newModel(params: any = null): TimeRecordingModel {
    return new TimeRecordingModel(params)
  }
}
