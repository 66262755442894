import { Component, OnInit } from '@angular/core';
import { ViewArticleComponent } from '../view-article/view-article.component';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RepositoryService } from '../repository.service';
import { ParametersStartService } from '../parameters-start.service';
import { NetworkService } from '../network.service';
import { UUID } from 'angular2-uuid';
import { CommonsService } from '../commons-service';

@Component({
  selector: 'app-add-article',
  templateUrl: '../view-article/view-article.component.html',
  styleUrls: ['./add-article.component.css']
})
export class AddArticleComponent extends ViewArticleComponent implements OnInit {

  override showStockInfoButton = false;

  constructor(
    translate: TranslateService,
    route: ActivatedRoute,
    router: Router,
    repository: RepositoryService,
    parametersStart: ParametersStartService,
    networkService: NetworkService
  ) { super(translate, route, router, repository, parametersStart, networkService) }

  override ngOnInit(): void {
    super.ngOnInit()
  }

  override async initSpecific() {
    this.translate.get("ARTICLE_ADD_TITLE").subscribe((translated: string) => {
      this.textHeaderPage = translated
    })

    this.article.uuidArticlegroup = this.articleGroups[0].uuid
    this.article.uuid = UUID.UUID();
    this.article.uuidTaxRate = this.taxRates[0].uuid
    this.article.taxRate = this.taxRates[0];
    CommonsService.setPrices(this.article, this.parametersStart.currency)
    this.standardDBPercent100 = 100.0 * this.article.standardDBPercent;
  }

  override async saveSpecific() {
    await this.repository.articles.create(this.article)
    CommonsService.showSuccessMessage(this.translate.instant("GENERAL_SAVED"))
    this.router.navigateByUrl("/viewArticle/" + this.article.uuid)
  }
}
