import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { CommonsService } from '../commons-service';
import { ParametersStartService } from '../parameters-start.service';
import { SaleModel } from '../models/sale-model';
import { RepositoryService } from '../repository.service';
import { OfferPositionModel } from '../models/offer-position-model';
import { OrderPositionModel } from '../models/order-position-model';
import { InvoicePositionModel } from '../models/invoice-position-model';
import { OfferModel } from '../models/offer-model';
import { OrderModel } from '../models/order-model';
import { InvoiceModel } from '../models/invoice-model';
import { ModelFactory } from '../models/model-factory';
import { ModelInterface } from '../models/model-interface';
import { SalePositionModel } from '../models/sale-position-model';
import { DataNotAvailableError } from '../data-not-available-error';
declare const log: any;

@Component({
  selector: 'app-edit-sale-position',
  templateUrl: './edit-sale-position.component.html',
  styleUrls: ['./edit-sale-position.component.css']
})
export class EditSalePositionComponent implements OnInit {

  typeSale = "Offer"
  uuidSalePosition = ""
  isSaleNew = false

  salePosition: SalePositionModel = new SalePositionModel()
  salePosBeforeEdit: SalePositionModel = new SalePositionModel();
  amount = 1;
  discount = "0"

  constructor(
    protected router: Router,
    private route: ActivatedRoute,
    private modelFactory: ModelFactory<ModelInterface>,
    private repository: RepositoryService,
    private parametersStart: ParametersStartService
  ) { }

  ngOnInit(): void {
    this.init()
  }

  async init() {
    const routeParams = this.route.snapshot.paramMap;
    this.typeSale = String(routeParams.get('type'));
    this.uuidSalePosition = String(routeParams.get('uuidSalePosition'));
    this.isSaleNew = (routeParams.get('isSaleNew')) == "true";

    if (this.typeSale == "Offer") {
      this.salePosition = await this.repository.readOfferPosition(this.uuidSalePosition)
      this.salePosBeforeEdit = new OfferPositionModel(this.salePosition)
    } else if (this.typeSale == "Order") {
      this.salePosition = await this.repository.readOrderPosition(this.uuidSalePosition)
      this.salePosBeforeEdit = new OrderPositionModel(this.salePosition)
    } else if (this.typeSale == "Invoice") {
      this.salePosition = await this.repository.readInvoicePosition(this.uuidSalePosition)
      this.salePosBeforeEdit = new InvoicePositionModel(this.salePosition)
    } else {
      throw new DataNotAvailableError("Data not available of type " + this.typeSale)
    }

    let taxRates = await this.repository.taxRates.readAll()
    let taxRate = CommonsService.getObjectByUuid(taxRates, this.salePosition.uuidTaxRate)

    if(!taxRate) {
      let article = await this.repository.articles.read(this.salePosition.uuidArticle)
      if(article) {
        taxRate = CommonsService.getObjectByUuid(taxRates, article.uuidTaxRate)
      }
    }

    this.salePosition.taxRate = taxRate
    this.salePosition.priceUnitGrossV = this.salePosition.priceUnitNetV * (1.0 + this.salePosition.getTaxFactor())
    CommonsService.setPrices(this.salePosition, this.parametersStart.currency)

    log("the position")
    log(this.salePosition)

    this.amount = this.salePosition.amount;
    this.discount = "" + 100.0 * this.salePosition.discount; // convert into percent
  }

  changeDiscount() {
    log("discount changed: " + this.discount);
    this.discount = CommonsService.checkNumberString(this.discount)
    this.salePosition.discount = +this.discount.replace(',', '.') / 100.0;
    log("discount to calculate with: " + this.salePosition.discount);
  }

  changeAmount() {
    log("amount changed: " + this.amount);
    this.salePosition.amount = this.amount;
  }

  changeNet() {
    // recalc price gross
    log("changed net A", this.salePosition.priceUnitNet, this.salePosition.getTaxFactor())
    var priceAndCurrencyNet = this.salePosition.priceUnitNet.split(" ");

    if (priceAndCurrencyNet.length === 1)
      if (priceAndCurrencyNet[0] != this.parametersStart.currency)
        this.salePosition.priceUnitNet += " " + this.parametersStart.currency;
      else
        this.salePosition.priceUnitNet = "0,00 " + this.parametersStart.currency;

    var pUNet = CommonsService.valueAndCurrencyMult(this.salePosition.priceUnitNet, 1.0);
    var pUGross = CommonsService.valueAndCurrencyMult(this.salePosition.priceUnitNet, 1.0 + this.salePosition.getTaxFactor());

    log("changed net, gross is " + pUGross);
    this.salePosition.priceUnitGross = pUGross;
    this.salePosition.priceUnitNet = pUNet;
  };

  // price gross was edited by user
  changeGross() {
    // recalc price net
    log("changed gross A", this.salePosition.priceUnitGross)
    var priceAndCurrencyGross = this.salePosition.priceUnitGross.split(" ");

    if (priceAndCurrencyGross.length === 1)
      if (priceAndCurrencyGross[0] != this.parametersStart.currency)
        this.salePosition.priceUnitGross += " " + this.parametersStart.currency;
      else
        this.salePosition.priceUnitGross = "0,00 " + this.parametersStart.currency;

    var pUGross = CommonsService.valueAndCurrencyMult(this.salePosition.priceUnitGross, 1.0);
    var pUNet = CommonsService.valueAndCurrencyMult(this.salePosition.priceUnitGross, 1.0 / (1.0 + this.salePosition.getTaxFactor()));

    log("changed gross, net is " + pUNet);
    this.salePosition.priceUnitNet = pUNet;
    this.salePosition.priceUnitGross = pUGross;
  }

  async save() {
    var priceAndCurrencyNet = this.salePosition.priceUnitNet.split(" ");
    var priceAndCurrencyGross = this.salePosition.priceUnitGross.split(" ");

    while (priceAndCurrencyNet[0].indexOf('.') >= 0)
      priceAndCurrencyNet[0] = priceAndCurrencyNet[0].replace('.', '');
    while (priceAndCurrencyGross[0].indexOf('.') >= 0)
      priceAndCurrencyGross[0] = priceAndCurrencyGross[0].replace('.', '');

    this.salePosition.priceUnitNetV = parseFloat(priceAndCurrencyNet[0].replace(",", "."));
    this.salePosition.priceUnitGrossV = parseFloat(priceAndCurrencyGross[0].replace(",", "."));
    this.salePosition.taxUnitV = this.salePosition.priceUnitGrossV - this.salePosition.priceUnitNetV;
    this.salePosition.priceSumNetV = this.salePosition.priceUnitNetV * this.salePosition.amount *
      (1.0 - this.salePosition.discount);		// _17
    this.salePosition.priceSumNetV = Math.round(this.salePosition.priceSumNetV * 100.0) / 100.0;
    this.salePosition.sumArticleNetV = this.salePosition.priceSumNetV;	// _11
    this.salePosition.priceSumGrossV = this.salePosition.priceUnitGrossV * this.salePosition.amount *
      (1.0 - this.salePosition.discount);
    this.salePosition.taxSumV = this.salePosition.priceSumNetV * this.salePosition.getTaxFactor(); // _13
    this.salePosition.taxSumV = Math.round(this.salePosition.taxSumV * 100.0) / 100.0;

    this.salePosition.costPriceTotalV = this.salePosition.costPriceUnitV * this.salePosition.amount;
    this.salePosition.costPriceTotalV = Math.round(this.salePosition.costPriceTotalV * 100.0) / 100.0

    this.salePosition.purchasePriceTotalV =
      this.salePosition.purchasePriceUnitV * this.salePosition.amount;
    this.salePosition.purchasePriceTotalV = Math.round(this.salePosition.purchasePriceTotalV * 100.0) / 100.0

    this.salePosition.db1V = this.salePosition.priceSumNetV - this.salePosition.costPriceTotalV; // _29
    this.salePosition.db1V = Math.round(this.salePosition.db1V * 100.0) / 100.0;
    this.salePosition.db2V = this.salePosition.priceSumNetV - this.salePosition.purchasePriceTotalV; // _30
    this.salePosition.db2V = Math.round(this.salePosition.db2V * 100.0) / 100.0;

    // _73 = _30 / _11
    this.salePosition.db2Percent = this.salePosition.db2V / this.salePosition.sumArticleNetV;

    // update the sale
    let sale: SaleModel = new SaleModel()
    let saleBeforeEdit: SaleModel = new SaleModel()

    if (this.typeSale == "Offer") {
      sale = await this.repository.readOffer(this.salePosition.uuidParent)
    } else if (this.typeSale == "Order") {
      sale = await this.repository.readOrder(this.salePosition.uuidParent)
    } else if (this.typeSale == "Invoice") {
      sale = await this.repository.readInvoice(this.salePosition.uuidParent)
    }
    saleBeforeEdit = sale.clone(this.modelFactory)

    sale.replaceSalePosition(this.salePosition.uuid, this.salePosition, this.parametersStart.currency)

    if (this.typeSale == "Offer") {
      await this.repository.updateOffer(<OfferModel>saleBeforeEdit, <OfferModel>sale)
    } else if (this.typeSale == "Order") {
      await this.repository.updateOrder(<OrderModel>saleBeforeEdit, <OrderModel>sale)
    } else if (this.typeSale == "Invoice") {
      await this.repository.updateInvoice(<InvoiceModel>saleBeforeEdit, <InvoiceModel>sale)
    }

    this.router.navigateByUrl("/editSale/" + this.typeSale + "/" + this.salePosition.uuidParent + "/" + this.isSaleNew)
  }

}
