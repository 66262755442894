import { BaseModel } from "./base-model"

export class ArticleGroupModel extends BaseModel {
  active: boolean = true
  name: string = ""

  constructor(params: any = null) {
    super(params)
    this.copyFromObject(params)
  }

  static override newModel(object: any = null) {
    return new ArticleGroupModel(object)
  }
}
