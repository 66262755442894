<div role="main">
  <div class="">
    <div class="page-title">
      <div class="title_left">
        <h3>{{ textTitle }}</h3>
      </div>
    </div>
  </div>

  <div class="clearfix"></div>

  <div class="x_panel">
    <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
      <div class="form-horizontal form-label-left">
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="select-sex"
            >{{ "APPOINTMENT_EDIT_CUSTOMER" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <span
              class="cursor-pointer move-down-button-row"
              [hidden]="!showLinkCustomer"
              (click)="gotoCustomer()"
              title="{{
                'APPOINTMENT_EDIT_CUSTOMER_TOOLTIP_CUSTOMER' | translate
              }}"
            >
              {{ nameCustomer }}
            </span>
            <span
              class="cursor-pointer button-row move-down-button-row"
              [hidden]="!showLinkCustomer"
              (click)="gotoCustomer()"
              title="{{
                'APPOINTMENT_EDIT_CUSTOMER_TOOLTIP_CUSTOMER' | translate
              }}"
              ><i class="mdi mdi-account color-main"></i
            ></span>

            <button
              class="btn btn-default button-row"
              (click)="selectCustomer()"
              title="{{
                'APPOINTMENT_EDIT_CUSTOMER_TOOLTIP_SEARCH' | translate
              }}"
            >
              <i class="mdi mdi-magnify"></i>
            </button>
            <button
              [hidden]="!showLinkCustomer"
              class="btn btn-default button-row"
              (click)="clearCustomer()"
              title="{{
                'APPOINTMENT_EDIT_CUSTOMER_TOOLTIP_CLEAR' | translate
              }}"
            >
              <i class="mdi mdi-cancel"></i>
            </button>
          </div>
        </div>

        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-date-begin"
          >
            {{ "APPOINTMENT_EDIT_BEGIN" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <div class="input-group date" id="div-date-begin">
              <input
                id="input-date-begin"
                type="text"
                class="form-control"
                placeholder="dd.mm.yyyy HH:MM"
                value="{{ textBegin }}"
              />
              <!-- onkeydown="return false" -->
              <span class="input-group-addon">
                <span class="glyphicon glyphicon-calendar"></span>
                <!--i class="mdi mdi-calendar icon-datepicker"></i-->
              </span>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-date-end"
          >
            {{ "APPOINTMENT_EDIT_END" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <div class="input-group date" id="div-date-end">
              <input
                id="input-date-end"
                type="text"
                class="form-control"
                placeholder="dd.mm.yyyy HH:MM"
                value="{{ textEnd }}"
              />
              <!-- onkeydown="return false" -->
              <span class="input-group-addon">
                <span class="glyphicon glyphicon-calendar"></span>
                <!--i class="mdi mdi-calendar icon-datepicker"></i-->
              </span>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="textarea-subject"
          >
            {{ "APPOINTMENT_EDIT_SUBJECT" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <textarea
              class="form-control"
              rows="3"
              id="textarea-subject"
              [(ngModel)]="appointment.subject"
              name="appointment.subject"
            ></textarea>
          </div>
        </div>

        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="textarea-location"
          >
            {{ "APPOINTMENT_EDIT_LOCATION" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <textarea
              class="form-control"
              rows="3"
              id="textarea-location"
              [(ngModel)]="appointment.location"
              name="appointment.location"
            ></textarea>
          </div>
        </div>

        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="textarea-remark"
          >
            {{ "APPOINTMENT_EDIT_REMARK" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <textarea
              class="form-control"
              rows="3"
              id="textarea-remark"
              [(ngModel)]="appointment.remark"
              name="appointment.remark"
            ></textarea>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12"></div>

    <div class="clearfix"></div>

    <div class="">
      <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
        <div class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"></div>
        <div class="flex-nowrap">
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <button (click)="save()" class="btn btn-primary button-row">
              <span>{{ "BUTTON_SAVE" | translate }} </span
              ><i class="mdi mdi-content-save color-bright"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="selectCustomerModal"
  tabindex="-1"
  aria-labelledby="conrimModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header display-flex">
        <h5 class="modal-title" id="confirmModalLabel">
          {{ "APPOINTMENT_EDIT_SELECT_CUSTOMER_TITLE" | translate }}
        </h5>
        <button
          type="button"
          class="btn-close-modal"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i class="mdi mdi-close color-main"></i>
        </button>
      </div>
      <div class="modal-body modal-body-select">
        <app-view-customers-select
          (selectedCustomerModal)="selectedCustomerModal($event)"
        >
        </app-view-customers-select>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">
          {{ "BUTTON_CANCEL" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
