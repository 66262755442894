<div role="main">
  <div class="">
    <div class="page-title">
      <div class="title_left">
        <h3>{{ textTitle }}</h3>
      </div>
    </div>
  </div>

  <div class="clearfix"></div>

  <div class="x_panel">
    <div class="x_title display-flex">
      <div class="input-group col-lg-6 col-md-6 col-sm-6 col-xs-9">
        <input
          type="text"
          class="form-control"
          placeholder="{{ 'CUSTOMERS_TOOLTIP_SEARCH' | translate }}..."
          type="text"
          [(ngModel)]="textSearch"
          (keyup)="keyUpEvent($event)"
        />
        <span class="input-group-btn">
          <button
            class="btn btn-default"
            type="button"
            (click)="search()"
            data-toggle="tooltip"
            data-placement="top"
          >
            {{ "CUSTOMERS_BUTTON_SEARCH" | translate }}
            <i class="mdi mdi-magnify"></i>
          </button>
        </span>
      </div>
    </div>

    <div class="x_content">
      <table class="table table-striped">
        <thead>
          <tr>
            <th (click)="changeSorting('date')">
              <div class="table-header-col">
                {{ "OPEN_SALES_TABLE_DATE" | translate
                }}<span [hidden]="sort.column != 'date'">
                  <span [hidden]="!sort.ascending"> ^</span>
                  <span [hidden]="sort.ascending"> v</span></span
                >
              </div>
            </th>
            <th (click)="changeSorting('number')">
              <div class="table-header-col">
                {{ "OPEN_SALES_TABLE_NUMBER" | translate
                }}<span [hidden]="sort.column != 'number'">
                  <span [hidden]="!sort.ascending"> ^</span>
                  <span [hidden]="sort.ascending"> v</span></span
                >
              </div>
            </th>
            <th (click)="changeSorting('name')">
              <div class="table-header-col">
                {{ "OPEN_SALES_TABLE_NAME" | translate
                }}<span [hidden]="sort.column != 'name'">
                  <span [hidden]="!sort.ascending"> ^</span>
                  <span [hidden]="sort.ascending"> v</span></span
                >
              </div>
            </th>
            <th (click)="changeSorting('street')">
              <div class="table-header-col">
                {{ "OPEN_SALES_TABLE_STREET" | translate
                }}<span [hidden]="sort.column != 'street'">
                  <span [hidden]="!sort.ascending"> ^</span>
                  <span [hidden]="sort.ascending"> v</span></span
                >
              </div>
            </th>
            <th (click)="changeSorting('city')">
              <div class="table-header-col">
                {{ "OPEN_SALES_TABLE_CITY" | translate
                }}<span [hidden]="sort.column != 'city'">
                  <span [hidden]="!sort.ascending"> ^</span>
                  <span [hidden]="sort.ascending"> v</span></span
                >
              </div>
            </th>
            <th (click)="changeSorting('sumGross')">
              <div class="table-header-col">
                {{ "OPEN_SALES_TABLE_SUM_GROSS" | translate
                }}<span [hidden]="sort.column != 'sumGross'">
                  <span [hidden]="!sort.ascending"> ^</span>
                  <span [hidden]="sort.ascending"> v</span></span
                >
              </div>
            </th>

            <th class="tableColWidthIcon" [hidden]="!showLinks"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let sale of sales
                | limitFromTo
                  : currentPage * pageSize
                  : (currentPage + 1) * pageSize;
              let i = index
            "
          >
            <td (click)="clickedOnSale(sale)">
              {{ sale.textDate }}
            </td>
            <td (click)="clickedOnSale(sale)">{{ sale.number }}</td>
            <td (click)="clickedOnSale(sale)">{{ sale.name }}</td>
            <td (click)="clickedOnSale(sale)">{{ sale.street }}</td>
            <td (click)="clickedOnSale(sale)">{{ sale.city }}</td>
            <td (click)="clickedOnSale(sale)" class="noWrap">
              {{ sale.sumGross }}
            </td>

            <td [hidden]="!showLinks">
              <div class="dropdown">
                <button
                  class="btn btn-sm btn-default dropdown-toggle btn-action"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {{
                    "GENERAL_TABLE_DROPDOWN_ACTIONS" | translate | capitalize
                  }}
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/editSale/{{ typeSales }}/{{
                        sale.uuid
                      }}/false"
                      ng-click="$event.stopPropagation();"
                      data-toggle="tooltip"
                      data-placement="top"
                    >
                      <i class="mdi mdi-pencil"></i>&nbsp;{{
                        "OPEN_SALES_TOOLTIP_EDIT" | translate | capitalize
                      }}</a
                    >
                  </li>

                  <li [hidden]="sale.uuidCustomer=='' || sale.uuidCustomer==null">
                    <a
                      class="dropdown-item"
                      routerLink="/editCustomer/{{ sale.uuidCustomer }}"
                      data-toggle="tooltip"
                      data-placement="top"
                    >
                      <i class="mdi mdi-account"></i>&nbsp;{{
                        "OPEN_SALES_TABLE_TOOLTIP_CUSTOMER"
                          | translate
                          | capitalize
                      }}</a
                    >
                  </li>

                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/deleteSale/{{ typeSales }}/{{
                        sale.uuid
                      }}"
                      ng-click="$event.stopPropagation();"
                      data-toggle="tooltip"
                      data-placement="top"
                    >
                      <i class="mdi mdi-delete-outline"></i>&nbsp;{{
                        "OPEN_SALES_TABLE_TOOLTIP_DELETE"
                          | translate
                          | capitalize
                      }}</a
                    >
                  </li>
                </ul>
              </div>
            </td>

            <!-- td class="tableColWidthIcon" ng-show={{showLinks}}><a href="#/editSale/{{type}}/{{sale.idAtClient}}" ng-click="$event.stopPropagation();"
				data-toggle="tooltip" data-placement="top" title="{{ 'OPEN_SALES_TOOLTIP_EDIT' | translate }}">
				<i class="mdi mdi-pencil"></i></a></td>

			<td class="tableColWidthIcon" ng-show={{showLinks}}><a href ng-show="sale.enableButtonCustomer"
				data-toggle="tooltip" data-placement="top"
				ng-click="gotoCustomer(sale.idCustomerAtClient); $event.stopPropagation();"
				title="{{ 'OPEN_SALES_TABLE_TOOLTIP_CUSTOMER' | translate }}">
				<i class="mdi mdi-account"></i></a>
			</td>

            <td class="tableColWidthIcon" ng-show={{showLinks}}><a href="#/deleteSale/{{type}}/{{sale.idAtClient}}" ng-click="$event.stopPropagation();"
				data-toggle="tooltip" data-placement="top" title="{{ 'OPEN_SALES_TABLE_TOOLTIP_DELETE' | translate }}">
				<i class="mdi mdi-delete-outline"></i></a></td -->
          </tr>
        </tbody>
      </table>

      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <button
          class="btn btn-sm btn-default btn-navigation"
          [disabled]="currentPage == 0 || numberOfPages() == 0"
          (click)="currentPage = 0; storeCurrentPage()"
        >
          {{ "NAVIGATION_PAGE_FIRST" | translate }}
        </button>
        <button
          class="btn btn-sm btn-default btn-navigation"
          [disabled]="currentPage == 0 || numberOfPages() == 0"
          (click)="currentPage = currentPage - 1; storeCurrentPage()"
        >
          {{ "NAVIGATION_PAGE_PREVIOUS" | translate }}
        </button>
        <span class="text-navigation">
          {{ currentPage + 1 }}/{{ numberOfPages() }}
        </span>
        <button
          class="btn btn-sm btn-default btn-navigation"
          [disabled]="currentPage >= numberOfPages() - 1"
          (click)="currentPage = currentPage + 1; storeCurrentPage()"
        >
          {{ "NAVIGATION_PAGE_NEXT" | translate }}
        </button>
        <button
          class="btn btn-sm btn-default btn-navigation"
          [disabled]="currentPage >= numberOfPages() - 1"
          (click)="currentPage = numberOfPages() - 1; storeCurrentPage()"
        >
          {{ "NAVIGATION_PAGE_LAST" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
