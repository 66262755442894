import { CommonsService } from "../commons-service";
import { BaseModel } from "./base-model";
import { ModelFactory } from "./model-factory";
import { ModelInterface } from "./model-interface";
import { SalePositionModel } from "./sale-position-model";
declare const log: any;

export class SaleModel extends BaseModel {

  number: string = ""
  date: Date = new Date()
  textDate: string = CommonsService.formatDate0(this.date)
  uuidCustomer: string = ""
  name: string = ""
  firstName: string = ""
  street: string = ""
  zipCode: string = ""
  city: string = ""
  uuidCountry: string = ""
  uuidPaymentType: string = ""
  discount: number = 0
  taxExempt: boolean = false
  uuidsSalePositions: string = ""
  sumPosNet: string = ""
  sumPosNetV: number = 0
  sumPosTax: string = ""
  sumPosTaxV: number = 0
  sumPosGross: string = ""
  sumPosGrossV: number = 0
  sumNet: string = ""
  sumNetV: number = 0
  sumTax: string = ""
  sumTaxV: number = 0
  sumGross: string = ""
  sumGrossV: number = 0
  costPriceTotal: string = ""
  costPriceTotalV: number = 0
  purchasePriceTotal: string = ""
  purchasePriceTotalV: number = 0
  sumNetDiscount: string = ""
  sumNetDiscountV: number = 0
  sumNetBeforeDiscount: string = ""
  sumNetBeforeDiscountV: number = 0
  db1: string = ""
  db1V: number = 0
  db2: string = ""
  db2V: number = 0
  db1Perc: number = 0
  db2Perc: number = 0
  weightKg: number = 0
  uuidCancellationReason: string = ""
  signatureImage: string | undefined = ""
  positions: SalePositionModel[] = []
  telephone: string = ""

  constructor(params: any = null) {
    super(params)
    this.copyFromObject(params)
    this.textDate = CommonsService.formatDate0(this.date)
  }

  static override newModel(params: any = null): SaleModel {
    return new SaleModel(params)
  }

  clone(modelFactory: ModelFactory<ModelInterface>): SaleModel {
    let clone: SaleModel = new SaleModel(this)
    clone.positions =
      <SalePositionModel[]>modelFactory.newModels(SalePositionModel, this.positions)
    return clone
  }

  fullAddress(): String {
    return (this.street + " " + this.zipCode + " " + this.city).trim();
  }

  fullName(): String {
    return (this.firstName + " " + this.name).trim();
  }

  getPosition(iPos: number): SalePositionModel {
    return this.positions[iPos]
  }

  getNumPositions(): number {
    return this.positions.length
  }

  setPositions(positions: SalePositionModel[], currency: string) {
    this.positions = positions
    positions.forEach((position: any) => {
      position.uuidParent = this.uuid
    });
    this.generateSequenceUuidsOfPositions()
    this.recalc(currency)
  }

  movePositionUp(indexPosition: number) {
    var position = this.positions.splice(indexPosition, 1)[0];
    this.positions.splice(indexPosition - 1, 0, position);
    this.generateSequenceUuidsOfPositions()
  }

  movePositionDown(indexPosition: number) {
    var position = this.positions.splice(indexPosition, 1)[0];
    this.positions.splice(indexPosition + 1, 0, position);
    this.generateSequenceUuidsOfPositions()
  }

  addPosition(newPosition: SalePositionModel, currency: string) {
    this.positions.push(newPosition)
    this.generateSequenceUuidsOfPositions()
    this.recalc(currency)
  }


  generateSequenceUuidsOfPositions() {
    let uuids = ""
    for (let iPos = 0; iPos < this.positions.length; iPos++) {
      let pos: SalePositionModel = this.positions[iPos]
      if (uuids == "")
        uuids = pos.uuid
      else
        uuids += "," + pos.uuid
    }
    this.uuidsSalePositions = uuids
    return uuids
  }

  public getArrUuidsOfPositions() {
    let uuids = []
    for (let iPos = 0; iPos < this.positions.length; iPos++) {
      let pos: SalePositionModel = this.positions[iPos]
      uuids.push(pos.uuid)
    }
    return uuids
  }

  getPositionByUuid(uuid: string): SalePositionModel | null {
    for (let iPos = 0; iPos < this.positions.length; iPos++) {
      let pos: SalePositionModel = this.positions[iPos]
      if (pos.uuid == uuid)
        return pos
    }
    return null
  }

  recalc(currency: string) {
    this.sumNetV = 0.0;
    this.sumTaxV = 0.0;
    this.sumGrossV = 0.0;
    this.weightKg = 0.0;
    this.costPriceTotalV = 0.0;
    this.purchasePriceTotalV = 0.0;

    this.db1V = 0.0;
    this.db2V = 0.0;

    for (let iPos = 0; iPos < this.positions.length; iPos++) {
      let position: SalePositionModel = this.positions[iPos]
      var posNetV = position.amount * position.priceUnitNetV * (1.0 - position.discount);
      posNetV = Math.round(posNetV * 100.0) / 100.0;
      position.priceSumNetV = posNetV;	// _17
      position.sumArticleNetV = position.priceSumNetV; 	// _11
      this.sumNetV = this.sumNetV + posNetV;
      var posGrossV = position.amount * position.priceUnitGrossV * (1.0 - position.discount);
      // position.priceSumGrossV = posGrossV;	// _16
      position.priceSumGrossV = position.priceSumNetV * (1.0 + position.getTaxFactor()); // _16
      position.priceSumGrossV = Math.round(position.priceSumGrossV * 100.0) / 100.0;
      this.sumGrossV = this.sumGrossV + position.priceSumGrossV;
      // sale.sumGrossV = Math.round(sale.sumGrossV * 100.0) / 100.0;

      position.costPriceTotalV = position.costPriceUnitV * position.amount;
      position.costPriceTotalV = Math.round(position.costPriceTotalV * 100.0) / 100.0

      position.purchasePriceTotalV = position.purchasePriceUnitV * position.amount;
      position.purchasePriceTotalV = Math.round(position.purchasePriceTotalV * 100.0) / 100.0

      position.db1V = position.priceSumNetV - position.costPriceTotalV;
      position.db1V = Math.round(position.db1V * 100.0) / 100.0;
      position.db2V = position.priceSumNetV - position.purchasePriceTotalV;
      position.db2V = Math.round(position.db2V * 100.0) / 100.0;

      // sale.db1V = sale.db1V + position.db1V;
      // sale.db2V = sale.db2V + position.db2V;
      position.db2Percent = position.db2V / position.sumArticleNetV;
      if (position.sumArticleNetV == 0) {
        position.db2Percent = 0;
      }

      this.costPriceTotalV += position.costPriceTotalV;
      this.purchasePriceTotalV += position.purchasePriceTotalV;

      position.taxSumV = position.priceSumNetV * position.getTaxFactor(); // _13
      position.taxSumV = Math.round(position.taxSumV * 100.0) / 100.0;

      // weight
      if (!position.weightKgUnit)
        position.weightKgUnit = 0.0;
      position.weightKgTotal = position.amount * position.weightKgUnit;
      // position.weightKgTotal = Math.round(position.weightKgTotal * 1000.0) / 1000.0;
      this.weightKg = this.weightKg + position.weightKgTotal;

      CommonsService.setPrices(position, currency);
    }

    if (this.taxExempt) {
      this.sumGrossV = this.sumNetV;
    }

    var fDiscountSale = (1.0 - this.discount);

    this.sumPosNetV = this.sumNetV * fDiscountSale;   // _83
    this.sumPosNetV = Math.round(this.sumPosNetV * 100.0) / 100.0;
    this.sumPosGrossV = this.sumGrossV * fDiscountSale;  // _87
    this.sumPosGrossV = Math.round(this.sumPosGrossV * 100.0) / 100.0;
    this.sumPosTaxV = (this.sumGrossV - this.sumNetV) * fDiscountSale; // _84
    this.sumPosTaxV = Math.round(this.sumPosTaxV * 100.0) / 100.0;

    this.sumNetV = this.sumNetV * fDiscountSale;	// _93
    this.sumNetV = Math.round(this.sumNetV * 100.0) / 100.0;
    this.sumGrossV = this.sumGrossV * fDiscountSale;	// _97
    this.sumGrossV = Math.round(this.sumGrossV * 100.0) / 100.0;
    // sale.sumDiscountV = sale.sumPosGrossV - sale.sumPosNetV;	// _116
    this.sumTaxV = this.sumGrossV - this.sumNetV;
    this.sumTaxV = Math.round(this.sumTaxV * 100.0) / 100.0;	// _94?

    // calc db1, db2
    this.db1V = this.sumNetV - this.costPriceTotalV;
    this.db2V = this.sumNetV - this.purchasePriceTotalV;

    if (this.sumNetV != 0.0) {
      this.db1Perc = this.db1V / this.sumNetV;
      this.db2Perc = this.db2V / this.sumNetV;
      // sale.db1Perc = Math.round(sale.db1Perc * 100.0) / 100.0;
      // sale.db2Perc = Math.round(sale.db2Perc * 100.0) / 100.0;
    } else {
      this.db1Perc = 0.0;
      this.db2Perc = 0.0;
    }

    this.sumNetBeforeDiscountV = 0;
    for (var iSalePos = 0; iSalePos < this.positions.length; iSalePos++) {
      var position = this.positions[iSalePos];
      this.sumNetBeforeDiscountV += position.priceSumNetV;
    }
    this.sumNetDiscountV = this.sumNetBeforeDiscountV - this.sumNetV;

    CommonsService.setPrices(this, currency)
  }

  removePosition(uuidPosition: string, currency: string) {
    let iPosToDelete = -1
    for (let iPos = 0; iPos < this.positions.length; iPos++) {
      if (this.positions[iPos].uuid == uuidPosition) {
        iPosToDelete = iPos
        break;
      }
    }
    if (iPosToDelete >= 0) {
      this.positions.splice(iPosToDelete, 1)
      this.generateSequenceUuidsOfPositions()
      this.recalc(currency)
    }
  }

  replaceSalePosition(uuidPositionToRemove: string, newSalePosition: SalePositionModel, currency: string) {
    let iPosToDelete = -1
    for (let iPos = 0; iPos < this.positions.length; iPos++) {
      if (this.positions[iPos].uuid == uuidPositionToRemove) {
        iPosToDelete = iPos
        break;
      }
    }
    log("replace pos ? " + iPosToDelete,)
    if (iPosToDelete >= 0) {
      log("replacing pos")
      this.positions.splice(iPosToDelete, 1, newSalePosition)
      this.generateSequenceUuidsOfPositions()
      this.recalc(currency)
    }
  }
}
