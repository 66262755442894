import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common'
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonsService } from '../commons-service';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from '../custom-date-parser-formatter'
import { EditTaskComponent } from '../edit-task/edit-task.component'
import { UUID } from 'angular2-uuid';
import { RepositoryService } from '../repository.service';
import { ModelFactory } from '../models/model-factory';
import { ModelInterface } from '../models/model-interface';
import { TaskModel } from '../models/task-model';
declare const log: any
declare var $: any

@Component({
  selector: 'app-add-task',
  templateUrl: '../edit-task/edit-task.component.html',
  styleUrls: ['./add-task.component.css'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
  ]
})
export class AddTaskComponent extends EditTaskComponent implements OnInit {

  constructor(
    location: Location,
    route: ActivatedRoute,
    router: Router,
    translate: TranslateService,
    modelFactory: ModelFactory<ModelInterface>,
    repository: RepositoryService
  ) { super(location, route, router, modelFactory, translate, repository) }

  override ngOnInit(): void {
    super.ngOnInit()
  }

  override async asyncInitSpecific() {
    this.translate.get("TASK_ADD_TITLE").subscribe((translated: string) => this.textTitle = translated)

    this.task = new TaskModel({ uuidTaskCategory: this.taskCategories[0].uuid })

    this.task.uuid = UUID.UUID();

    this.task.due.setSeconds(0)

    this.textDue = CommonsService.formatDateTime0(this.task.due)

    this.hasDateDue = false;
  }

  override async saveSpecific() {
    if (this.doneBool) {
      this.task.dateDone = new Date()
      this.task.done = true
    } else {
      this.task.dateDone = new Date(CommonsService.TIME_NOT_VALID_1900_MS)
      this.task.done = false
    }

    await this.repository.tasks.create(this.task)

    //this.location.back()
    CommonsService.showSuccessMessage(this.translate.instant("GENERAL_SAVED"))
  }

}
