import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { CommonsService } from '../commons-service';
import { RepositoryService } from '../repository.service';
import { CustomerModel } from '../models/customer-model';
import { NetworkService } from '../network.service';
declare const $: any;
declare const log: any;
declare const dataModels: any;

@Component({
  selector: 'app-edit-customer',
  templateUrl: './edit-customer.component.html',
  styleUrls: ['./edit-customer.component.css'],
})
export class EditCustomerComponent implements OnInit {

  securityToken = "";
  private serverAddress = "wss://" + window.location.hostname + "/ws/"; // environment.SERVER_HOST;// + ":" + environment.port;

  textHeaderPage = ""
  showLinks = true;

  showHistory = false;
  iActiveHistoryTab = 0;
  showHistoryTab: boolean[] = [false, false, false, false, false, false, false]
  htmlHistoryTab: string[] = ["", "", "", "", "", "", ""]

  uuidCustomer: string = "";
  uuidsCustomerCategoriesArr: string[] = []
  customer: CustomerModel = new CustomerModel()
  customerBeforeEdit: CustomerModel = new CustomerModel()

  textDateBirthday: String = CommonsService.formatDate0(new Date('2000-01-01T00:00:00'))
  textDateLastContact: String = CommonsService.formatDate0(new Date());
  textDateNextContact: String = CommonsService.formatDate0(new Date(new Date().setDate((new Date()).getDate() + 5)));

  sexes: any[] = [];
  countries: any[] = [];
  customerCategories: any[] = [];
  paymentTypes: any[] = [];
  paymentTargets: any[] = [];
  pricelists: any[] = [];
  discountPercent = 0

  uuidCustomerCategoryDefault = ""

  formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
  format = this.formats[2];

  constructor(
    protected router: Router,
    private route: ActivatedRoute,
    protected translate: TranslateService,
    protected commons: CommonsService,
    protected repository: RepositoryService,
    private networkService: NetworkService) {
  }

  ngOnInit(): void {
    this.init()
  }

  async init() {
    this.securityToken = await this.repository.readSecurityToken()
    this.sexes = await this.repository.sexes.readAll()
    this.countries = await this.repository.countries.readAll()
    this.customerCategories = await this.repository.customerCategories.readAll()
    this.uuidCustomerCategoryDefault = this.customerCategories[2].uuid
    this.paymentTypes = await this.repository.paymentTypes.readAll()
    this.paymentTargets = await this.repository.paymentTargets.readAll()
    this.pricelists = await this.repository.priceLists.readAll()

    $('#div-last-contact').datetimepicker({
      format: 'DD.MM.YYYY'
    });

    $('#div-next-contact').datetimepicker({
      format: 'DD.MM.YYYY'
    });

    $('#div-birthday').datetimepicker({
      format: 'DD.MM.YYYY'
    });

    return this.initSpecific();
  }

  async initSpecific() {
    this.translate.get("CUSTOMER_EDIT_TITLE").subscribe((translated: string) => {
      this.textHeaderPage = translated
    })

    const routeParams = this.route.snapshot.paramMap;
    this.uuidCustomer = String(routeParams.get('uuidCustomer'));

    let customerTemp = await this.repository.customers.read(this.uuidCustomer, false)

    this.customer = <CustomerModel>customerTemp

    this.uuidsCustomerCategoriesArr = this.customer.uuidsCustomerCategories.split(",");

    // invalid date?
    if (this.customer.birthday.getTime() <= CommonsService.parseDate("01.01.1901").getTime())
      this.textDateBirthday = ""
    else
      this.textDateBirthday = CommonsService.formatDate0(this.customer.birthday)
    this.textDateLastContact = CommonsService.formatDate0(this.customer.lastContact)
    this.textDateNextContact = CommonsService.formatDate0(this.customer.nextContact)

    this.discountPercent = this.customer.discount * 100.0

    this.customerBeforeEdit = new CustomerModel(this.customer);

    log("the customer")
    log(this.customer)

    return this.loadCustomerHistory(this.customer.uuid);
  }

  // load customer history from the server
  private loadCustomerHistory(uuidCustomer: string) {
    log("start load customer history")
    var promises = [];

    //let promiseAP = this.loadCustomerHistoryHtml("Ansprechpartner", uuidCustomer, 1);
    //promises.push(promiseAP);
    let promiseKK = this.loadCustomerHistoryHtml("Kundenkontakt", uuidCustomer, 2);
    promises.push(promiseKK);
    let promiseTM = this.loadCustomerHistoryHtml("Termin", uuidCustomer, 3);
    promises.push(promiseTM);
    let promiseAN = this.loadCustomerHistoryHtml("Angebot", uuidCustomer, 4);
    promises.push(promiseAN);
    let promiseAF = this.loadCustomerHistoryHtml("Auftrag", uuidCustomer, 5);
    promises.push(promiseAF);
    let promiseLF = this.loadCustomerHistoryHtml("Lieferung", uuidCustomer, 6);
    promises.push(promiseLF);
    let promiseRN = this.loadCustomerHistoryHtml("Rechnung", uuidCustomer, 7);
    promises.push(promiseRN);

    Promise.allSettled(promises).then(() => {
      log("all promises done");
    })
  };

  setActiveHistoryTab(iNewTab: number) {
    this.iActiveHistoryTab = iNewTab;
  };

  isSetActiveHistoryTab(iTab: number) {
    return this.iActiveHistoryTab === iTab;
  };

  private async loadCustomerHistoryHtml(nameModel: string, uuidCustomer: string, iTab: number) {
    let html = await this.networkService.readCustomerHistoryHtml(nameModel, uuidCustomer)
    if (html && html != "") {
      this.htmlHistoryTab[iTab] = html;
      this.showHistoryTab[iTab] = true;
      this.showHistory = true;
      this.setActiveHistoryTab(iTab)
    }
  }

  async save(navigateToList: boolean = true) {
    this.customer.uuidsCustomerCategories = this.uuidsCustomerCategoriesArr.toString()

    let textDateBirthdayEdited = $("#input-birthday").val();
    if (textDateBirthdayEdited == "")
      this.customer.birthday = CommonsService.parseDate("01.01.1900")
    else
      this.customer.birthday = CommonsService.parseDate(textDateBirthdayEdited)
    this.customer.lastContact = CommonsService.parseDate($("#input-last-contact").val())
    this.customer.nextContact = CommonsService.parseDate($("#input-next-contact").val())

    this.customer.discount = this.discountPercent / 100.0

    return this.saveSpecific(navigateToList)
  }

  async saveSpecific(navigateToList: boolean) {
    await this.repository.customers.update(this.customerBeforeEdit, this.customer)
    // if (navigateToList)
    //   return this.router.navigateByUrl("/viewCustomers")
    CommonsService.showSuccessMessage(this.translate.instant("GENERAL_SAVED"))
    return Promise.resolve(true)
  }

  gotoAppointments() {
    this.save(false).then(() => {
      this.router.navigateByUrl("/viewAppointments/" + this.uuidCustomer)
    })
  }

  gotoPartners() {
    this.save(false).then(() => {
      this.router.navigateByUrl("/viewPartners/" + this.uuidCustomer)
    })
  }

  async gotoCustomerContacts() {
    await this.save(false)
    this.router.navigateByUrl("/viewCustomerContacts/" + this.uuidCustomer)
  }

  gotoOffers() {
    this.save(false).then(() => {
      this.router.navigateByUrl("/viewSales/Offer/" + this.uuidCustomer)
    })
  }

  gotoOrders() {
    this.save(false).then(() => {
      this.router.navigateByUrl("/viewSales/Order/" + this.uuidCustomer)
    })
  }

  gotoSalesHistory() {
    this.save(false).then(() => {
      this.router.navigateByUrl("/viewCustomerSalesHistory/" + this.uuidCustomer)
    })
  }
}
