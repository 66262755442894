import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CommonsService } from '../commons-service';
import { ParametersStartService } from '../parameters-start.service';
import { RepositoryService } from '../repository.service';
import { CustomerModel } from '../models/customer-model';
import { OfferModel } from '../models/offer-model';
declare const log: any

@Component({
  selector: 'app-view-enquiries',
  templateUrl: './view-enquiries.component.html',
  styleUrls: ['./view-enquiries.component.css']
})
export class ViewEnquiriesComponent implements OnInit {

  pageSize = environment.entriesPerPage
  currentPage = 0
  enquiries: any[] = []
  sort = {
    column: "EnquiryDate_171",
    ascending: true
  };

  constructor(
    private repository: RepositoryService,
    private parametersStart: ParametersStartService
  ) { }

  ngOnInit(): void {
    this.init()
  }

  async init() {
    await this.repository.updateUtilityData("locationOriginSales", "viewEnquiries")
    this.sort.column = await this.repository.readUtilityData("sortEnquiriesColumn")
    this.sort.ascending = "true" == await this.repository.readUtilityData("sortEnquiriesAscending")
    this.currentPage = +await this.repository.readUtilityData("showEnquiriesPage")

    await this.loadEnquiries()
  }

  async loadEnquiries() {
    let customers: CustomerModel[] = await this.repository.customers.readAll();

    let enquiriesR: OfferModel[] = await this.repository.readEnquiries(this.sort.column, this.sort.ascending)
    enquiriesR.forEach(enquiry => {
      enquiry.telephone = "";
      let cust: CustomerModel = CommonsService.getObjectByUuid(customers, enquiry.uuidCustomer);
      if (cust) {
        enquiry.telephone = cust.telephone1;
      }
    });

    this.enquiries = enquiriesR

    if (this.currentPage > this.numberOfPages() - 1) {
      this.currentPage = this.numberOfPages() - 1;
      this.storeCurrentPage();
    }
  }

  timeLastSorting = new Date();
  async changeSorting(column: string) {
    var timeNow = new Date();
    if (timeNow.getTime() - this.timeLastSorting.getTime() < CommonsService.TIME_TRIGGER_SORTING_MS)
      return;
    this.timeLastSorting = new Date();

    if (this.sort.column == column) {
      this.sort.ascending = !this.sort.ascending;
    } else {
      this.sort.column = column;
      this.sort.ascending = false;
    }

    await this.repository.updateUtilityData("sortEnquiriesColumn", this.sort.column);
    await this.repository.updateUtilityData("sortEnquiriesAscending", this.sort.ascending ? "true" : "false");

    await this.loadEnquiries()
  };

  async storeCurrentPage() {
    this.repository.updateUtilityData("showEnquiriesPage", "" + this.currentPage);
  }

  numberOfPages() {
    if (this.enquiries && this.enquiries.length > 0)
      return Math.ceil(this.enquiries.length / this.pageSize);
    else
      return 1;
  }
}
