import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { CommonsService } from '../commons-service';
import { RepositoryService } from '../repository.service';
import { CustomerModel } from '../models/customer-model';
declare const log: any;

@Component({
  selector: 'app-view-customers',
  templateUrl: './view-customers.component.html',
  styleUrls: ['./view-customers.component.css']
})
export class ViewCustomersComponent implements OnInit {

  environment = environment
  showLinks = true;
  showButtonAdd = true;
  customers: CustomerModel[] = [];
  pageSize = environment.entriesPerPage;
  currentPage: number = 0
  sort = {
    column: "NameFirma_5",
    ascending: true
  }
  textSearch = "";

  iRowHighlighted = -1

  @ViewChild('idInputTextSearch') inputTextSearch: ElementRef | null | undefined;

  constructor(
    private router: Router,
    private repository: RepositoryService
  ) { }

  ngOnInit(): void {
    this.init()
  }

  ngAfterViewInit() {
  }

  async init() {
    this.textSearch = await this.repository.readUtilityData("searchCustomersText")
    this.sort.column = await this.repository.readUtilityData("sortCustomersColumn")
    this.currentPage = +await this.repository.readUtilityData("showCustomersPage")
    this.sort.ascending = await this.repository.readUtilityData("sortCustomersAscending")
    this.search();
  }

  resetSearch() {
    this.textSearch = ""
    this.sort.column = "number"
    this.currentPage = 0
    this.sort.ascending = true
    this.search();
  }

  numberOfPages() {
    if (this.customers && this.customers.length > 0)
      return Math.ceil(this.customers.length / this.pageSize);
    else
      return 1;
  }

  async search() {
    await this.loadCustomersSearch()

    this.setFocusToSearchField()

    if (this.currentPage > this.numberOfPages() - 1) {
      this.currentPage = this.numberOfPages() - 1;
      this.storeCurrentPage();
    }

    await this.repository.updateUtilityData("searchCustomersText", this.textSearch)
  }

  setFocusToSearchField() {
    setTimeout(() => { this.inputTextSearch?.nativeElement.focus() },
      CommonsService.TIME_DELAY_FOCUS_MS)
    this.iRowHighlighted = -1
  }

  async loadCustomersSearch() {
    var textSearchLC = "";
    if (this.textSearch && typeof this.textSearch != 'undefined' && this.textSearch != "")
      textSearchLC = this.textSearch.toLowerCase();

    let customersL = <CustomerModel[]>await this.repository.customers.readAll(this.sort.column, this.sort.ascending)
    customersL = customersL.filter((customer: any) => {
      if (textSearchLC != "") {
        if ((customer.firstName.toLowerCase().indexOf(textSearchLC) >= 0) ||
          (customer.lastName.toLowerCase().indexOf(textSearchLC) >= 0) ||
          (customer.street.toLowerCase().indexOf(textSearchLC) >= 0) ||
          (customer.city.toLowerCase().indexOf(textSearchLC) >= 0) ||
          (customer.zipCode.toLowerCase().indexOf(textSearchLC) >= 0))
          return true
        else
          return false
      } else
        return true
    })
    this.customers = customersL;

    this.setFocusToSearchField()

    log("the customers")
    log(this.customers)
  }

  timeLastSorting = new Date();
  changeSorting(column: string) {
    var timeNow = new Date();
    if (timeNow.getTime() - this.timeLastSorting.getTime() < CommonsService.TIME_TRIGGER_SORTING_MS)
      return;
    this.timeLastSorting = new Date();

    log("sort by column " + column);

    var sort = this.sort;

    if (sort.column == column) {
      sort.ascending = !sort.ascending;
    } else {
      sort.column = column;
      sort.ascending = false;
    }

    // store sort parameters in local db
    const this_ = this;
    this.repository.updateUtilityData("sortCustomersColumn", this.sort.column).then(() => {
      return this.repository.updateUtilityData("sortCustomersAscending", "" + this.sort.ascending);
    }).then(() => {
      setTimeout(function () {
        this_.loadCustomersSearch();
      }, 100);
    })
  };

  storeCurrentPage() {
    this.repository.updateUtilityData("showCustomersPage", "" + this.currentPage);
    this.setFocusToSearchField()
  }

  gotoAddCustomer() {
    this.router.navigateByUrl("/addCustomer")
  }

  clickedOnCustomer(customer: CustomerModel) {
    this.selectedCustomer(customer)
  }

  enterEvent(event: any) {
    if (this.iRowHighlighted == -1)
      return

    let iCustomer = this.currentPage * this.pageSize + this.iRowHighlighted

    let customer = this.customers[iCustomer]

    this.selectedCustomer(customer)

    event.preventDefault()
    return false
  }


  selectedCustomer(customer: CustomerModel) {
    this.router.navigateByUrl("/editCustomer/" + customer.uuid);
  }

  private keyTimeout: any = null;

  keyUpEvent(event: any) {
    if (event.key.length != 1 && event.key != "Backspace") {
      event.preventDefault()
      return false;
    }

    if (this.keyTimeout) {
      clearTimeout(this.keyTimeout);
      this.keyTimeout = null;
    }

    this.keyTimeout = setTimeout(() => {
      this.search();
    }, 300)

    return true
  }

  arrowDownEvent(event: any) {
    if (this.iRowHighlighted < this.pageSize - 1 &&
      this.pageSize * this.currentPage + this.iRowHighlighted < this.customers.length - 1) {
      this.iRowHighlighted++
      this.scrollRowIntoView()
    } else if (this.iRowHighlighted == this.pageSize - 1 &&
      this.currentPage < this.numberOfPages() - 1) {
      this.currentPage++
      this.storeCurrentPage()
      this.iRowHighlighted = 0
      this.scrollRowIntoView()
    }

    event.preventDefault()
    return false
  }

  arrowUpEvent(event: any) {
    if (this.iRowHighlighted > 0) {
      this.iRowHighlighted--
      this.scrollRowIntoView()
    } else if (this.currentPage > 0) {
      this.currentPage--
      this.storeCurrentPage()
      this.iRowHighlighted = this.pageSize - 1
      setTimeout(() => {
        this.scrollRowIntoView();
      }, 200)
    }

    event.preventDefault()
    return false
  }

  scrollRowIntoView() {
    const element = document.getElementById("id-table-customers-row-" + this.iRowHighlighted);
    if (element != null)
      element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
  }
}
