import { CommonsService } from "../commons-service";
import { BaseModel } from "./base-model";

export class ImageModel extends BaseModel {

  uuidParent = ""
  name = ""
  data = ""
  type = 5
  isLink = false

  // isSignature() {
  //   return this.name == CommonsService.NAME_FILE_SIGNATURE_IMAGE
  // }

  constructor(params: any = null) {
    super(params)
    this.copyFromObject(params)
  }

  static override newModel(object: any = null) {
    return new ImageModel(object)
  }
}
