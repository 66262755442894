import { CommonsService } from "../commons-service";
import { ImageModel } from "./image-model";
import { ModelInterface } from "./model-interface"
declare const log: any;

export class BaseModel implements ModelInterface {

  uuid: string = ""

  constructor(params: any = null) {
    this.copyFromObject(params)
  }

  static newModel(object: any = null) {
    return new BaseModel(object)
  }

  copyFromObject(object: any): ModelInterface {
    if (object == null)
      return this;

    for (var propertyName in this) {
      if(propertyName.endsWith("_plain")) {
        // this is a plain version of an rtf text and is set automatically below
        continue;
      }

      let type = typeof this[propertyName]
      if (propertyName in object) {
        if (type == "object" && (this[propertyName] instanceof Date))
          this[propertyName] = <any>new Date(object[propertyName])
        else if (!object[propertyName] || object[propertyName] == null || typeof object[propertyName] == "undefined") {
          if (type == "string" && !propertyName.endsWith("Plain"))
            this[propertyName] = <any>""
          else if (type == "number")
            this[propertyName] = <any>0
          else if (type == "boolean")
            this[propertyName] = <any>false
          else
            this[propertyName] = object[propertyName]
        } else {
          this[propertyName] = object[propertyName]
          let propertyNamePlain = propertyName + "_plain"
          if (type == "string" && propertyNamePlain in this && typeof (<any>this)[propertyNamePlain] == "string") {
            (<any>this)[propertyNamePlain] = CommonsService.convertRtfToPlain(<any>this[propertyName]);
            // take converted plain text anyway
            (<any>this)[propertyName] = (<any>this)[propertyNamePlain]; // take converted plain text anyway
          }
        }
      } else {
        if (type == "object" && (this[propertyName] instanceof Date))
          this[propertyName] = <any>new Date(0)
        else if (type == "string")
          this[propertyName] = <any>""
        else if (type == "number")
          this[propertyName] = <any>0
        else if (type == "boolean")
          this[propertyName] = <any>false
        else
          this[propertyName] = <any>null
      }
    }
    return this
  }

}
