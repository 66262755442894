import { BaseModel } from "./base-model"
declare const log: any;

export class TaskCategoryModel extends BaseModel {

  name: string = ""

  constructor(params: any = null) {
    super(params)
    this.copyFromObject(params)
  }

  static override newModel(params: any = null): TaskCategoryModel {
    return new TaskCategoryModel(params)
  }
}
