import { Component, OnInit } from '@angular/core';
import { ArticleModel } from '../models/article-model';
import { TaxRateModel } from '../models/tax-rate-model';
import { ArticleGroupModel } from '../models/article-group-model';
import { RepositoryService } from '../repository.service';
import { ActivatedRoute } from '@angular/router';
import { CommonsService } from '../commons-service';
import { ParametersStartService } from '../parameters-start.service';
import { Location } from '@angular/common'
declare const log: any;

@Component({
  selector: 'app-delete-article',
  templateUrl: './delete-article.component.html',
  styleUrls: ['./delete-article.component.css']
})
export class DeleteArticleComponent implements OnInit {

  uuidArticle = ""
  article: ArticleModel = new ArticleModel()
  standardDBPercent100: number = 0
  taxRates: TaxRateModel[] = []
  articleGroups: ArticleGroupModel[] = []

  constructor(
    private route: ActivatedRoute,
    private repository: RepositoryService,
    private parametersStart: ParametersStartService,
    private location: Location,
  ) { }

  ngOnInit(): void {
    this.init()
  }

  async init() {
    this.articleGroups = await this.repository.articleGroups.readAll()
    this.taxRates = await this.repository.taxRates.readAll()

    const routeParams = this.route.snapshot.paramMap;
    this.uuidArticle = String(routeParams.get('uuidArticle'));
    this.article = <ArticleModel>await this.repository.articles.read(this.uuidArticle);

    this.article.taxRate = <TaxRateModel | null>CommonsService.getObjectByUuid(this.taxRates, this.article.uuidTaxRate)
    CommonsService.setPrices(this.article, this.parametersStart.currency)
    this.standardDBPercent100 = 100.0 * this.article.standardDBPercent;

    let articleGroup: ArticleGroupModel = CommonsService.getObjectByUuid(this.articleGroups, this.article.uuidArticlegroup)
    if (articleGroup != null) {
      this.article.articleGroupName = articleGroup.name
    }

    log("the article", this.article)
  }

  async delete() {
    await this.repository.articles.delete(this.uuidArticle)
    this.location.back()
  }

}
