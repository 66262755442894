import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonsService } from '../commons-service';
import { ParametersStartService } from '../parameters-start.service';
import { ArticleModel } from '../models/article-model';
import { RepositoryService } from '../repository.service';
import { TranslateService } from '@ngx-translate/core';
import { NetworkService } from '../network.service';
import { StocksOfArticleModel } from '../models/stocks-of-article-model';
import { TaxRateModel } from '../models/tax-rate-model';
import { ArticleGroupModel } from '../models/article-group-model';
declare const log: any;

@Component({
  selector: 'app-view-article',
  templateUrl: './view-article.component.html',
  styleUrls: ['./view-article.component.css']
})
export class ViewArticleComponent implements OnInit {

  textHeaderPage = ""
  showStockInfoButton = true;

  uuidArticle = ""
  article: ArticleModel = new ArticleModel()
  articleBeforeEdit: ArticleModel = new ArticleModel()
  standardDBPercent100: number = 0
  showStockOfWarehouses: boolean = false
  stockInfos: StocksOfArticleModel[] = []
  taxRates: TaxRateModel[] = []
  articleGroups: ArticleGroupModel[] = []

  constructor(
    protected translate: TranslateService,
    private route: ActivatedRoute,
    protected router: Router,
    protected repository: RepositoryService,
    protected parametersStart: ParametersStartService,
    private networkService: NetworkService
  ) { }

  ngOnInit(): void {
    this.init()
  }

  async init() {
    this.articleGroups = await this.repository.articleGroups.readAll()
    this.taxRates = await this.repository.taxRates.readAll()

    this.initSpecific();
  }

  async initSpecific() {
    this.translate.get("ARTICLE_VIEW_TITLE").subscribe((translated: string) => {
      this.textHeaderPage = translated
    })

    const routeParams = this.route.snapshot.paramMap;
    this.uuidArticle = String(routeParams.get('uuidArticle'));
    this.article = <ArticleModel>await this.repository.articles.read(this.uuidArticle);

    this.article.taxRate = <TaxRateModel | null>CommonsService.getObjectByUuid(this.taxRates, this.article.uuidTaxRate)
    CommonsService.setPrices(this.article, this.parametersStart.currency)
    this.standardDBPercent100 = 100.0 * this.article.standardDBPercent;

    let articleGroup: ArticleGroupModel = CommonsService.getObjectByUuid(this.articleGroups, this.article.uuidArticlegroup)
    if (articleGroup != null) {
      this.article.articleGroupName = articleGroup.name
    }

    this.articleBeforeEdit = new ArticleModel(this.article)

    log("the article", this.article)
  }

  taxRateChanged() {
    this.article.taxRate = <TaxRateModel | null>CommonsService.getObjectByUuid(this.taxRates, this.article.uuidTaxRate)
    var pUGross = CommonsService.valueAndCurrencyMult(this.article.priceNet, 1.0 + this.article.getTaxFactor());
    this.article.priceGross = pUGross;
    this.article.priceGrossV = CommonsService.getValueFromValueWithCurrency(pUGross);
  }

  changeNet() {
    // var priceAndCurrencyNet = this.article.priceNet.split(" ");

    // if (priceAndCurrencyNet.length === 1)
    //   if (priceAndCurrencyNet[0] != this.parametersStart.currency)
    //     this.article.priceNet += " " + this.parametersStart.currency;
    //   else
    //     this.article.priceNet = "0,00 " + this.parametersStart.currency;
    this.article.priceNet = CommonsService.parseValueWithCurrency(this.article.priceNet, this.parametersStart.currency)

    var pUNet = CommonsService.valueAndCurrencyMult(this.article.priceNet, 1.0);
    var pUGross = CommonsService.valueAndCurrencyMult(this.article.priceNet, 1.0 + this.article.getTaxFactor());

    this.article.priceGross = pUGross;
    this.article.priceGrossV = CommonsService.getValueFromValueWithCurrency(pUGross);
    this.article.priceNet = pUNet;
    this.article.priceNetV = CommonsService.getValueFromValueWithCurrency(pUNet);

    this.article.calcStandardDBFromValues(this.parametersStart.currency);
    this.standardDBPercent100 = 100.0 * this.article.standardDBPercent;

    log(this.article)
  };

  changeGross() {
    // var priceAndCurrencyGross = this.article.priceGross.split(" ");

    // if (priceAndCurrencyGross.length === 1)
    //   if (priceAndCurrencyGross[0] != this.parametersStart.currency)
    //     this.article.priceGross += " " + this.parametersStart.currency;
    //   else
    //     this.article.priceGross = "0,00 " + this.parametersStart.currency;
    this.article.priceGross = CommonsService.parseValueWithCurrency(this.article.priceGross, this.parametersStart.currency)

    var pUGross = CommonsService.valueAndCurrencyMult(this.article.priceGross, 1.0);
    var pUNet = CommonsService.valueAndCurrencyMult(this.article.priceGross, 1.0 / (1.0 + this.article.getTaxFactor()));

    this.article.priceGross = pUGross;
    this.article.priceGrossV = CommonsService.getValueFromValueWithCurrency(pUGross);
    this.article.priceNet = pUNet;
    this.article.priceNetV = CommonsService.getValueFromValueWithCurrency(pUNet);

    this.article.calcStandardDBFromValues(this.parametersStart.currency);
    this.standardDBPercent100 = 100.0 * this.article.standardDBPercent;

    log(this.article)
  }

  changePurchasePrice() {
    this.article.calcCostPricePerPiece = CommonsService.parseValueWithCurrency(this.article.calcCostPricePerPiece, this.parametersStart.currency);
    this.article.calcCostPricePerPieceV = CommonsService.getValueFromValueWithCurrency(this.article.calcCostPricePerPiece)

    this.article.calcCosts(this.parametersStart.currency)
    this.standardDBPercent100 = 100.0 * this.article.standardDBPercent;
  }

  async fetchStockOfWarehouses() {

    if (!this.networkService.isOnline || !this.networkService.hasServer) {
      this.showStockOfWarehouses = false
      CommonsService.showErrorMessage(this.translate.instant("ARTICLE_MESSAGE_NOT_ONLINE"))
    } else {
      this.stockInfos = await this.repository.readStocksOfArticleInWarehouses(this.uuidArticle)

      log(this.stockInfos)

      if (this.stockInfos == null || this.stockInfos.length == 0) {
        this.showStockOfWarehouses = false
      } else {
        this.showStockOfWarehouses = true
      }
    }
  }

  async save() {
    this.saveSpecific();
  }

  async saveSpecific() {
    await this.repository.articles.update(this.articleBeforeEdit, this.article)
    CommonsService.showSuccessMessage(this.translate.instant("GENERAL_SAVED"))
  }

}
