import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonsService } from '../commons-service';
import { Location } from '@angular/common';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from '../custom-date-parser-formatter'
import { RepositoryService } from '../repository.service';
import { AppointmentModel } from '../models/appointment-model';
import { CustomerModel } from '../models/customer-model';
import { ViewCustomersSelectComponent } from '../view-customers-select/view-customers-select.component';
declare var $: any;
declare const log: any;

@Component({
  selector: 'app-edit-appointment',
  templateUrl: './edit-appointment.component.html',
  styleUrls: ['./edit-appointment.component.css'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
  ]
})
export class EditAppointmentComponent implements OnInit {

  textTitle = ""
  uuidAppointment = ""
  appointment: AppointmentModel = new AppointmentModel()
  customer: CustomerModel = new CustomerModel()
  appointmentBeforeEdit: AppointmentModel = new AppointmentModel()
  textBegin = "01.01.2000 12:00"
  textEnd = "01.01.2000 13:00"
  nameCustomer = ""
  showLinkCustomer = false

  @ViewChild(ViewCustomersSelectComponent) viewCustomersSelect!: ViewCustomersSelectComponent;

  constructor(
    protected location: Location,
    protected route: ActivatedRoute,
    protected router: Router,
    protected translate: TranslateService,
    protected repository: RepositoryService,
  ) { }

  ngOnInit(): void {
    let this_ = this;

    $('#div-date-begin').datetimepicker({
      format: 'DD.MM.YYYY HH:mm'
    });
    $('#div-date-end').datetimepicker({
      format: 'DD.MM.YYYY HH:mm'
    });
    $("#div-date-begin").on("dp.change", function (e: any) {
      this_.changedDateTimeBegin();
    });
    $("#div-date-end").on("dp.change", function (e: any) {
      this_.changedDateTimeEnd();
    });
    this.initSpecific1()
  }

  async initSpecific1() {
    this.translate.get("APPOINTMENT_EDIT_TITLE").subscribe((translated) => this.textTitle = translated)

    const routeParams = this.route.snapshot.paramMap;
    this.uuidAppointment = String(routeParams.get('uuidAppointment'));

    let appTemp = await this.repository.appointments.read(this.uuidAppointment)
    if (!appTemp) {
      this.router.navigateByUrl("/start")
      return
    }

    this.appointment = <AppointmentModel>appTemp

    this.appointmentBeforeEdit = new AppointmentModel(this.appointment);

    return this.initCommon()
  }

  async initCommon() {
    const routeParams = this.route.snapshot.paramMap;

    this.textBegin = CommonsService.formatDateTime0(this.appointment.begin)
    this.textEnd = CommonsService.formatDateTime0(this.appointment.end)

    let custTemp = <CustomerModel>await this.repository.customers.read(this.appointment.uuidCustomer, true)
    if (custTemp) {
      this.customer = custTemp
      this.appointment.uuidCustomer = this.customer.uuid
      this.nameCustomer = this.customer.firstName + " " + this.customer.lastName;
      this.showLinkCustomer = true
    } else {
      this.appointment.uuidCustomer = ""
      this.nameCustomer = "";
      this.showLinkCustomer = false;
    }

    // prevent direct edit of time picker inputs
    $(".ngb-tp-input").attr("inputmode", "")
    $(".ngb-tp-input").attr("readonly", "")

    return this.initSpecific2()
  }

  async initSpecific2() {
  }

  validateDateTimeInputs() {
    this.appointment.begin = CommonsService.parseDateTime($("#input-date-begin").val())
    this.appointment.end = CommonsService.parseDateTime($("#input-date-end").val())

    this.appointment.durationHours =
      (this.appointment.end.getTime() - this.appointment.begin.getTime()) / (1000 * 60 * 60);

    return true
  }

  async save(navigateBack: boolean = true): Promise<boolean> {
    if (!this.validateDateTimeInputs())
      return Promise.resolve(false)
    return this.saveSpecific(navigateBack)
  }

  async saveSpecific(navigateBack: boolean = true): Promise<boolean> {
    this.repository.appointments.update(this.appointmentBeforeEdit, this.appointment)

    // if (navigateBack)
    //   this.location.back()
    CommonsService.showSuccessMessage(this.translate.instant("GENERAL_SAVED"))
    return Promise.resolve(true)
  }

  async gotoCustomer() {
    if (!await this.save(false))
      return
    this.router.navigateByUrl("/editCustomer/" + this.customer.uuid)
  }

  changedDateTimeBegin() {
    this.appointment.begin = CommonsService.parseDateTime($("#input-date-begin").val())
    this.appointment.end = CommonsService.parseDateTime($("#input-date-end").val())

    if (this.appointment.end.getTime() < this.appointment.begin.getTime()) {
      $("#input-date-end").val($("#input-date-begin").val())
    }
  }

  changedDateTimeEnd() {
    this.appointment.begin = CommonsService.parseDateTime($("#input-date-begin").val())
    this.appointment.end = CommonsService.parseDateTime($("#input-date-end").val())

    if (this.appointment.end.getTime() < this.appointment.begin.getTime()) {
      $("#input-date-begin").val($("#input-date-end").val())
    }
  }

  selectCustomer() {
    $('#selectCustomerModal').modal('show')
    this.viewCustomersSelect.resetSearch()
    this.viewCustomersSelect.setFocusToSearchField()
  }

  selectedCustomerModal(customer: any) {
    $('#selectCustomerModal').modal('hide')
    this.customer = customer
    this.appointment.uuidCustomer = customer.uuid
    this.nameCustomer = this.customer.firstName + " " + this.customer.lastName;
    this.showLinkCustomer = true
    log("selected cutomer", this)
  }

  clearCustomer() {
    this.customer = new CustomerModel()
    this.appointment.uuidCustomer = ""
    this.nameCustomer = ""
    this.showLinkCustomer = false
  }
}
