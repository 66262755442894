import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
declare const enableLogging: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'futureapp';
  constructor(
    private translate: TranslateService) {
    translate.setDefaultLang('de');
  }

  ngOnInit() {
    enableLogging(environment.enableLogging);
    // test translation
    this.translate.get('NAV_CUSTOMERS').subscribe((translated: string) => {
      console.log("Testing the translation: " + translated);
      const translation = this.translate.instant('NAV_CUSTOMERS');
      console.log("Testing the translation: " + translation);
    });
  }

}
