import { Location } from '@angular/common';
import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonsService } from './commons-service';
import { DataNotAvailableError } from './data-not-available-error';
import { DbNoConnectionError } from './db-no-connection-error';
import { DbOutOfDateError } from './db-out-of-date-error';
declare const log: any;

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {

  constructor(
    private translate: TranslateService,
    private location: Location,
    private router: Router,
    private ngZone: NgZone) {
  }

  handleError(error: any): void {
    console.error(error);
    this.ngZone.run(() => {
      this.translate.get("ERROR_UNCAUGHT_ERROR").subscribe((translated) => this.handleErrorAsync(error))
    });
  }

  private async handleErrorAsync(error: any) {

    if (error.promise && error.rejection) {
      // Promise rejection wrapped
      error = error.rejection;
    }
    log("Error")
    log(error)

    if (error instanceof DbOutOfDateError) {
      this.router.navigateByUrl("/start")
      CommonsService.showErrorMessage(this.translate.instant("ERROR_DB_OUT_OF_DATE"))
    } else if (error instanceof DataNotAvailableError) {
      let this_ = this
      this.location.back()
        this_.translate.get('ERROR_DATA_NOT_AVAILABLE').subscribe((translated: string) => {
          CommonsService.showErrorMessage(translated)
        });
    } else if (error instanceof DbNoConnectionError) {
      this.router.navigateByUrl("/start")
      CommonsService.showErrorMessage(this.translate.instant("ERROR_DB_NO_CONNECTION"))
    } else {
      CommonsService.showErrorMessage(this.translate.instant("ERROR_UNCAUGHT_ERROR") + " (" + error.message + ")")
    }
  }

}
