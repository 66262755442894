<div role="main">
  <div class="">
    <div class="page-title">
      <div class="title_left">
        <h3>
          {{ "PARTNERS_TITLE" | translate }}

          <div class="display-inline">
            -
            <span
              class="cursor-pointer"
              routerLink="/editCustomer/{{ customer.uuid }}"
              title="{{ 'PARTNERS_TITLE_TOOLTIP_CUSTOMER' | translate }}"
              >{{ textNameCustomer }}</span
            >
            <span
              class="span-icon-header"
              routerLink="/editCustomer/{{ customer.uuid }}"
              data-toggle="tooltip"
              data-placement="top"
              title="{{ 'PARTNERS_TITLE_TOOLTIP_CUSTOMER' | translate }}"
            >
              <i class="mdi mdi-account icon-header"></i
            ></span>
          </div>
        </h3>
      </div>
    </div>
  </div>

  <div class="clearfix"></div>

  <div class="x_panel">
    <div class="x_title display-flex">
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-3">
        <button
          class="btn btn-default"
          type="button"
          routerLink="/addPartner/{{ customer.uuid }}"
          data-toggle="tooltip"
          data-placement="top"
          title="{{ 'PARTNERS_TOOLTIP_ADD' | translate }}"
        >
          {{ "PARTNERS_BUTTON_NEW" | translate }}
          <i class="mdi mdi-plus-circle indicator-color-green"></i>
        </button>
      </div>
    </div>

    <div class="x_content">
      <table class="table table-striped">
        <thead>
          <tr>
            <th class="table-header-col-no-sort">
              {{ "PARTNERS_TABLE_FIRSTNAME" | translate }}
            </th>
            <th class="table-header-col-no-sort">
              {{ "PARTNERS_TABLE_LASTNAME" | translate }}
            </th>
            <th class="table-header-col-no-sort">
              {{ "PARTNERS_TABLE_POSITION" | translate }}
            </th>
            <th class="tableColWidthIcon table-header-col">
              <div
                routerLink="/addPartner/{{ uuidCustomer }}"
                data-toggle="tooltip"
                data-placement="top"
                title="{{ 'PARTNERS_TABLE_TOOLTIP_ADD' | translate }}"
              >
                <i class="mdi mdi-plus-circle indicator-color-green"></i>
              </div>
            </th>
            <!-- th class="tableColWidthIcon"></th -->
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let partner of partners
                | limitFromTo
                  : currentPage * pageSize
                  : (currentPage + 1) * pageSize;
              let i = index
            "
          >
            <td routerLink="/editPartner/{{ partner.uuid }}">
              {{ partner.firstName }}
            </td>
            <td routerLink="/editPartner/{{ partner.uuid }}">
              {{ partner.lastName }}
            </td>
            <td routerLink="/editPartner/{{ partner.uuid }}">
              {{ partner.position }}
            </td>

            <td ng-show="1">
              <div class="dropdown">
                <button
                  class="btn dropdown-toggle btn-default btn-action"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {{
                    "GENERAL_TABLE_DROPDOWN_ACTIONS" | translate | capitalize
                  }}
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/editPartner/{{ partner.uuid }}"
                      data-toggle="tooltip"
                      data-placement="top"
                    >
                      <i class="mdi mdi-pencil"></i>&nbsp;{{
                        "PARTNERS_TABLE_TOOLTIP_EDIT" | translate | capitalize
                      }}
                    </a>
                  </li>

                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/deletePartner/{{ partner.uuid }}"
                      data-toggle="tooltip"
                      data-placement="top"
                    >
                      <i class="mdi mdi-delete-outline"></i>&nbsp;{{
                        "PARTNERS_TABLE_TOOLTIP_DELETE" | translate | capitalize
                      }}
                    </a>
                  </li>
                </ul>
              </div>
            </td>

            <!-- td class="tableColWidthIcon"><a href="#/editPartner/{{idCustomerAtClient}}/{{entry.idAtClient}}"
          ng-click="$event.stopPropagation();"
          data-toggle="tooltip" data-placement="top" title="{{ 'PARTNERS_TABLE_TOOLTIP_EDIT' | translate }}">
          <i class="mdi mdi-pencil"></i></a></td>
        <td class="tableColWidthIcon"><a href="#/deletePartner/{{idCustomerAtClient}}/{{entry.idAtClient}}"
          ng-click="$event.stopPropagation();"
          data-toggle="tooltip" data-placement="top" title="{{ 'PARTNERS_TABLE_TOOLTIP_DELETE' | translate }}">
          <i class="mdi mdi-delete-outline"></i></a></td -->
          </tr>
        </tbody>
      </table>

      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <button
          class="btn btn-sm btn-default btn-navigation"
          [disabled]="currentPage == 0 || numberOfPages() == 0"
          (click)="currentPage = 0; storeCurrentPage()"
        >
          {{ "NAVIGATION_PAGE_FIRST" | translate }}
        </button>
        <button
          class="btn btn-sm btn-default btn-navigation"
          [disabled]="currentPage == 0 || numberOfPages() == 0"
          (click)="currentPage = currentPage - 1; storeCurrentPage()"
        >
          {{ "NAVIGATION_PAGE_PREVIOUS" | translate }}
        </button>
        <span class="text-navigation">
          {{ currentPage + 1 }}/{{ numberOfPages() }}
        </span>
        <button
          class="btn btn-sm btn-default btn-navigation"
          [disabled]="currentPage >= numberOfPages() - 1"
          (click)="currentPage = currentPage + 1; storeCurrentPage()"
        >
          {{ "NAVIGATION_PAGE_NEXT" | translate }}
        </button>
        <button
          class="btn btn-sm btn-default btn-navigation"
          [disabled]="currentPage >= numberOfPages() - 1"
          (click)="currentPage = numberOfPages() - 1; storeCurrentPage()"
        >
          {{ "NAVIGATION_PAGE_LAST" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
