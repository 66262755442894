import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ViewArticlesComponent } from '../view-articles/view-articles.component';
import { ParametersStartService } from '../parameters-start.service';
import { RepositoryService } from '../repository.service';
declare const log: any;

@Component({
  selector: 'app-view-articles-select',
  templateUrl: '../view-articles/view-articles.component.html',
  styleUrls: ['./view-articles-select.component.css']
})
export class ViewArticlesSelectComponent extends ViewArticlesComponent implements OnInit {

  @Output() selectedArticleModal = new EventEmitter<string>();

  override showLinks = false;

  constructor(
    router: Router,
    repository: RepositoryService,
    parametersStart: ParametersStartService) {
    super(router, repository, parametersStart)
  }

  override ngOnInit(): void {
    super.ngOnInit()
  }

  override selectedArticle(article: any) {
    this.selectedArticleModal.emit(article)
  }
}
