import { AfterViewInit, Component, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core'
import { ParametersStartService } from '../parameters-start.service';
import { UUID } from 'angular2-uuid';
import { ViewOpenSalesSelectComponent } from '../view-open-sales-select/view-open-sales-select.component';
import { RepositoryService } from '../repository.service';
import { TimeRecordingModel } from '../models/time-recording-model';
import { NetworkService } from '../network.service';
import { EditMaintenanceComponent } from '../edit-maintenance/edit-maintenance.component';
import { EditSaleComponent } from '../edit-sale/edit-sale.component';
import { CommonsService } from '../commons-service';
import { LoginComponent } from '../login/login.component';
import { Router } from '@angular/router';
declare const log: any;
declare const $: any

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.css'],
})

export class NavigationBarComponent implements AfterViewInit {
  environment = environment;
  editMaintenanceComponent: EditMaintenanceComponent | null = null
  editSaleComponent: EditSaleComponent | null = null

  @ViewChild(ViewOpenSalesSelectComponent) viewOpenSalesSelect!: ViewOpenSalesSelectComponent;

  ngAfterViewInit() {
    // viewOpenSalesSelect is set
  }

  onActivateRouterOutlet(component: any) {
    if (component instanceof EditMaintenanceComponent)
      this.editMaintenanceComponent = component
    else
      this.editMaintenanceComponent = null

    if (component instanceof EditSaleComponent)
      this.editSaleComponent = component
    else
      this.editSaleComponent = null

    if (!(component instanceof LoginComponent) &&
      this.networkService.isOnline &&
      this.networkService.hasServer &&
      this.parametersStart.nameUser == "")
      this.router.navigateByUrl("/login");
  }

  constructor(
    private translate: TranslateService,
    public parametersStart: ParametersStartService,
    private repository: RepositoryService,
    public networkService: NetworkService,
    private router: Router) {
  }

  ngOnInit(): void {
    var this_ = this;

    var $BODY = $("#div_body");
    var $MENU_TOGGLE = $("#menu_toggle");
    var $SIDEBAR_MENU = $("#sidebar-menu");

    $SIDEBAR_MENU.find("a").on("click", function (ev: any) {
      console.log("clicked - sidebar_menu", ev);
      var $li = $(ev.target).parent();

      if ($li.is(".active")) {
        $li.removeClass("active active-sm");
        $("ul:first", $li).slideUp(function () {
          this_.setContentHeight();
        });
      } else {
        // prevent closing menu if we are on child menu
        if (!$li.parent().is(".child_menu")) {
          $SIDEBAR_MENU.find("li").removeClass("active active-sm");
          $SIDEBAR_MENU.find("li ul").slideUp();
        } else {
          if ($BODY.is(".nav-sm")) {
            $SIDEBAR_MENU.find("li").removeClass("active active-sm");
            $SIDEBAR_MENU.find("li ul").slideUp();
          }
        }
        // $li.addClass("active");

        $("ul:first", $li).slideDown(function () {
          this_.setContentHeight();
        });
      }
    });

    // toggle small or large menu
    $MENU_TOGGLE.on("click", function () {
      console.log("clicked - menu toggle");

      if ($BODY.hasClass("nav-md")) {
        $SIDEBAR_MENU.find("li.active ul").hide();
        $SIDEBAR_MENU
          .find("li.active")
          .addClass("active-sm")
          .removeClass("active");
      } else {
        $SIDEBAR_MENU.find("li.active-sm ul").show();
        $SIDEBAR_MENU
          .find("li.active-sm")
          .addClass("active")
          .removeClass("active-sm");
      }

      $BODY.toggleClass("nav-md nav-sm");

      this_.setContentHeight();

      $(".dataTable").each(function () {
        $(this_).dataTable().fnDraw();
      });
    });

    this.init()
  }

  async init() {
    this.repository.readNameUser()
      .then(
        (nameUser) => {
          log("nameUser: " + nameUser)
          this.parametersStart.nameUser = nameUser;
        },
        () => {
          // no user logged in
        }
      )

    let uuidRecord = await this.repository.readUtilityData("timeRecordingUuidRecord")
    let this_ = this

    if (uuidRecord == null || uuidRecord == "")
      this.translate.get('NAV_TIME_RECORDING_START').subscribe(function (translated) {
        this_.parametersStart.buttonStartText = translated;
        this_.parametersStart.buttonStartColor = "indicator-green";
      });
    else
      this.translate.get('NAV_TIME_RECORDING_STOP').subscribe(function (translated) {
        this_.parametersStart.buttonStartText = translated;
        this_.parametersStart.buttonStartColor = "indicator-red";
      });
  }

  async logout() {
    this.networkService.logout()
    this.parametersStart.buttonLoginLogoutText = "Login";
    this.parametersStart.buttonLoginLogoutEnabled = true;
    CommonsService.showSuccessMessage(this.translate.instant("GENERAL_LOGGED_OUT"))

    this.router.navigateByUrl("/login")
  }

  async doSynchronization() {
    //await this.synchronizationService.syncronize();
    //window.location.reload()
  }

  async startStopTimerecording() {
    let this_ = this
    let uuidRecord = await this.repository.readUtilityData("timeRecordingUuidRecord")
    let recordType = await this.repository.readUtilityData("timeRecordingType")
    log("timeredoc record " + recordType + ": " + uuidRecord)

    if (uuidRecord == null || uuidRecord == "") {
      // no time recording running; start a new one
      if (this.editMaintenanceComponent) {
        await this.selectedRecordForTimeRecording(this.editMaintenanceComponent.uuidMaintenance, "Maintenance", this.editMaintenanceComponent.maintenance.uuidCustomer)
        this.editMaintenanceComponent.setRunningTimeRecording(true)
      } else if (this.editSaleComponent && this.editSaleComponent.typeSale == "Order") {
        // take the order currently displayed/edited
        await this.selectedRecordForTimeRecording(this.editSaleComponent.sale.uuid, "Order", this.editSaleComponent.sale.uuidCustomer)
        this.editSaleComponent.setRunningTimeRecording(true)
      } else {
        // select open order from list
        await this.viewOpenSalesSelect.search() // load currently available orders
        $('#selectOrderModal').modal('show')
      }

    } else {
      // time recording is running ==> stop it?
      //$translate('MESSAGE_STOP_TIME_RECORDING_CONFIRM').then(function (textMsg)
      // ask for confirmation
      var r = true;
      // var r = confirm(textMsg);
      if (!r)
        return;

      // stop the time recording
      let timeRecording: TimeRecordingModel = new TimeRecordingModel();
      timeRecording.uuid = UUID.UUID();
      if (recordType == "Order")
        timeRecording.uuidOrder = uuidRecord;
      else if (recordType == "Maintenance")
        timeRecording.uuidMaintenance = uuidRecord;
      let textDateStart = await this.repository.readUtilityData("timeRecordingDateStart")
      timeRecording.begin = new Date(textDateStart);
      timeRecording.end = new Date();
      // calculate duration in hours
      timeRecording.duration = (timeRecording.end.getTime() - timeRecording.begin.getTime()) / (1000 * 60 * 60);

      let uuidCustomer = await this.repository.readUtilityData("timeRecordingUuidCustomer")
      timeRecording.uuidCustomer = uuidCustomer ? uuidCustomer : "";

      await this.repository.timeRecordings.create(timeRecording)

      this.translate.get('NAV_TIME_RECORDING_STOPPED').subscribe(function (translated) {
        CommonsService.showSuccessMessage(translated)
      });

      // reset
      await this.repository.updateUtilityData("timeRecordingUuidRecord", "");

      // reset button appearance
      this_.translate.get('NAV_TIME_RECORDING_START').subscribe(function (translated) {
        this_.parametersStart.buttonStartText = translated;
        this_.parametersStart.buttonStartColor = "indicator-green";

        if (recordType == "Order" && this_.editSaleComponent && this_.editSaleComponent.typeSale == "Order"
          && this_.editSaleComponent.uuidSale == uuidRecord) {
          this_.editSaleComponent.setRunningTimeRecording(false)
        } else if (recordType == "Maintenance" && this_.editMaintenanceComponent
          && this_.editMaintenanceComponent.uuidMaintenance == uuidRecord) {
          this_.editMaintenanceComponent.setRunningTimeRecording(false)
        }
      });
    }
  }

  private setContentHeight() {

  }

  async selectedOrderForTimeRecording(order: any) {
    await this.selectedRecordForTimeRecording(order.uuid, "Order", order.uuidCustomer)
  }

  async selectedRecordForTimeRecording(uuidRecord: string, typeRecord: string, uuidCustomer: string) {

    //$translate('NAV_START_TIME_RECORDING_WITH_ORDER').
    // ask for confirmation
    var r = true;
    // var r = confirm(textMsg);

    if (!r)
      return

    // start time recording
    await this.repository.updateUtilityData("timeRecordingUuidRecord", uuidRecord);
    await this.repository.updateUtilityData("timeRecordingType", typeRecord);
    await this.repository.updateUtilityData("timeRecordingUuidCustomer", uuidCustomer);
    await this.repository.updateUtilityData("timeRecordingDateStart", new Date().toISOString());

    let this_ = this
    this.translate.get('NAV_TIME_RECORDING_STOP').subscribe(function (translated) {
      this_.parametersStart.buttonStartText = translated;
      this_.parametersStart.buttonStartColor = "indicator-red";
    });

    $('#selectOrderModal').modal('hide')

    this.translate.get('NAV_TIME_RECORDING_STARTED').subscribe(function (translated) {
      CommonsService.showSuccessMessage(translated)
    });
  }
}


