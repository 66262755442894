import { Injectable } from "@angular/core";
import { ModelBuilder } from "./model-builder";
import { ModelInterface } from "./model-interface";

@Injectable({
  providedIn: 'root'
})
export class ModelFactory<T extends ModelInterface> {

  newModel(clazz: ModelBuilder<T>, params: any = null): T {
    return clazz.newModel(params)
  }

  newModels(clazz: ModelBuilder<T>, params: any[]) {
    let models: T[] = []
    for (let i = 0; i < params.length; i++) {
      models.push(<T>clazz.newModel(params[i]))
    }
    return models
  }
}



