import { BaseModel } from "./base-model";

export class CustomerCategoryModel extends BaseModel {

  name: string = ""

  constructor(params: any = null) {
    super(params)
    this.copyFromObject(params)
  }

  static override newModel(object: any = null) {
    return new CustomerCategoryModel(object)
  }
}
