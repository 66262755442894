<div role="main">
  <div class="">
    <div class="page-title">
      <div class="title_left">
        <h3>
          {{ "CUSTOMERS_SUGG_FREQ_TITLE" | translate }}
        </h3>
      </div>
    </div>
  </div>

  <div class="clearfix"></div>

  <div class="x_panel">
    <div class="x_content">
      <table class="table table-striped">
        <thead>
          <tr>
            <th class="tableColWidthM table-header-col-no-sort">
              {{ "CUSTOMERS_SUGG_FREQ_TABLE_FIRST_NAME" | translate }}
            </th>
            <th class="tableColWidthM table-header-col-no-sort">
              {{ "CUSTOMERS_SUGG_FREQ_TABLE_LAST_NAME" | translate }}
            </th>
            <th class="tableColWidthM table-header-col-no-sort">
              {{ "CUSTOMERS_SUGG_FREQ_TABLE_STREET" | translate }}
            </th>
            <th class="tableColWidthM table-header-col-no-sort">
              {{ "CUSTOMERS_SUGG_FREQ_TABLE_ZIP_CODE" | translate }}
            </th>
            <th class="tableColWidthM table-header-col-no-sort">
              {{ "CUSTOMERS_SUGG_FREQ_TABLE_CITY" | translate }}
            </th>
            <th class="tableColWidthM table-header-col-no-sort">
              {{ "CUSTOMERS_SUGG_FREQ_TABLE_NEXT_CONTACT" | translate }}
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            *ngFor="let customer of customers; let i = index"
            routerLink="/editCustomer/{{ customer.uuid }}"
          >
            <td>{{ customer.firstName }}</td>
            <td>{{ customer.lastName }}</td>
            <td>{{ customer.street }}</td>
            <td>{{ customer.zipCode }}</td>
            <td>{{ customer.city }}</td>
            <td>{{ customer.textNextContact }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
