import { ArticleModel } from "./article-model";
import { BaseModel } from "./base-model";

export class MaintenanceMaterialModel extends BaseModel {

  uuidPart: string = ""
  amount = 1
  priceUnit: string = ""
  priceUnitV = 0
  priceSum: string = ""
  priceSumV = 0
  uuidMaintenance:  string = ""

  article: ArticleModel | null = null
  namePart: string = ""

  constructor(params: any = null) {
    super(params)
    this.copyFromObject(params)
  }

  static override newModel(object: any = null) {
    return new MaintenanceMaterialModel(object)
  }
}
