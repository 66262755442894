import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common'
import { TranslateService } from '@ngx-translate/core';
import { ParametersStartService } from '../parameters-start.service';
import { CommonsService } from '../commons-service';
import { RepositoryService } from '../repository.service';
import { CustomerModel } from '../models/customer-model';
import { SaleModel } from '../models/sale-model';
import { CompanyModel } from '../models/company-model';
import { SalePositionModel } from '../models/sale-position-model';
import { CountryModel } from '../models/country-model';
declare const log: any;

@Component({
  selector: 'app-print-sale',
  templateUrl: './print-sale.component.html',
  styleUrls: ['./print-sale.component.css']
})
export class PrintSaleComponent implements OnInit {

  typeSale = "Offer"
  uuidSale = ""
  textTitle = ""
  company: CompanyModel = new CompanyModel()
  customer: CustomerModel = new CustomerModel()
  sale: SaleModel = new SaleModel()
  showSignatureImage = false
  textPaymentType = ""
  textCountry = ""
  mapNetVTaxVByTaxFactor: Map<number, any> = new Map()

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    protected translate: TranslateService,
    private parametersStart: ParametersStartService,
    private repository: RepositoryService,
  ) { }

  ngOnInit(): void {
    this.init()
  }

  async init() {
    setTimeout(() => {
      this.parametersStart.showNavbar = false
    });
    const routeParams = this.route.snapshot.paramMap;
    this.typeSale = String(routeParams.get('type'));
    this.uuidSale = String(routeParams.get('uuidSale'));

    if (this.typeSale === "Offer") {
      this.sale = await this.repository.readOffer(this.uuidSale)
      this.translate.get('SALE_PRINT_TITLE_OFFER').subscribe((translated: string) => {
        this.textTitle = translated;
      });
    } else if (this.typeSale === "Order") {
      this.sale = await this.repository.readOrder(this.uuidSale)
      this.translate.get('SALE_PRINT_TITLE_ORDER').subscribe((translated: string) => {
        this.textTitle = translated;
      });
    } else if (this.typeSale === "Invoice") {
      this.sale = await this.repository.readInvoice(this.uuidSale)
      this.translate.get('SALE_PRINT_TITLE_INVOICE').subscribe((translated: string) => {
        this.textTitle = translated;
      });
    } else
      throw "Error, unknown sale type " + this.typeSale

    let this_ = this
    this.company = await this.repository.readCompany()

    if (this.sale.signatureImage != null && typeof (this.sale.signatureImage) != 'undefined' && this.sale.signatureImage != "")
      this.showSignatureImage = true
    else
      this.showSignatureImage = false

    let fDiscountSale = 1.0 - this.sale.discount;
    this.mapNetVTaxVByTaxFactor.clear()

    this.sale.positions.forEach(function (position: SalePositionModel) {
      var taxPerc = Math.round(position.getTaxFactor() * 100.0);

      var netVTaxV = this_.mapNetVTaxVByTaxFactor.get(taxPerc);
      if (!netVTaxV) {
        // new tax value
        let netV = fDiscountSale * position.priceSumNetV;
        let taxV = fDiscountSale * (position.priceSumGrossV - position.priceSumNetV);
        this_.mapNetVTaxVByTaxFactor.set(taxPerc,
          {
            "netV": netV, "taxV": taxV,
            "net": CommonsService.convertValueToPrice(netV, this_.parametersStart.currency),
            "tax": CommonsService.convertValueToPrice(taxV, this_.parametersStart.currency)
          })
      } else {
        // existing tax value
        let netV = netVTaxV.netV + fDiscountSale * position.priceSumNetV;
        let taxV = netVTaxV.taxV + fDiscountSale * (position.priceSumGrossV - position.priceSumNetV);
        this_.mapNetVTaxVByTaxFactor.set(taxPerc, {
          "netV": netV, "taxV": taxV,
          "net": CommonsService.convertValueToPrice(netV, this_.parametersStart.currency),
          "tax": CommonsService.convertValueToPrice(taxV, this_.parametersStart.currency)
        })
      }
    });


    let paymentTypes = await this.repository.paymentTypes.readAll()
    let paymentType: any = CommonsService.getObjectByUuid(paymentTypes, this.sale.uuidPaymentType)
    if (paymentType)
      this.textPaymentType = paymentType.name;

    let countries = await this.repository.countries.readAll()
    let country: CountryModel = CommonsService.getObjectByUuid(countries, this.sale.uuidCountry)
    if (country)
      this.textCountry = country.name

    this.customer = <CustomerModel>await this.repository.customers.read(this.sale.uuidCustomer, true)
    if (!this.customer)
      this.customer = new CustomerModel()
  }

  ngOnDestroy(): void {
    this.parametersStart.showNavbar = true
  }

  goBack() {
    this.location.back()
  }

}
