import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonsService } from '../commons-service';
import { environment } from 'src/environments/environment';
import { ParametersStartService } from '../parameters-start.service'
import { UUID } from 'angular2-uuid';
import { RepositoryService } from '../repository.service';
import { CancellationReasonModel } from '../models/cancellation-reason-model';
import { PaymentTypeModel } from '../models/payment-type-model';
import { SaleModel } from '../models/sale-model';
import { StateOrderModel } from '../models/state-order-model';
import { OfferModel } from '../models/offer-model';
import { OrderModel } from '../models/order-model';
import { InvoiceModel } from '../models/invoice-model';
import { SalePositionModel } from '../models/sale-position-model';
import { OfferPositionModel } from '../models/offer-position-model';
import { OrderPositionModel } from '../models/order-position-model';
import { InvoicePositionModel } from '../models/invoice-position-model';
import { ModelFactory } from '../models/model-factory';
import { ModelInterface } from '../models/model-interface';
import { Location } from '@angular/common';
import { TaxRateModel } from '../models/tax-rate-model';
import { ViewArticlesSelectComponent } from '../view-articles-select/view-articles-select.component';
import { CountryModel } from '../models/country-model';
declare var $: any;
declare const log: any;
declare const SignaturePad: any;

@Component({
  selector: 'app-edit-sale',
  templateUrl: './edit-sale.component.html',
  styleUrls: ['./edit-sale.component.css'],
  providers: []
})
export class EditSaleComponent implements OnInit {

  environment = environment

  typeSale = "Offer"
  uuidSale = ""
  isSaleNew = false

  //nameModel = "Angebot"
  //nameModelPosition = "Angebotspositionen"

  sale: SaleModel = new SaleModel()
  saleAsOffer: OfferModel = new OfferModel()
  saleAsOrder: OrderModel = new OrderModel()
  saleAsInvoice: InvoiceModel = new InvoiceModel()
  saleBeforeEdit: SaleModel = new SaleModel()
  discount: string = "0"

  countries: CountryModel[] = [];
  cancellationReasons: CancellationReasonModel[] = []
  paymentTypes: PaymentTypeModel[] = []
  statesOrder: StateOrderModel[] = []
  taxRates: TaxRateModel[] = []

  textTitle = ""
  textTimeRecording = ""

  showButtonCustomer = true;
  enableButtonCustomer = false;
  enablePaymentType = false;
  enableCancellationReasons = false;
  showButtonMakeOrder = false;
  enableButtonMakeOrder = false;
  showLinkOrder = false;
  enableLinkOrder = false;
  showLinkOffer = false;
  enableLinkOffer = false;
  enableStatesOrder = false;
  showLinkInvoice = false;
  enableLinkInvoice = false;
  showButtonMakeInvoice = false;
  enableButtonMakeInvoice = false;
  enableSignaturePad = environment.enableSignaturePad
  signaturePad: any
  textDate = ""

  @ViewChild(ViewArticlesSelectComponent) viewArticlesSelect!: ViewArticlesSelectComponent;

  constructor(
    protected location: Location,
    protected router: Router,
    private route: ActivatedRoute,
    protected translate: TranslateService,
    private modelFactory: ModelFactory<ModelInterface>,
    private repository: RepositoryService,
    private parametersStart: ParametersStartService
  ) { }

  ngOnInit(): void {
    this.init()
  }

  async init() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    await CommonsService.showProcessingMessage("editSale init")

    const routeParams = this.route.snapshot.paramMap;
    this.typeSale = String(routeParams.get('type'));
    this.uuidSale = String(routeParams.get('uuidSale'));
    this.isSaleNew = String(routeParams.get('isNew')) == "true";

    if (this.typeSale == "Offer") {
      if (this.isSaleNew)
        this.translate.get("SALE_NEW_TITLE_OFFER")
          .subscribe((translated: string) => { this.textTitle = translated }
          )
      else
        this.translate.get("SALE_EDIT_TITLE_OFFER")
          .subscribe((translated: string) => { this.textTitle = translated }
          )
    } else if (this.typeSale == "Order") {
      if (this.isSaleNew)
        this.translate.get("SALE_NEW_TITLE_ORDER")
          .subscribe((translated: string) => { this.textTitle = translated }
          )
      else
        this.translate.get("SALE_EDIT_TITLE_ORDER")
          .subscribe((translated: string) => { this.textTitle = translated }
          )
    } else if (this.typeSale == "Invoice") {
      if (this.isSaleNew)
        this.translate.get("SALE_NEW_TITLE_INVOICE")
          .subscribe((translated: string) => { this.textTitle = translated }
          )
      else
        this.translate.get("SALE_EDIT_TITLE_INVOICE")
          .subscribe((translated: string) => { this.textTitle = translated }
          )
    } else
      throw "Error: unknown sales type '" + this.typeSale + "'"

    this.discount = "0.0"

    this.countries = await this.repository.countries.readAll()
    this.cancellationReasons = <CancellationReasonModel[]>await this.repository.cancellationReasons.readAll()
    this.paymentTypes = <PaymentTypeModel[]>await this.repository.paymentTypes.readAll()
    this.statesOrder = <StateOrderModel[]>await this.repository.statesOrder.readAll()

    let saleToBeEdited = new SaleModel()
    try {
      if (this.typeSale == "Offer") {
        saleToBeEdited = await this.repository.readOffer(this.uuidSale)
        this.saleAsOffer = <OfferModel>saleToBeEdited
      } else if (this.typeSale == "Order") {
        this.showButtonMakeInvoice = true;
        saleToBeEdited = await this.repository.readOrder(this.uuidSale)
        this.saleAsOrder = <OrderModel>saleToBeEdited
      } else if (this.typeSale == "Invoice") {
        saleToBeEdited = await this.repository.readInvoice(this.uuidSale)
        this.saleAsInvoice = <InvoiceModel>saleToBeEdited
      } else {
        await CommonsService.hideProcessingMessage("editSale init")
        throw new Error("Unknown sale type " + this.typeSale)
      }
    } catch (err) {
      await CommonsService.hideProcessingMessage("editSale init")
      throw err
    }

    this.taxRates = await this.repository.taxRates.readAll()
    for (let position of saleToBeEdited.positions) {
      position.taxRate = <TaxRateModel | null>CommonsService.getObjectByUuid(this.taxRates, position.uuidTaxRate)
    }

    this.setSaleToBeEdited(saleToBeEdited)
    log("sale", this.sale)

    // recalc and save sale
    await this.saveSaleFinal(false)

    this.enableButtonCustomer = this.sale.uuidCustomer && this.sale.uuidCustomer != "" ? true : false;

    // display parameters
    if (this.typeSale == "Offer") {
      this.enablePaymentType = true;
      this.showButtonMakeOrder = true;
      this.showLinkOrder = true;
      if (this.isSaleNew) {
        this.enableCancellationReasons = false;
        this.enableButtonMakeOrder = true;
        this.enableLinkOrder = false;
      } else if ((<OfferModel>this.sale).uuidOrder && (<OfferModel>this.sale).uuidOrder != "") {
        this.enableCancellationReasons = false;
        this.enableButtonMakeOrder = false;
        this.enableLinkOrder = true;
      } else {
        this.enableCancellationReasons = true;
        let cancellationReason = CommonsService.getObjectByUuid(this.cancellationReasons, this.sale.uuidCancellationReason)
        this.enableButtonMakeOrder = !cancellationReason || !cancellationReason.name || cancellationReason.name == ""
        this.enableLinkOrder = false;
      }
      this.enableLinkOffer = false;
      this.enableStatesOrder = false;
    } else if (this.typeSale == "Order") {
      this.enableCancellationReasons = false;
      this.showLinkOffer = true
      this.showLinkInvoice = true;
      if ((<OrderModel>this.sale).uuidOffer && (<OrderModel>this.sale).uuidOffer != "")
        this.enableLinkOffer = true;
      else
        this.enableLinkOffer = false;
      if ((<OrderModel>this.sale).uuidInvoice && (<OrderModel>this.sale).uuidInvoice != "") {
        this.enableLinkInvoice = true;
        this.enableButtonMakeInvoice = false;
      } else {
        this.enableLinkInvoice = false;
        this.enableButtonMakeInvoice = true;
      }
      this.enablePaymentType = true;
      this.enableButtonMakeOrder = false;
      this.enableLinkOrder = false;
      this.enableStatesOrder = true;

      // get state of time recording
      let uuidRecord = await this.repository.readUtilityData("timeRecordingUuidRecord")
      let recordType = await this.repository.readUtilityData("timeRecordingType")
      if (recordType == "Order" && uuidRecord != "" && uuidRecord == this.sale.uuid) {
        this.setRunningTimeRecording(true)
      } else {
        //this.translate.get("SALE_EDIT_TITLE_ORDER")
        //.subscribe((translated: string) => { this.textTitle = translated }
        //)
      }
    } else { // "Invoice"
      this.showLinkOrder = true;
      this.enableCancellationReasons = false;
      this.enablePaymentType = true;
      this.enableButtonMakeOrder = false;
      this.enableButtonMakeInvoice = false;
      this.enableLinkOrder = (<InvoiceModel>this.sale).uuidOrder != null && (<InvoiceModel>this.sale).uuidOrder != "";
      this.enableStatesOrder = false;
      this.enableSignaturePad = false;
    }

    this.textDate = CommonsService.formatDate0(this.sale.date)
    $('#div-date').datetimepicker({
      format: 'DD.MM.YYYY'
    });

    //
    this.signaturePad = new SignaturePad(document.getElementById('canvas-signature-pad'), {
      backgroundColor: 'rgba(255, 255, 255, 0)',
      penColor: 'rgb(0, 0, 0)'
    });
    // scale up on devices with hight DPI
    let canvas: any = document.getElementById('canvas-signature-pad');
    var ratio = Math.max(window.devicePixelRatio || 1, 1);
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext("2d").scale(ratio, ratio);

    // redraw the signature on the sig pad
    // $('.sigPad').signaturePad().regenerate(sale.signatureArray);
    // log("signature fromDataURL: ", sale.signatureImage);
    if (this.sale.signatureImage) {
      //Vlog("signature prev: " + sale.signatureImage.substring(0, 200));
      log("signature fromDataURL: ", this.sale.signatureImage);
      this.signaturePad.fromDataURL(this.sale.signatureImage);
    } else {
      log("signature from is empty")
      // signaturePad.clear();
    }

    await CommonsService.hideProcessingMessage("editSale init")

    log("show button make invoice " + this.showButtonMakeInvoice);
  }

  async setRunningTimeRecording(isRunning: boolean) {
    if (isRunning) {
      // time recording is running for the sale currently displayed
      this.translate.get("SALE_TIME_RECORDING_RUNNING")
        .subscribe((translated: string) => {
          this.textTimeRecording = translated
          this.translate.get("SALE_EDIT_TITLE_ORDER")
            .subscribe((translated: string) => { this.textTitle = translated + " " + this.textTimeRecording }
            )
        }
        )
    } else {
      this.translate.get("SALE_EDIT_TITLE_ORDER")
        .subscribe((translated: string) => { this.textTitle = translated }
        )
    }
  }

  async gotoOrder() {
    if (!this.enableLinkOrder) {
      CommonsService.showErrorMessage(this.translate.instant("GENERAL_NO_SALE_LINKED"))
      return;
    }
    await CommonsService.showProcessingMessage("gotoOrder")
    CommonsService.startTime("saveSale")
    await this.saveSale()
    CommonsService.stopTime("saveSale")
    await CommonsService.hideProcessingMessage("gotoOrder")

    this.router.navigateByUrl("/editSale/Order/" + (<InvoiceModel>this.sale).uuidOrder + "/false")
  }

  async gotoOffer() {
    if (!this.enableLinkOffer) {
      CommonsService.showErrorMessage(this.translate.instant("GENERAL_NO_SALE_LINKED"))
      return;
    }
    await CommonsService.showProcessingMessage("gotoOffer")
    CommonsService.startTime("saveSale")
    await this.saveSale()
    CommonsService.stopTime("saveSale")
    await CommonsService.hideProcessingMessage("gotoOffer")

    this.router.navigateByUrl("/editSale/Offer/" + (<OrderModel>this.sale).uuidOffer + "/false")
  }

  async gotoInvoice() {
    if (!this.enableLinkInvoice) {
      CommonsService.showErrorMessage(this.translate.instant("GENERAL_NO_SALE_LINKED"))
      return;
    }
    await CommonsService.showProcessingMessage("gotoInvoice")
    CommonsService.startTime("saveSale")
    await this.saveSale()
    CommonsService.stopTime("saveSale")
    await CommonsService.hideProcessingMessage("gotoInvoice")

    this.router.navigateByUrl("/editSale/Invoice/" + (<OrderModel>this.sale).uuidInvoice + "/false")
  }

  cancellationReasonChanged() {
    let cancellationReason = CommonsService.getObjectByUuid(this.cancellationReasons, this.sale.uuidCancellationReason)
    this.enableButtonMakeOrder = !cancellationReason || !cancellationReason.name || cancellationReason.name == ""
  }

  async gotoCustomer() {
    if (!this.enableButtonCustomer) {
      CommonsService.showErrorMessage(this.translate.instant("GENERAL_NO_CUSTOMER_LINKED"))
      return;
    }

    if (this.sale.uuidCustomer && this.sale.uuidCustomer != "") {
      await this.saveSale()
      this.router.navigateByUrl("/editCustomer/" + this.sale.uuidCustomer)
    }
  }

  async clickedOnPosition(salePosition: any) {
    await this.saveSale()
    this.router.navigateByUrl("/editSalePosition/" + this.typeSale + "/" + salePosition.uuid + "/" + this.isSaleNew)
  }

  async fetchEditedData() {
    this.sale.date = CommonsService.parseDate($("#input-date").val())
    CommonsService.setPrices(this.sale, this.parametersStart.currency)

    var signatureImage: any = null;
    log("signaturePad is empty 2: " + this.signaturePad.isEmpty());
    let this_ = this
    if (!this.signaturePad.isEmpty()) {
      var signatureImage = this.signaturePad.toDataURL(); // save image as PNG;
      console.log("signature image ori: " + signatureImage);
      console.log("size image 1: " + signatureImage.length);

      // scale down from devices with high DPI
      let canvas: any = document.getElementById('canvas-signature-pad');
      var ratio = Math.max(window.devicePixelRatio || 1, 1);
      console.log("Window device pixel ratio: " + window.devicePixelRatio);

      // stay with existing canvas
      /*
      canvas.getContext("2d").scale(ratio, ratio);
      signatureImage = signaturePad.toDataURL();
      console.log("signature image scaled: " + signatureImage);
      console.log("size image scaled: " + signatureImage.length);
      */

      // use dummy canvas; does not work; image is not drawn on canvasTemp, only on second attempt
      var canvasTemp: any = document.createElement("canvas");
      canvasTemp.width = canvas.width / ratio;
      canvasTemp.height = canvas.height / ratio;
      var imageDummy = new Image();

      return new Promise((resolve, reject) => {
        imageDummy.onload = function () {
          //document.body.appendChild(imageDummy);
          //canvasTemp.getContext("2d").fillRect(canvasTemp.width - 1, 1, canvasTemp.width, canvasTemp.height);
          canvasTemp.getContext("2d").drawImage(imageDummy, 0, 0,
            canvasTemp.width,
            canvasTemp.height);
          //document.body.appendChild(canvasTemp);
          var signatureImage_ = canvasTemp.toDataURL("image/png");
          console.log("signature image resized: " + signatureImage_);
          this_.sale.signatureImage = signatureImage_;
          resolve(null)
        }
        //imageDummy.src = canvas.toDataURL("image/png");
        imageDummy.src = signatureImage;

        /*
        var canvasTemp = document.createElement("canvas");

        canvasTemp.width = initialization.signaturePadWidth;
        canvasTemp.height = initialization.signaturePadHeight;
        var imageDummy = new Image();
        imageDummy.src = signatureImage;
        // Scale and draw the source image to the canvasTemp
        canvasTemp.getContext("2d").drawImage(imageDummy, 0, 0,
                            initialization.signaturePadWidth,
                            initialization.signaturePadHeight);

        // Convert the canvas to a data URL in PNG format
        signatureImage = canvasTemp.toDataURL("image/png");
        */

        //console.log("signature image final: " + signatureImage);
        //console.log("size image 2: " + signatureImage.length);
      })
    } else {
      log("signaturePad is empty");
      this.sale.signatureImage = undefined;
      return Promise.resolve()
    }
  }

  async saveSale(doPurr: boolean = false) {
    CommonsService.startTime("fetchEditedData")
    await this.fetchEditedData()
    CommonsService.stopTime("fetchEditedData")
    CommonsService.startTime("saveSaleFinal")
    await this.saveSaleFinal(doPurr)
    CommonsService.stopTime("saveSaleFinal")
  }

  async saveSaleFinal(doPurr: boolean = false) {
    this.sale.recalc(this.parametersStart.currency);

    log("saveSaleFinal", this.sale);

    if (this.typeSale == "Offer")
      await this.repository.updateOffer(<OfferModel>this.saleBeforeEdit, <OfferModel>this.sale)
    else if (this.typeSale == "Order")
      await this.repository.updateOrder(<OrderModel>this.saleBeforeEdit, <OrderModel>this.sale)
    else if (this.typeSale == "Invoice")
      await this.repository.updateInvoice(<InvoiceModel>this.saleBeforeEdit, <InvoiceModel>this.sale)
    else
      throw new Error("Unknown sale type " + this.typeSale)

    this.setSaleToBeEdited(this.sale)
    if (doPurr)
      CommonsService.showSuccessMessage(this.translate.instant("SALE_EDIT_SAVED_SALE"))
    return Promise.resolve()
  }

  async changeDiscount() {
    this.discount = CommonsService.checkNumberString(this.discount)
    this.sale.discount = +this.discount.replace(',', '.') / 100.0;
    this.sale.recalc(this.parametersStart.currency)
  };

  private setSaleToBeEdited(sale: any) {
    this.sale = sale;
    this.parametersStart.sale = sale;

    log("the sale")
    log(sale)

    this.saleBeforeEdit = this.sale.clone(this.modelFactory)

    log("the sale before edit")
    log(this.saleBeforeEdit)

    this.discount = "" + sale.discount * 100.0; // convert into percent
    this.discount = "" + Math.round(+this.discount * 1000.0) / 1000.0;
  }

  clearSignaturePad() {
    this.signaturePad.clear()
  }

  movePositionUp(indexPosition: number) {
    this.sale.movePositionUp(indexPosition)
  }

  movePositionDown(indexPosition: number) {
    this.sale.movePositionDown(indexPosition)
  }

  // add new sales position -> select article
  addArticle() {
    $('#selectArticleModal').modal('show')
    this.viewArticlesSelect.resetSearch()
    this.viewArticlesSelect.setFocusToSearchField()
  }

  // add new sales position -> take selected article
  async selectedArticleModal(article: any) {
    $('#selectArticleModal').modal('hide')

    var newPosition: SalePositionModel = new SalePositionModel()
    if (this.typeSale == "Offer")
      newPosition = new OfferPositionModel()
    else if (this.typeSale == "Order")
      newPosition = new OrderPositionModel()
    else if (this.typeSale == "Invoice")
      newPosition = new InvoicePositionModel()
    else
      throw new Error("Unknown sale type " + this.typeSale)

    newPosition.uuid = UUID.UUID()
    newPosition.positionType = 1;
    newPosition.uuidParent = this.sale.uuid
    newPosition.uuidArticle = article.uuid;
    newPosition.name = article.name; // article.number + " - " + article.name;
    newPosition.amount = 1;
    newPosition.articleGroupId = article.articleGroupId;
    newPosition.priceUnitNetV = article.priceNetV;
    newPosition.uuidTaxRate = article.uuidTaxRate;
    newPosition.taxRate = <TaxRateModel | null>CommonsService.getObjectByUuid(this.taxRates, newPosition.uuidTaxRate)
    newPosition.description = article.description;
    newPosition.description2 = article.description2;
    newPosition.commissionLevel = article.commissionLevel;
    newPosition.costPriceUnitV = article.costPriceV;
    newPosition.costPriceTotalV = article.costPriceV;
    newPosition.purchasePriceUnitV = article.purchasePriceUnitV;
    newPosition.purchasePriceTotalV = article.purchasePriceUnitV;
    newPosition.priceUnitNetV = article.priceNetV;
    newPosition.priceUnitGrossV = article.priceGrossV;
    newPosition.sumArticleNetV = article.priceNetV; // _11
    newPosition.taxSumV = newPosition.priceUnitGrossV - newPosition.priceUnitNetV;
    // newPosition.taxSumV = article.taxFactor * article.priceNetV;
    newPosition.priceSumGrossV = newPosition.priceUnitGrossV;
    newPosition.priceSumNetV = article.priceNetV; // _17
    newPosition.discount = 0.0;
    newPosition.weightKgUnit = article.weightKgUnit;
    newPosition.weightKgTotal = article.weightKgUnit;
    newPosition.db1V = newPosition.priceSumNetV - newPosition.costPriceTotalV; // _29
    newPosition.db2V = newPosition.priceSumNetV - newPosition.purchasePriceTotalV; // _30
    newPosition.db2Percent = newPosition.db2V / newPosition.sumArticleNetV; // _73 = _30 / _11
    if (newPosition.sumArticleNetV == 0) {
      newPosition.db2Percent = 0;
    }

    CommonsService.setPrices(newPosition, this.parametersStart.currency)

    this.sale.addPosition(newPosition, this.parametersStart.currency);

    return this.saveSale(true)
  }

  // make order from offer
  isMakingOrder = false;
  async makeOrder() {
    if (!this.enableButtonMakeOrder) {
      CommonsService.showErrorMessage(this.translate.instant("SALE_EDIT_SALE_ALREADY_EXISTS"))
      return;
    }
    if (this.isMakingOrder) {
      return;
    }
    this.isMakingOrder = true;
    CommonsService.startTime("makeOrder")
    await CommonsService.showProcessingMessage("makeOrder")
    let uuidOrder = UUID.UUID();

    (<OfferModel>this.sale).uuidOrder = uuidOrder
    await this.fetchEditedData();

    let order: OrderModel = new OrderModel(this.sale)
    order.uuid = uuidOrder;
    order.number = "";
    order.date = new Date();
    order.uuidStateOrder = this.statesOrder[0].uuid
    order.uuidOffer = this.sale.uuid
    order.signatureImage = undefined;
    order.uuidCancellationReason = this.cancellationReasons[0].uuid

    let newPositions = <OrderPositionModel[]>this.modelFactory.newModels(OrderPositionModel, this.sale.positions)
    newPositions.forEach((position: any) => {
      position.uuid = UUID.UUID()
      position.uuidParent = order.uuid
    });
    order.setPositions(newPositions, this.parametersStart.currency)

    await this.repository.createOrder(order)
    // updates link to new sale
    await this.saveSaleFinal()

    await CommonsService.hideProcessingMessage("makeOrder")
    CommonsService.showSuccessMessage(this.translate.instant("SALE_EDIT_CREATED_SALE"))
    CommonsService.stopTime("makeOrder")

    this.isMakingOrder = false;
    this.router.navigateByUrl("/editSale/Order/" + order.uuid + "/true")
  }

  // make invoice from order
  isMakingInvoice = false;
  async makeInvoice() {
    if (!this.enableButtonMakeInvoice) {
      CommonsService.showErrorMessage(this.translate.instant("SALE_EDIT_SALE_ALREADY_EXISTS"))
      return;
    }
    if (this.isMakingInvoice) {
      return;
    }
    this.isMakingInvoice = true;
    CommonsService.startTime("makeInvoice")
    await CommonsService.showProcessingMessage("makeInvoice")
    let uuidInvoice = UUID.UUID();

    (<OrderModel>this.sale).uuidInvoice = uuidInvoice
    await this.fetchEditedData()

    let invoice: InvoiceModel = new InvoiceModel(this.sale)
    invoice.uuid = uuidInvoice;
    invoice.number = "";
    invoice.date = new Date();
    invoice.uuidOrder = this.sale.uuid
    invoice.signatureImage = undefined;
    invoice.uuidCancellationReason = this.cancellationReasons[0].uuid;

    let newPositions = <InvoicePositionModel[]>this.modelFactory.newModels(InvoicePositionModel, this.sale.positions)
    newPositions.forEach((position: any) => {
      position.uuid = UUID.UUID()
      position.uuidParent = invoice.uuid
    });
    invoice.setPositions(newPositions, this.parametersStart.currency)

    await this.repository.createInvoice(invoice)
    // updates link to new sale
    await this.saveSaleFinal()

    await CommonsService.hideProcessingMessage("makeInvoice")
    CommonsService.showSuccessMessage(this.translate.instant("SALE_EDIT_CREATED_SALE"))
    CommonsService.stopTime("makeInvoice")
    this.isMakingInvoice = false;
    this.router.navigateByUrl("/editSale/Invoice/" + invoice.uuid + "/true")
  }

  async printSale() {
    await this.saveSale()
    this.router.navigateByUrl("/printSale/" + this.typeSale + "/" + this.uuidSale)
  }

  async deleteSalePosition(position: any) {
    await this.saveSale()

    this.router.navigateByUrl("/deleteSalePosition/" + this.typeSale + "/" +
      position.uuid + "/" +
      this.isSaleNew)
  }
}

