import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from '../custom-date-parser-formatter'
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { AddCustomerContactComponent } from '../add-customer-contact/add-customer-contact.component'
import { RepositoryService } from '../repository.service';
import { CustomerContactModel } from '../models/customer-contact-model';
declare const log: any

@Component({
  selector: 'app-edit-customer-contact',
  templateUrl: '../add-customer-contact/add-customer-contact.component.html',
  styleUrls: ['./edit-customer-contact.component.css'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
  ]
})
export class EditCustomerContactComponent extends AddCustomerContactComponent implements OnInit {

  uuidCustomerContact = ""
  customerContactBeforeEdit: CustomerContactModel = new CustomerContactModel()

  constructor(
    location: Location,
    route: ActivatedRoute,
    router: Router,
    translate: TranslateService,
    repository: RepositoryService,
  ) {
    super(location, route, router, translate, repository)
  }

  override ngOnInit(): void {
    super.ngOnInit()
  }

  override async initSpecific() {
    this.translate.get("CONTACT_EDIT_TITLE").subscribe((translated) => this.textTitle = translated)

    const routeParams = this.route.snapshot.paramMap;
    this.uuidCustomerContact = String(routeParams.get('uuidCustomerContact'));

    this.customerContact = await this.repository.readCustomerContact(this.uuidCustomerContact)

    this.customerContactBeforeEdit = new CustomerContactModel(this.customerContact)

    await this.init()
  }

  override async saveSpecific() {
    await this.repository.updateCustomerContact(this.customerContactBeforeEdit, this.customerContact)

    this.location.back()
  }

}
