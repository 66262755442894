import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common'
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from '../custom-date-parser-formatter'
import { EditAppointmentComponent } from '../edit-appointment/edit-appointment.component';
import { UUID } from 'angular2-uuid';
import { RepositoryService } from '../repository.service';
import { CommonsService } from '../commons-service';
declare var $: any;
declare const log: any;


@Component({
  selector: 'app-add-appointment',
  templateUrl: '../edit-appointment/edit-appointment.component.html',
  styleUrls: ['./add-appointment.component.css'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
  ]
})
export class AddAppointmentComponent extends EditAppointmentComponent implements OnInit {

  uuidCustomer: string = ""

  constructor(
    location: Location,
    route: ActivatedRoute,
    router: Router,
    translate: TranslateService,
    repository: RepositoryService,) {
    super(location, route, router, translate, repository)
  }

  override ngOnInit(): void {
    super.ngOnInit()
  }

  override async initSpecific1() {
    this.translate.get("APPOINTMENT_ADD_TITLE").subscribe((translated) => this.textTitle = translated)

    const routeParams = this.route.snapshot.paramMap;
    this.uuidCustomer = String(routeParams.get('uuidCustomer'));

    this.appointment.begin = new Date()
    this.appointment.begin.setDate(this.appointment.begin.getDate() + 1);
    this.appointment.end = new Date()
    this.appointment.end.setDate(this.appointment.end.getDate() + 1);
    this.appointment.durationHours = 0
    this.appointment.location = ""
    this.appointment.remark = ""
    this.appointment.subject = ""
    this.appointment.uuid = UUID.UUID();
    this.appointment.uuidCustomer = this.uuidCustomer

    return this.initCommon()
  }

  override async initSpecific2() {
  }

  override async saveSpecific(navigateBack: boolean = true): Promise<boolean> {
    await this.repository.appointments.create(this.appointment)

    // if (navigateBack)
    //   this.location.back()
    CommonsService.showSuccessMessage(this.translate.instant("GENERAL_SAVED"))
    return Promise.resolve(true)
  }

}
