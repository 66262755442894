<div role="main">
  <div class="">
    <div class="page-title">
      <div class="title_left">
        <h3>{{ "SALEPOS_EDIT_TITLE" | translate }}</h3>
      </div>
    </div>
  </div>

  <div class="clearfix"></div>

  <div class="x_panel">
    <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
      <div class="form-horizontal form-label-left">
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-name"
          >
            {{ "SALEPOS_DELETE_NAME" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-name"
              type="text"
              ng-disabled="true"
              class="form-control"
              readonly
              [(ngModel)]="salePosition.name"
            />
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="textarea-description"
            >{{ "SALEPOS_DELETE_DESCRIPTION" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <textarea
              id="textarea-description"
              class="form-control"
              rows="4"
              id="subject"
              readonly
              [(ngModel)]="salePosition.description_plain"
            ></textarea>
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-amount"
          >
            {{ "SALEPOS_DELETE_AMOUNT" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-amount"
              type="text"
              class="form-control"
              readonly
              [(ngModel)]="salePosition.amount"
            />
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-discount"
          >
            {{ "SALEPOS_DELETE_DISCOUNT" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-discount"
              type="text"
              class="form-control"
              readonly
              [(ngModel)]="discount"
            />
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-net"
          >
            {{ "SALEPOS_DELETE_PRICE_NET" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-net"
              type="text"
              class="form-control"
              readonly
              [(ngModel)]="salePosition.priceUnitNet"
            />
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-gross"
          >
            {{ "SALEPOS_DELETE_PRICE_GROSS" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-gross"
              type="text"
              class="form-control"
              readonly
              [(ngModel)]="salePosition.priceUnitGross"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>

    <div class="">
      <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
        <div class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"></div>
        <div class="flex-nowrap">
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <button (click)="delete()" class="btn btn-danger button-row">
              <span>{{ "BUTTON_DELETE" | translate }} </span
              ><i class="mdi mdi-delete-outline color-bright"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
