import { AppointmentModel } from "./appointment-model";

export class AppointmentForViewModel extends AppointmentModel {

  textBegin: string = ""
  textEnd: string = ""
  nameCustomer: string = ""
  enableButtonCustomer: boolean = false

  constructor(params: any = null) {
    super(params)
    this.copyFromObject(params)
  }

  static override newModel(object: any = null) {
    return new AppointmentForViewModel(object)
  }
}
