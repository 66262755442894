import { DeviceModel } from "./device-model";

export class DeviceForViewModel extends DeviceModel {

  nameCustomer: string = ""
  enableButtonCustomer: boolean = false

  constructor(params: any = null) {
    super(params)
    this.copyFromObject(params)
  }

  static override newModel(object: any = null) {
    return new DeviceForViewModel(object)
  }
}
