<div role="main">
  <div class="">
    <div class="page-title">
      <div class="title_left">
        <h3>{{ "MAINTENANCE_DELETE_TITLE" | translate }}</h3>
      </div>
    </div>
  </div>

  <div class="clearfix"></div>

  <div class="x_panel">
    <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
      <div class="form-horizontal form-label-left">
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-number"
          >
            {{ "MAINTENANCE_EDIT_NUMBER_BEGIN" | translate }}
          </label>
          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
            <input
              id="input-number"
              class="form-control"
              type="text"
              [(ngModel)]="maintenance.number"
              readonly
            />
          </div>

          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-5">
            <input
              id="input-name"
              class="form-control"
              type="text"
              [(ngModel)]="maintenance.textBegin"
              readonly
            />
          </div>
        </div>

        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-ean"
          >
            {{ "MAINTENANCE_EDIT_CUSTOMER_FULL_NAME" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-ean"
              class="form-control"
              type="text"
              [(ngModel)]="maintenance.customerFullName"
              readonly
            />
          </div>
        </div>

        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-street"
          >
            {{ "MAINTENANCE_EDIT_CUSTOMER_STREET" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-street"
              class="form-control"
              type="text"
              [(ngModel)]="maintenance.customerStreet"
              readonly
            />
          </div>
        </div>

        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-zipCode"
          >
            {{ "MAINTENANCE_EDIT_CUSTOMER_ZIP_CODE_CITY" | translate }}
          </label>
          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
            <input
              id="input-zipCode"
              class="form-control"
              type="text"
              [(ngModel)]="maintenance.customerZipCode"
              readonly
            />
          </div>

          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-5">
            <input
              id="input-city"
              class="form-control"
              type="text"
              [(ngModel)]="maintenance.customerCity"
              readonly
            />
          </div>
        </div>

        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-city"
          >
            {{ "MAINTENANCE_EDIT_ARTICLE_NAME" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-city"
              class="form-control"
              type="text"
              [(ngModel)]="maintenance.articleName"
              readonly
            />
          </div>
        </div>

        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-serialnumber"
          >
            {{
              "MAINTENANCE_EDIT_SERIAL_NUMBER_DEVICE_NAME" | translate
            }}
          </label>
          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
            <input
              id="input-serialnumber"
              class="form-control"
              type="text"
              [(ngModel)]="maintenance.serialNumber"
              readonly
            />
          </div>

          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-5">
            <input
              id="input-device-name"
              class="form-control"
              type="text"
              [(ngModel)]="maintenance.deviceName"
              readonly
            />
          </div>
        </div>

        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-city"
          >
            {{ "MAINTENANCE_EDIT_CATEGORY" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-city"
              class="form-control"
              type="text"
              [(ngModel)]="maintenance.categoryName"
              readonly
            />
          </div>
        </div>

        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-status"
          >
            {{ "MAINTENANCE_EDIT_STATUS" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-status"
              class="form-control"
              type="text"
              [(ngModel)]="maintenance.statusName"
              readonly
            />
          </div>
        </div>

        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="checkbox-done"
          >
            {{ "MAINTENANCE_EDIT_DONE" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              style="top: 6px; position: relative"
              id="checkbox-done"
              class=""
              type="checkbox"
              [(ngModel)]="maintenance.done"
              readonly
              disabled
            />
          </div>
        </div>

        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="textarea-fault-description"
          >
            {{ "MAINTENANCE_EDIT_FAULT_DESCRIPTION" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <textarea
              class="form-control"
              rows="3"
              [(ngModel)]="maintenance.faultDescription"
              id="textarea-fault-description"
              readonly
            ></textarea>
          </div>
        </div>

        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="textarea-diagnosis"
          >
            {{ "MAINTENANCE_EDIT_DIAGNOSIS" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <textarea
              class="form-control"
              rows="3"
              [(ngModel)]="maintenance.diagnosis"
              id="textarea-diagnosis"
              readonly
            ></textarea>
          </div>
        </div>

        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="textarea-remarks"
          >
            {{ "MAINTENANCE_EDIT_REMARKS" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <textarea
              class="form-control"
              rows="3"
              [(ngModel)]="maintenance.remarks"
              id="textarea-remarks"
              readonly
            ></textarea>
          </div>
        </div>











      </div>
    </div>

    <div class="clearfix"></div>

    <div class="">
      <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
        <div class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"></div>
        <div class="flex-nowrap">
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <button (click)="delete()" class="btn btn-danger button-row">
              <span>{{ "BUTTON_DELETE" | translate }} </span
              ><i class="mdi mdi-delete-outline color-bright"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
