<div
  role="main"
  (keydown.arrowdown)="arrowDownEvent($event)"
  (keydown.arrowup)="arrowUpEvent($event)"
  (keydown.Enter)="enterEvent($event)"
  autocomplete="false"
>
  <input autocomplete="false" name="hidden" type="text" style="display: none" />
  <div class="">
    <div class="page-title">
      <div class="title_left">
        <h3>{{ "ARTICLES_TITLE" | translate }}</h3>
      </div>
    </div>
  </div>

  <div class="clearfix"></div>

  <div class="x_panel">
    <div class="x_title display-flex">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding: 0px">
        <div class="col-lg-6 col-md-6 col-sm-8 col-xs-12">
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              placeholder="{{ 'ARTICLES_TOOLTIP_SEARCH' | translate }}..."
              type="text"
              [(ngModel)]="textSearch"
              (keyup)="keyUpEvent($event)"
              id="id-input-text-search"
              #idInputTextSearch
            />
            <!-- (keyup.enter)="search()" -->
            <span class="input-group-btn">
              <button
                class="btn btn-default"
                type="button"
                (click)="search()"
                data-toggle="tooltip"
                data-placement="top"
              >
                {{ "ARTICLES_BUTTON_SEARCH" | translate }}
                <i class="mdi mdi-magnify"></i>
              </button>
            </span>
          </div>
        </div>

        <div
          class="visible-xs col-xs-12"
          style="margin-bottom: 12px"
          [hidden]="!showLinks"
        ></div>

        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-3" [hidden]="!showLinks">
          <button
            class="btn btn-default"
            type="button"
            (click)="gotoAddArticle()"
            data-toggle="tooltip"
            data-placement="top"
            title="{{ 'CUSTOMERS_TOOLTIP_ADD' | translate }}"
          >
            {{ "CUSTOMERS_BUTTON_NEW" | translate }}
            <i class="mdi mdi-plus-circle indicator-color-green"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="x_content">
      <table class="table table-striped">
        <thead>
          <tr>
            <th
              class="tableColWidthS col-fixed-1-o"
              (click)="changeSorting('number')"
            >
              <div class="table-header-col">
                {{ "ARTICLES_TABLE_NUMBER" | translate }}
                <span [hidden]="sort.column != 'number'">
                  <span [hidden]="!sort.ascending"> ^</span>
                  <span [hidden]="sort.ascending"> v</span></span
                >
              </div>
            </th>
            <th
              class="tableColWidthS col-fixed-1-o"
              (click)="changeSorting('ean')"
            >
              <div class="table-header-col">
                {{ "ARTICLES_TABLE_EAN" | translate }}
                <span [hidden]="sort.column != 'ean'">
                  <span [hidden]="!sort.ascending"> ^</span>
                  <span [hidden]="sort.ascending"> v</span></span
                >
              </div>
            </th>
            <th
              class="tableColWidthM col-flexible-o"
              (click)="changeSorting('name')"
            >
              <div class="table-header-col">
                {{ "ARTICLES_TABLE_NAME" | translate
                }}<span [hidden]="sort.column != 'name'">
                  <span [hidden]="!sort.ascending"> ^</span>
                  <span [hidden]="sort.ascending"> v</span></span
                >
              </div>
            </th>
            <th
              class="tableColWidthS col-fixed-1-o"
              (click)="changeSorting('priceNetV')"
            >
              <div class="table-header-col">
                {{ "ARTICLES_TABLE_PRICE_NET" | translate
                }}<span [hidden]="sort.column != 'priceNetV'">
                  <span [hidden]="!sort.ascending"> ^</span>
                  <span [hidden]="sort.ascending"> v</span>
                </span>
              </div>
            </th>
            <th
              class="tableColWidthS col-fixed-1-o"
              (click)="changeSorting('priceGrossV')"
            >
              <div class="table-header-col">
                {{ "ARTICLES_TABLE_PRICE_GROSS" | translate
                }}<span [hidden]="sort.column != 'priceGrossV'">
                  <span [hidden]="!sort.ascending"> ^</span>
                  <span [hidden]="sort.ascending"> v</span>
                </span>
              </div>
            </th>
            <th
              class="tableColWidthS col-fixed-1-o"
              (click)="changeSorting('articleGroupName')"
            >
              <div class="table-header-col">
                {{ "ARTICLES_TABLE_GROUP" | translate
                }}<span [hidden]="sort.column != 'articleGroupName'">
                  <span [hidden]="!sort.ascending"> ^</span>
                  <span [hidden]="sort.ascending"> v</span>
                </span>
              </div>
            </th>
            <th class="tableColWidthM" [hidden]="!showLinks">
              <div
                class="table-header-col"
                data-toggle="tooltip"
                data-placement="top"
                title="{{ 'CUSTOMERS_TABLE_TOOLTIP_ADD' | translate }}"
                (click)="gotoAddArticle()"
              >
                <i class="mdi mdi-plus-circle indicator-color-green"></i>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let article of articles
                | limitFromTo
                  : currentPage * pageSize
                  : (currentPage + 1) * pageSize;
              let i = index
            "
            [ngClass]="iRowHighlighted == i ? 'table-row-highlight' : ''"
            id="{{ 'id-table-articles-row-' + i }}"
          >
            <td (click)="clickedOnArticle(article)">{{ article.number }}</td>
            <td (click)="clickedOnArticle(article)">{{ article.ean }}</td>
            <td (click)="clickedOnArticle(article)">{{ article.name }}</td>
            <td (click)="clickedOnArticle(article)" class="noWrap">
              {{ article.priceNet }}
            </td>
            <td (click)="clickedOnArticle(article)" class="noWrap">
              {{ article.priceGross }}
            </td>
            <td (click)="clickedOnArticle(article)">
              {{ article.articleGroupName }}
            </td>

            <td [hidden]="!showLinks">
              <div class="dropdown">
                <button
                  class="btn btn-sm btn-default dropdown-toggle btn-action"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {{
                    "GENERAL_TABLE_DROPDOWN_ACTIONS" | translate | capitalize
                  }}
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/viewArticle/{{ article.uuid }}"
                      ng-click="$event.stopPropagation();"
                      data-toggle="tooltip"
                      data-placement="top"
                    >
                      <i class="mdi mdi-pencil"></i>&nbsp;{{
                        "GENERAL_TABLE_DROPDOWN_ACTION_EDIT" | translate | capitalize
                      }}</a
                    >
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/deleteArticle/{{ article.uuid }}"
                      ng-click="$event.stopPropagation();"
                      data-toggle="tooltip"
                      data-placement="top"
                    >
                      <i class="mdi mdi-delete-outline"></i>&nbsp;{{
                        "GENERAL_TABLE_DROPDOWN_ACTION_DELETE"
                          | translate
                          | capitalize
                      }}</a
                    >
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <button
          class="btn btn-sm btn-default btn-navigation"
          [disabled]="currentPage == 0 || numberOfPages() == 0"
          (click)="currentPage = 0; storeCurrentPage()"
        >
          {{ "NAVIGATION_PAGE_FIRST" | translate }}
        </button>
        <button
          class="btn btn-sm btn-default btn-navigation"
          [disabled]="currentPage == 0 || numberOfPages() == 0"
          (click)="currentPage = currentPage - 1; storeCurrentPage()"
        >
          {{ "NAVIGATION_PAGE_PREVIOUS" | translate }}
        </button>
        <span class="text-navigation">
          {{ currentPage + 1 }}/{{ numberOfPages() }}
        </span>
        <button
          class="btn btn-sm btn-default btn-navigation"
          [disabled]="currentPage >= numberOfPages() - 1"
          (click)="currentPage = currentPage + 1; storeCurrentPage()"
        >
          {{ "NAVIGATION_PAGE_NEXT" | translate }}
        </button>
        <button
          class="btn btn-sm btn-default btn-navigation"
          [disabled]="currentPage >= numberOfPages() - 1"
          (click)="currentPage = numberOfPages() - 1; storeCurrentPage()"
        >
          {{ "NAVIGATION_PAGE_LAST" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
