<div role="main">
  <div class="">
    <div class="page-title">
      <div class="title_left">
        <h3>{{ textTitle }}</h3>
      </div>
    </div>
  </div>

  <div class="clearfix"></div>

  <div class="x_panel">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="" role="tabpanel" data-example-id="togglable-tabs">
        <ul id="myTab1" class="nav nav-tabs bar_tabs left" role="tablist">
          <li role="presentation" class="active">
            <a
              href="#tab_content11"
              id="home-tabb"
              role="tab"
              data-toggle="tab"
              aria-controls="home"
              aria-expanded="true"
              >{{ "MAINTENANCE_TAB_GENERAL" | translate }}</a
            >
          </li>
          <li role="presentation" class="">
            <a
              href="#tab_content22"
              role="tab"
              id="profile-tabb"
              data-toggle="tab"
              aria-controls="profile"
              aria-expanded="false"
              >{{ "MAINTENANCE_TAB_WAREHOUSE" | translate }}</a
            >
          </li>
          <li role="presentation" class="">
            <a
              href="#tab_content33"
              role="tab"
              id="profile-tabb3"
              data-toggle="tab"
              aria-controls="profile"
              aria-expanded="false"
              >{{ "MAINTENANCE_TAB_TIME_TRACKING" | translate }}</a
            >
          </li>
          <li role="presentation" class="">
            <a
              href="#tab_content44"
              role="tab"
              id="profile-tabb4"
              data-toggle="tab"
              aria-controls="profile"
              aria-expanded="false"
              >{{ "MAINTENANCE_TAB_IMAGES" | translate }}</a
            >
          </li>
          <li role="presentation" class="">
            <a
              href="#tab_content55"
              role="tab"
              id="profile-tabb5"
              data-toggle="tab"
              aria-controls="profile"
              aria-expanded="false"
              class="active"
              >{{ "MAINTENANCE_TAB_SIGNATURE" | translate }}</a
            >
          </li>
        </ul>
        <div id="myTabContent2" class="tab-content">
          <div
            role="tabpanel"
            class="tab-pane fade active in"
            id="tab_content11"
            aria-labelledby="home-tab"
          >
            <div class="visible-xs col-xs-12" style="margin-bottom: 40px"></div>

            <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
              <div class="form-horizontal form-label-left">
                <div class="form-group">
                  <label
                    class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
                    for="input-number"
                  >
                    {{ "MAINTENANCE_EDIT_NUMBER_BEGIN" | translate }}
                  </label>
                  <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                    <input
                      id="input-number"
                      class="form-control"
                      type="text"
                      [(ngModel)]="maintenance.number"
                      readonly
                    />
                  </div>

                  <div class="col-lg-4 col-md-4 col-sm-4 col-xs-5">
                    <input
                      id="input-name"
                      class="form-control"
                      type="text"
                      [(ngModel)]="maintenance.textBegin"
                      readonly
                    />
                  </div>
                </div>

                <!-- div class="form-group">
                  <label
                    class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
                    for="input-serialnumber"
                  >
                    {{ "MAINTENANCE_EDIT_NUMBER" | translate }}
                  </label>
                  <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
                    <input
                      id="input-serialnumber"
                      class="form-control"
                      type="text"
                      [(ngModel)]="maintenance.number"
                      readonly
                    />
                  </div>
                </!-->

                <!-- div class="form-group">
                  <label
                    class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
                    for="input-name"
                  >
                    {{ "MAINTENANCE_EDIT_BEGIN" | translate }}
                  </label>
                  <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
                    <input
                      id="input-name"
                      class="form-control"
                      type="text"
                      [(ngModel)]="maintenance.textBegin"
                      readonly
                    />
                  </div>
                </!-->

                <div class="form-group">
                  <label
                    class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
                    for="input-ean"
                  >
                    {{ "MAINTENANCE_EDIT_CUSTOMER_FULL_NAME" | translate }}
                  </label>
                  <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
                    <input
                      id="input-ean"
                      class="form-control"
                      type="text"
                      [(ngModel)]="maintenance.customerFullName"
                      readonly
                    />
                  </div>
                </div>

                <div class="form-group">
                  <label
                    class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
                    for="input-street"
                  >
                    {{ "MAINTENANCE_EDIT_CUSTOMER_STREET" | translate }}
                  </label>
                  <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
                    <input
                      id="input-street"
                      class="form-control"
                      type="text"
                      [(ngModel)]="maintenance.customerStreet"
                      readonly
                    />
                  </div>
                </div>

                <div class="form-group">
                  <label
                    class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
                    for="input-zipCode"
                  >
                    {{ "MAINTENANCE_EDIT_CUSTOMER_ZIP_CODE_CITY" | translate }}
                  </label>
                  <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                    <input
                      id="input-zipCode"
                      class="form-control"
                      type="text"
                      [(ngModel)]="maintenance.customerZipCode"
                      readonly
                    />
                  </div>

                  <div class="col-lg-4 col-md-4 col-sm-4 col-xs-5">
                    <input
                      id="input-city"
                      class="form-control"
                      type="text"
                      [(ngModel)]="maintenance.customerCity"
                      readonly
                    />
                  </div>
                </div>

                <div class="form-group">
                  <label
                    class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
                    for="input-city"
                  >
                    {{ "MAINTENANCE_EDIT_CUSTOMER_TELEPHONE" | translate }}
                  </label>
                  <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
                    <input
                      id="input-city"
                      class="form-control"
                      type="text"
                      [(ngModel)]="maintenance.customerTelephone"
                      readonly
                    />
                  </div>
                </div>

                <div class="form-group">
                  <label
                    class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
                    for="input-city"
                  >
                    {{ "MAINTENANCE_EDIT_ARTICLE_NAME" | translate }}
                  </label>
                  <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
                    <input
                      id="input-city"
                      class="form-control"
                      type="text"
                      [(ngModel)]="maintenance.articleName"
                      readonly
                    />
                  </div>
                </div>

                <div class="form-group">
                  <label
                    class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
                    for="input-serialnumber"
                  >
                    {{
                      "MAINTENANCE_EDIT_SERIAL_NUMBER_DEVICE_NAME" | translate
                    }}
                  </label>
                  <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                    <input
                      id="input-serialnumber"
                      class="form-control"
                      type="text"
                      [(ngModel)]="maintenance.serialNumber"
                      readonly
                    />
                  </div>

                  <div class="col-lg-4 col-md-4 col-sm-4 col-xs-5">
                    <input
                      id="input-device-name"
                      class="form-control"
                      type="text"
                      [(ngModel)]="maintenance.deviceName"
                      readonly
                    />
                  </div>
                </div>

                <!-- div class="form-group">
                  <label
                    class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
                    for="input-serialnumber"
                  >
                    {{ "MAINTENANCE_EDIT_SERIAL_NUMBER" | translate }}
                  </label>
                  <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
                    <input
                      id="input-serialnumber"
                      class="form-control"
                      type="text"
                      [(ngModel)]="maintenance.serialNumber"
                      readonly
                    />
                  </div>
                </!-->

                <!-- div class="form-group">
                  <label
                    class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
                    for="input-device-name"
                  >
                    {{ "MAINTENANCE_EDIT_DEVICE_NAME" | translate }}
                  </label>
                  <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
                    <input
                      id="input-device-name"
                      class="form-control"
                      type="text"
                      [(ngModel)]="maintenance.deviceName"
                      readonly
                    />
                  </div>
                </!-->

                <div class="form-group">
                  <label
                    class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
                    for="input-city"
                  >
                    {{ "MAINTENANCE_EDIT_CATEGORY" | translate }}
                  </label>
                  <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
                    <input
                      id="input-city"
                      class="form-control"
                      type="text"
                      [(ngModel)]="maintenance.categoryName"
                      readonly
                    />
                  </div>
                </div>

                <div class="form-group">
                  <label
                    class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
                    for="input-status"
                  >
                    {{ "MAINTENANCE_EDIT_STATUS" | translate }}
                  </label>
                  <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
                    <input
                      id="input-status"
                      class="form-control"
                      type="text"
                      [(ngModel)]="maintenance.statusName"
                      readonly
                    />
                  </div>
                </div>

                <div class="form-group">
                  <label
                    class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
                    for="checkbox-done"
                  >
                    {{ "MAINTENANCE_EDIT_DONE" | translate }}
                  </label>
                  <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
                    <input
                      style="top: 6px; position: relative"
                      id="checkbox-done"
                      class=""
                      type="checkbox"
                      [(ngModel)]="maintenance.done"
                      readonly
                      disabled
                    />
                  </div>
                </div>

                <div class="form-group">
                  <label
                    class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
                    for="textarea-fault-description"
                  >
                    {{ "MAINTENANCE_EDIT_FAULT_DESCRIPTION" | translate }}
                  </label>
                  <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
                    <textarea
                      class="form-control"
                      rows="3"
                      [(ngModel)]="maintenance.faultDescription"
                      id="textarea-fault-description"
                    ></textarea>
                  </div>
                </div>

                <div class="form-group">
                  <label
                    class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
                    for="textarea-diagnosis"
                  >
                    {{ "MAINTENANCE_EDIT_DIAGNOSIS" | translate }}
                  </label>
                  <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
                    <textarea
                      class="form-control"
                      rows="3"
                      [(ngModel)]="maintenance.diagnosis"
                      id="textarea-diagnosis"
                    ></textarea>
                  </div>
                </div>

                <div class="form-group">
                  <label
                    class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
                    for="textarea-remarks"
                  >
                    {{ "MAINTENANCE_EDIT_REMARKS" | translate }}
                  </label>
                  <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
                    <textarea
                      class="form-control"
                      rows="3"
                      [(ngModel)]="maintenance.remarks"
                      id="textarea-remarks"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12"></div>

            <div class="clearfix"></div>

            <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
              <div
                class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
              ></div>
              <div class="flex-nowrap">
                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
                  <button
                    [hidden]="maintenance.done"
                    (click)="finalizeStart()"
                    class="btn btn-primary button-row"
                  >
                    <span>{{ "BUTTON_FINALIZE" | translate }} </span
                    ><i class="mdi mdi-check color-bright"></i>
                  </button>

                  <button (click)="save()" class="btn btn-primary button-row">
                    <span>{{ "BUTTON_SAVE" | translate }} </span
                    ><i class="mdi mdi-content-save color-bright"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            role="tabpanel"
            class="tab-pane fade"
            id="tab_content22"
            aria-labelledby="profile-tab"
          >
            <div class="visible-xs col-xs-12" style="margin-bottom: 40px"></div>

            <div>
              <div class="col-lg-6 col-md-8 col-sm-12 col-xs-12">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th class="table-header-col-no-sort">
                        {{ "MATERIALS_TABLE_NAME_ARTICLE" | translate }}
                      </th>
                      <th class="table-header-col-no-sort">
                        {{ "MATERIALS_TABLE_AMOUNT" | translate }}
                      </th>
                      <th class="col-fixed-2 tableColWidthIcon">
                        <div
                          class="table-header-col"
                          (click)="addMaterialStart()"
                          title="{{
                            'MATERIALS_TABLE_TOOLTIP_ADD' | translate
                          }}"
                        >
                          <i
                            class="mdi mdi-plus-circle indicator-color-green"
                          ></i>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let material of materials; let i = index">
                      <td>{{ material.namePart }}</td>
                      <td>{{ material.amount }}</td>
                      <td>
                        <div class="dropdown">
                          <button
                            class="btn btn-sm btn-default dropdown-toggle btn-action"
                            type="button"
                            id="dropdownMenuButton"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            {{
                              "GENERAL_TABLE_DROPDOWN_ACTIONS"
                                | translate
                                | capitalize
                            }}
                          </button>
                          <ul
                            class="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <li (click)="addMaterialStart(material)">
                              <a
                                class="dropdown-item"
                                data-toggle="tooltip"
                                data-placement="top"
                              >
                                <i
                                  class="mdi mdi-plus-circle indicator-color-green"
                                ></i
                                >&nbsp;{{
                                  "MATERIALS_TABLE_DROPDOWN_ADD"
                                    | translate
                                    | capitalize
                                }}</a
                              >
                            </li>

                            <li>
                              <a
                                class="dropdown-item"
                                (click)="removeMaterialStart(material)"
                                data-toggle="tooltip"
                                data-placement="top"
                              >
                                <i class="mdi mdi-minus-circle-outline"></i
                                >&nbsp;{{
                                  "MATERIALS_TABLE_DROPDOWN_REMOVE"
                                    | translate
                                    | capitalize
                                }}</a
                              >
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div
            role="tabpanel"
            class="tab-pane fade"
            id="tab_content33"
            aria-labelledby="profile-tab"
          >
            <div class="visible-xs col-xs-12" style="margin-bottom: 40px"></div>

            <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
              <div class="form-horizontal form-label-left">
                <div class="form-group">
                  <label
                    class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
                    for="input-time-total"
                  >
                    {{ "MAINTENANCE_EDIT_TIME_RECORDINGS_TOTAL" | translate }}
                  </label>
                  <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
                    <input
                      id="input-serialnumber"
                      class="form-control"
                      type="text"
                      [(ngModel)]="timeRecordingsDurationTotalText"
                      readonly
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="clearfix"></div>

            <div>
              <div class="col-lg-6 col-md-8 col-sm-12 col-xs-12">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th class="table-header-col-no-sort">
                        {{ "TIMERECORDING_TABLE_BEGIN" | translate }}
                      </th>
                      <th class="table-header-col-no-sort">
                        {{ "TIMERECORDING_TABLE_END" | translate }}
                      </th>
                      <th class="table-header-col-no-sort">
                        {{ "TIMERECORDING_TABLE_DURATION" | translate }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="
                        let timeRecording of timeRecordings;
                        let i = index
                      "
                    >
                      <td>
                        {{ timeRecording.textBegin }}
                      </td>
                      <td>
                        {{ timeRecording.textEnd }}
                      </td>
                      <td>
                        {{ timeRecording.textDuration }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div
            role="tabpanel"
            class="tab-pane fade"
            id="tab_content44"
            aria-labelledby="profile-tab"
          >
            <div class="visible-xs col-xs-12" style="margin-bottom: 40px"></div>

            <webcam
              [height]="500"
              [width]="500"
              [trigger]="triggerObservable"
              (imageCapture)="handleImage($event)"
              *ngIf="showWebcam"
              [allowCameraSwitch]="allowCameraSwitch"
              [switchCamera]="nextWebcamObservable"
              [videoOptions]="videoOptions"
              [imageQuality]="1"
              (cameraSwitched)="cameraWasSwitched($event)"
              (initError)="handleInitError($event)"
            ></webcam>

            <div [hidden]="!showWebcam" class="flex-nowrap">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <button
                  (click)="triggerSnapshot()"
                  class="btn btn-primary button-row"
                >
                  <span>{{ "BUTTON_SNAPSHOT" | translate }} </span
                  ><i class="mdi mdi-circle-slice-8 color-bright"></i>
                </button>

                <button
                  [hidden]="!multipleWebcamsAvailable"
                  (click)="showNextWebcam(true)"
                  class="btn btn-primary button-row"
                >
                  <span>{{ "BUTTON_CAMERA_FLIP" | translate }} </span
                  ><i class="mdi mdi-camera-flip color-bright"></i>
                </button>
              </div>
            </div>

            <div class="flex-nowrap">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <button
                  (click)="toggleWebcam()"
                  class="btn btn-primary button-row"
                >
                  <span>{{ "BUTTON_CAMERA" | translate }} </span
                  ><i
                    class="mdi {{
                      showWebcam ? 'mdi-camera-off' : 'mdi-camera'
                    }} color-bright"
                  ></i>
                </button>

                <input
                  id="input-select-image-file-"
                  type="file"
                  hidden
                  [(ngModel)]="pathImageFile"
                  (ngModelChange)="selectedImageFile()"
                  accept=".png,.jpg,.jpeg"
                />

                <input
                  id="input-select-image-file"
                  accept=".png,.jpg,.jpeg"
                  hidden
                  type="file"
                  (change)="selectedImageListener($event)"
                />

                <button
                  (click)="selectImageFile()"
                  class="btn btn-primary button-row"
                  hidde
                >
                  <span>{{ "BUTTON_SELECT_FILE" | translate }} </span
                  ><i class="mdi mdi-file-image color-bright"></i>
                </button>
              </div>

              <div
                *ngFor="let image of maintenance.getImagesWithoutSignature()"
                class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
              >
                <hr class="image-separator" />
                {{ image.name }}
                <img width="100%" src="{{ image.data }}" />

                <button
                  [hidden]="image.isLink"
                  (click)="removeImageStart(image.uuid)"
                  class="btn btn-primary button-row"
                >
                  <span>{{ "BUTTON_REMOVE" | translate }} </span
                  ><i class="mdi mdi-delete-outline color-bright"></i>
                </button>
              </div>
            </div>
          </div>

          <div
            role="tabpanel"
            class="tab-pane fade"
            id="tab_content55"
            aria-labelledby="profile-tab"
          >
            <div class="visible-xs col-xs-12" style="margin-bottom: 40px"></div>

            <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
              <div class="form-horizontal form-label-left">
                <div class="form-group">
                  <!-- <label
                    class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-0"
                    for=""
                  >
                  </label> -->
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div id="signatureWrapper" class="signatureWrapper">
                      <!-- canvas id="signatureShadowCanvas" ng-disabled="true" class="shadowCanvas"></canvas -->
                      <!-- div id="signaturePadWrapper" class="wrapperSignaturePad" -->
                      <!-- img class="img2" src="http://www.licensing.biz/cimages/c0f1a3a97bc0de97271f9813f7715bb3.png" width=400 height=200 / -->
                      <img
                        class="img-signature-pad"
                        id="img-signature-pad"
                        src="../../assets/Images/backgroundSignaturePad.png"
                        width="400"
                        height="200"
                      />
                      <canvas id="canvas-signature-pad" class="signature-pad"></canvas>
                      <!-- /div -->
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label
                    class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-0"
                    for=""
                  >
                  </label>
                  <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                    <button
                      (click)="clearSignaturePad()"
                      class="btn btn-primary button-row"
                    >
                      <span>{{ "BUTTON_CLEAR" | translate }} </span
                      ><i class="mdi mdi-cancel color-bright"></i>
                    </button>

                    <button (click)="save()" class="btn btn-primary button-row">
                      <span>{{ "BUTTON_SAVE" | translate }} </span
                      ><i class="mdi mdi-content-save color-bright"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal select article dialog -->
<div
  class="modal fade"
  id="selectArticleModal"
  tabindex="-1"
  aria-labelledby="conrimModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header display-flex">
        <h5 class="modal-title" id="confirmModalLabel">
          {{ "MAINTENANCE_EDIT_TITLE_ADD_MATERIAL" | translate }}
        </h5>
        <button
          type="button"
          class="btn-close-modal"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i class="mdi mdi-close color-main"></i>
        </button>
      </div>
      <div class="modal-body modal-body-select">
        <app-view-articles-select (selectedArticleModal)="selectedArticleModal($event)">
        </app-view-articles-select>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary button-row"
          data-dismiss="modal"
        >
          {{ "BUTTON_CANCEL" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Modal input amount add material dialog -->
<div
  class="modal fade"
  id="inputAddAmountModal"
  tabindex="-1"
  aria-labelledby=""
  aria-hidden="true"
>
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header display-flex">
        <h5 class="modal-title">
          {{ "MAINTENANCE_EDIT_TITLE_ADD_MATERIAL" | translate }}
        </h5>
        <button
          type="button"
          class="btn-close-modal"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i class="mdi mdi-close color-main"></i>
        </button>
      </div>
      <div class="modal-body modal-body-selec">
        <div>
          <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
            <div class="form-horizontal form-label-left">
              <div class="form-group">
                <label
                  class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
                  for="input-article-amount"
                >
                  {{ "NEW_MATERIAL_ARTICLE" | translate }}
                </label>
                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
                  <input
                    id="input-article-amount"
                    class="form-control"
                    type="text"
                    value="{{ materialArticle ? materialArticle.name : '' }}"
                    readonly
                  />
                </div>
              </div>
              <div class="form-group">
                <label
                  class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
                  for="input-article-amount"
                >
                  {{ "NEW_MATERIAL_AMOUNT" | translate }}
                </label>
                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
                  <input
                    id="input-article-amount"
                    class="form-control"
                    type="number"
                    min="0"
                    [(ngModel)]="materialAmount"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary button-row"
          (click)="addMaterialInputDone()"
        >
          {{ "BUTTON_SAVE" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-primary button-row"
          data-dismiss="modal"
        >
          {{ "BUTTON_CANCEL" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Modal input amount remove material dialog -->
<div
  class="modal fade"
  id="inputRemoveAmountModal"
  tabindex="-1"
  aria-labelledby=""
  aria-hidden="true"
>
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header display-flex">
        <h5 class="modal-title">
          {{ "MAINTENANCE_EDIT_TITLE_REMOVE_MATERIAL" | translate }}
        </h5>
        <button
          type="button"
          class="btn-close-modal"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i class="mdi mdi-close color-main"></i>
        </button>
      </div>
      <div class="modal-body modal-body-selec">
        <div>
          <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
            <div class="form-horizontal form-label-left">
              <div class="form-group">
                <label
                  class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
                  for="input-article-amount"
                >
                  {{ "NEW_MATERIAL_ARTICLE" | translate }}
                </label>
                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
                  <input
                    id="input-article-amount"
                    class="form-control"
                    type="text"
                    value="{{ materialArticle ? materialArticle.name : '' }}"
                    readonly
                  />
                </div>
              </div>
              <div class="form-group">
                <label
                  class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
                  for="input-article-amount"
                >
                  {{ "NEW_MATERIAL_AMOUNT" | translate }}
                </label>
                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
                  <input
                    id="input-article-amount"
                    class="form-control"
                    type="number"
                    min="0"
                    max="{{ materialMaxAmount }}"
                    [(ngModel)]="materialAmount"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary button-row"
          (click)="removeMaterialInputDone()"
        >
          {{ "BUTTON_SAVE" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-primary button-row"
          data-dismiss="modal"
        >
          {{ "BUTTON_CANCEL" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Modal confirm remove image dialog -->
<div
  class="modal fade"
  id="confirmRemoveImage"
  tabindex="-1"
  aria-labelledby=""
  aria-hidden="true"
>
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header display-flex">
        <h5 class="modal-title">
          {{ "MAINTENANCE_EDIT_TITLE_REMOVE_IMAGE" | translate }}
        </h5>
        <button
          type="button"
          class="btn-close-modal"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i class="mdi mdi-close color-main"></i>
        </button>
      </div>
      <div class="modal-body modal-body-selec">
        <div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="form-horizontal form-label-left">
              {{ imageToRemove.name }}
              <img width="100%" src="{{ imageToRemove.data }}" />
            </div>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary button-row"
          (click)="removeImage()"
        >
          <span>{{ "BUTTON_REMOVE" | translate }} </span
          ><i class="mdi mdi-delete-outline color-bright"></i>
        </button>
        <button
          type="button"
          class="btn btn-primary button-row"
          data-dismiss="modal"
        >
          {{ "BUTTON_CANCEL" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Modal confirm finalize dialog -->
<div
  class="modal fade"
  id="modalConfirmFinalize"
  tabindex="-1"
  aria-labelledby=""
  aria-hidden="true"
>
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header display-flex">
        <h5 class="modal-title">
          {{ "MAINTENANCE_EDIT_TITLE_CONFIRM_MARK_DONE" | translate }}
        </h5>
        <button
          type="button"
          class="btn-close-modal"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i class="mdi mdi-close color-main"></i>
        </button>
      </div>
      <div class="modal-body modal-body-selec">
        <div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            {{ "MAINTENANCE_EDIT_TEXT_CONFIRM_MARK_DONE" | translate }}
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary button-row"
          (click)="finalize()"
        >
          <span>{{ "BUTTON_FINALIZE" | translate }} </span
          ><i class="mdi mdi-check color-bright"></i>
        </button>
        <button
          type="button"
          class="btn btn-primary button-row"
          data-dismiss="modal"
        >
          {{ "BUTTON_CANCEL" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
