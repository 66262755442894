import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ViewCustomersComponent } from '../view-customers/view-customers.component';
import { RepositoryService } from '../repository.service';
declare const log: any;

// Used vor modal selection of a customer; see demo in start.component.html <app-view-customers-select
@Component({
  selector: 'app-view-customers-select',
  templateUrl: '../view-customers/view-customers.component.html',
  styleUrls: ['./view-customers-select.component.css']
})
export class ViewCustomersSelectComponent extends ViewCustomersComponent implements OnInit {

  override showLinks = false;
  override showButtonAdd = false;
  //override pageSize = 10;
  @Output() selectedCustomerModal = new EventEmitter<string>();

  constructor(
    router: Router,
    repository: RepositoryService) {
    super(router, repository)
  }

  override ngOnInit(): void {
    super.ngOnInit()
  }

  override selectedCustomer(customer: any) {
    this.selectedCustomerModal.emit(customer)
  }
}
