<div role="main">
  <div class="">
    <div class="page-title">
      <div class="title_left">
        <h3>{{ textHeaderPage }}</h3>
      </div>
    </div>
  </div>

  <div class="clearfix"></div>

  <div class="x_panel">
    <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
      <div class="form-group">
        <label class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3">
        </label>
        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
          <button (click)="save()" class="btn btn-primary button-row">
            <span>{{ "BUTTON_SAVE" | translate }} </span
            ><i class="mdi mdi-content-save color-bright"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="clearfix"></div>

    <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
      <div class="form-horizontal form-label-left">
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="select-sex"
            >{{ "CUSTOMER_EDIT_SEX" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <select
              id="select-sex"
              class="form-control"
              [(ngModel)]="customer.uuidSex"
              name="customer.uuidSex"
            >
              <option *ngFor="let sex of sexes" [ngValue]="sex.uuid">
                {{ sex.formOfAddress }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-academic-title"
          >
            {{ "CUSTOMER_EDIT_ACADEMIC_TITLE" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-academic-title"
              class="form-control"
              type="text"
              [(ngModel)]="customer.academicTitle"
              name="customer.academicTitle"
            />
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-first-name"
          >
            {{ "CUSTOMER_EDIT_FIRST_NAME" | translate }}
          </label>
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7">
            <input
              id="input-first-name"
              class="form-control"
              type="text"
              [(ngModel)]="customer.firstName"
              name="customer.firstName"
            />
          </div>
          <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 move-down">
            <input
              type="checkbox"
              id="ceckbox-familiar"
              name="customer.familiar"
              [checked]="customer.familiar"
              (change)="customer.familiar = !customer.familiar"
            />
            {{ "CUSTOMER_EDIT_FAMILIAR" | translate }}
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-last-name"
          >
            {{ "CUSTOMER_EDIT_LAST_NAME" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-last-name"
              class="form-control"
              type="text"
              [(ngModel)]="customer.lastName"
              name="customer.lastName"
            />
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-street"
          >
            {{ "CUSTOMER_EDIT_STREET" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-street"
              class="form-control"
              type="text"
              [(ngModel)]="customer.street"
              name="customer.street"
            />
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-zip-code"
          >
            {{ "CUSTOMER_EDIT_ZIP_CODE" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-zip-code"
              class="form-control"
              type="text"
              [(ngModel)]="customer.zipCode"
              name="customer.zipCode"
            />
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-city"
          >
            {{ "CUSTOMER_EDIT_CITY" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-city"
              class="form-control"
              type="text"
              [(ngModel)]="customer.city"
              name="customer.city"
            />
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3 firstColumn"
            for="select-country"
          >
            {{ "CUSTOMER_EDIT_COUNTRY" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <select
              id="select-country"
              class="form-control"
              [(ngModel)]="customer.uuidCountry"
              name="customer.uuidCountry"
            >
              <option
                *ngFor="let country of countries"
                [ngValue]="country.uuid"
              >
                {{ country.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-telephone-1"
          >
            {{ "CUSTOMER_EDIT_TELEPHONE_1" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-telephone-1"
              class="form-control"
              type="text"
              [(ngModel)]="customer.telephone1"
              name="customer.telephone1"
            />
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-telephone-2"
          >
            {{ "CUSTOMER_EDIT_TELEPHONE_2" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-telephone-2"
              class="form-control"
              type="text"
              [(ngModel)]="customer.telephone2"
              name="customer.telephone2"
            />
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-fax"
          >
            {{ "CUSTOMER_EDIT_FAX" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-fax"
              class="form-control"
              type="text"
              [(ngModel)]="customer.fax"
              name="customer.fax"
            />
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-email"
          >
            {{ "CUSTOMER_EDIT_EMAIL" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-email"
              class="form-control"
              type="text"
              [(ngModel)]="customer.email"
              name="customer.email"
            />
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-homepage"
          >
            {{ "CUSTOMER_EDIT_HOMEPAGE" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-homepage"
              class="form-control"
              type="text"
              [(ngModel)]="customer.homepage"
              name="customer.homepage"
            />
          </div>
        </div>

        <div class="form-group">
          <label class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3">
            {{ "CUSTOMER_EDIT_CUSTOMER_NUMBER_UID" | translate }}:
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              class="form-control"
              type="text"
              [(ngModel)]="customer.numberUID"
              name="customer.numberUID"
            />
          </div>
        </div>

        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="checkbox-done"
          >
            {{ "CUSTOMER_EDIT_TAX_EXEMPT" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              style="top: 6px; position: relative"
              id="checkbox-done"
              class=""
              type="checkbox"
              [(ngModel)]="customer.taxExempt"
            />
          </div>
        </div>

        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="select-category"
          >
            {{ "CUSTOMER_EDIT_CATEGORY" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <select
              id="select-category"
              multiple="multiple"
              size="3"
              class="input-block-level multiple-select form-control"
              [(ngModel)]="uuidsCustomerCategoriesArr"
              name="uuidsCustomerCategoriesArr"
            >
              <option
                *ngFor="let customerCategory of customerCategories"
                [ngValue]="customerCategory.uuid"
              >
                {{ customerCategory.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
      <div class="form-horizontal form-label-left">
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="textarea-remarks"
          >
            {{ "CUSTOMER_EDIT_REMARKS" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <textarea
              class="form-control"
              rows="3"
              id="textarea-remarks"
              [(ngModel)]="customer.remarks"
              name="customer.remarks"
            ></textarea>
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="textarea-keywords"
          >
            {{ "CUSTOMER_EDIT_KEYWORDS" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <textarea
              id="textarea-keywords"
              class="form-control"
              rows="3"
              id="subject"
              [(ngModel)]="customer.keywords"
              name="customer.keywords"
            ></textarea>
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="select-payment-type"
          >
            {{ "CUSTOMER_EDIT_PAYMENT_TYPE" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <select
              id="select-payment-type"
              class="input-block-level form-control"
              [(ngModel)]="customer.uuidPaymentType"
              name="customer.uuidPaymentType"
            >
              <option
                *ngFor="let paymentType of paymentTypes"
                [ngValue]="paymentType.uuid"
              >
                {{ paymentType.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="select-payment-target"
          >
            {{ "CUSTOMER_EDIT_PAYMENT_TARGET" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <select
              id="select-payment-target"
              class="input-block-level form-control"
              [(ngModel)]="customer.uuidPaymentTarget"
              name="customer.uuidPaymentTarget"
            >
              <option
                *ngFor="let paymentTarget of paymentTargets"
                [ngValue]="paymentTarget.uuid"
              >
                {{ paymentTarget.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-discount"
          >
            {{ "CUSTOMER_EDIT_DISCOUNT" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-discount"
              class="form-control"
              type="number"
              min="0.0"
              max="100.0"
              step="0.01"
              [(ngModel)]="discountPercent"
              name="discountPercent"
            />
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="select-pricelist"
          >
            {{ "CUSTOMER_EDIT_PRICELIST" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <select
              id="select-pricelist"
              class="form-control"
              [(ngModel)]="customer.uuidPricelist"
              name="customer.uuidPricelist"
            >
              <option
                *ngFor="let pricelist of pricelists"
                [ngValue]="pricelist.uuid"
              >
                {{ pricelist.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="clearfix"></div>

    <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
      <div class="form-horizontal form-label-left">
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-last-contact"
          >
            {{ "CUSTOMER_EDIT_LAST_CONTACT" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <div class="input-group date" id="div-last-contact">
              <input
                id="input-last-contact"
                placeholder="dd.mm.yyyy"
                type="text"
                class="form-control"
                value="{{ textDateLastContact }}"
              />
              <!-- onkeydown="return false" -->
              <span class="input-group-addon">
                <span class="glyphicon glyphicon-calendar"></span>
                <!--i class="mdi mdi-calendar icon-datepicker"></i-->
              </span>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-next-contact"
          >
            {{ "CUSTOMER_EDIT_NEXT_CONTACT" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <div class="input-group date" id="div-next-contact">
              <input
                id="input-next-contact"
                type="text"
                class="form-control"
                placeholder="dd.mm.yyyy"
                value="{{ textDateNextContact }}"
              />
              <!-- onkeydown="return false" -->
              <span class="input-group-addon">
                <span class="glyphicon glyphicon-calendar"></span>
                <!--i class="mdi mdi-calendar icon-datepicker"></i-->
                <!--button
                  type="button"
                  class="btn btn-default btn-datepicker"
                >
                  <i class="mdi mdi-calendar icon-datepicker"></i>
                </button-->
              </span>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="textarea-next-contact-topic"
          >
            {{ "CUSTOMER_EDIT_NEXT_CONTACT_TOPIC" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <textarea
              id="textarea-next-contact-topic"
              class="form-control"
              rows="2"
              [(ngModel)]="customer.nextContactTopic"
              name="customer.nextContactTopic"
            ></textarea>
          </div>
          <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12"></div>
        </div>

        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-contact-frequency-days"
          >
            {{ "CUSTOMER_EDIT_CONTACT_FREQUENCY" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              class="form-control"
              id="input-contact-frequency-days"
              type="number"
              [(ngModel)]="customer.contactFrequencyDays"
              name="customer.contactFrequencyDays"
            />
          </div>
        </div>

        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-birthday"
          >
            {{ "CUSTOMER_EDIT_BIRTHDAY" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <div class="input-group date" id="div-birthday">
              <input
                id="input-birthday"
                type="text"
                class="form-control"
                placeholder="dd.mm.yyyy"
                value="{{ textDateBirthday }}"
              />
              <!-- onkeydown="return false" -->
              <span class="input-group-addon">
                <span class="glyphicon glyphicon-calendar"></span>
                <!--i class="mdi mdi-calendar icon-datepicker"></i-->
              </span>
            </div>
          </div>
        </div>

        <div class="form-group" [hidden]="!showLinks">
          <label class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3">
            {{ "CUSTOMER_EDIT_CUSTOMER_NUMBER" | translate }}:
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              class="form-control"
              type="text"
              [(ngModel)]="customer.number"
              name="customer.nummber"
              readonly
            />
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12"></div>

    <div class="clearfix"></div>

    <div class="">
      <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
        <div class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"></div>
        <div class="flex-nowrap">
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <button (click)="save()" class="btn btn-primary button-row">
              <span>{{ "BUTTON_SAVE" | translate }} </span
              ><i class="mdi mdi-content-save color-bright"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="clearfix margin-small"></div>

    <div class="">
      <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
        <div class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"></div>
        <div class="flex-nowrap">
          <div
            class="col-lg-8 col-md-8 col-sm-8 col-xs-9"
            style="margin-bottom: 10px"
          >
            <span
              [hidden]="!showLinks"
              class="button-row"
              (click)="gotoAppointments()"
              data-toggle="tooltip"
              data-placement="top"
              title="{{ 'CUSTOMER_EDIT_TOOLTIP_APPOINTMENTS' | translate }}"
            >
              <i class="mdi mdi-calendar color-main"></i>
            </span>
            <span
              [hidden]="!showLinks"
              class="button-row"
              (click)="gotoPartners()"
              data-toggle="tooltip"
              data-placement="top"
              title="{{ 'CUSTOMER_EDIT_TOOLTIP_PARTNER' | translate }}"
            >
              <i class="mdi mdi-account-box-outline color-main"></i>
            </span>
            <span
              [hidden]="!showLinks"
              class="button-row"
              (click)="gotoCustomerContacts()"
              data-toggle="tooltip"
              data-placement="top"
              title="{{ 'CUSTOMER_EDIT_TOOLTIP_CONTACTS' | translate }}"
            >
              <i class="mdi mdi-forum-outline color-main"></i
            ></span>
            <span
              [hidden]="!showLinks"
              class="button-row"
              (click)="gotoOffers()"
              data-toggle="tooltip"
              data-placement="top"
              title="{{ 'CUSTOMER_EDIT_TOOLTIP_OFFERS' | translate }}"
            >
              <i class="mdi mdi-clipboard-text-outline color-main"></i
            ></span>
            <span
              [hidden]="!showLinks"
              class="button-row"
              (click)="gotoOrders()"
              data-toggle="tooltip"
              data-placement="top"
              title="{{ 'CUSTOMER_EDIT_TOOLTIP_ORDERS' | translate }}"
            >
              <i class="mdi mdi-clipboard-text-play-outline color-main"></i
            ></span>
            <span
              [hidden]="!showLinks"
              class="button-row"
              (click)="gotoSalesHistory()"
              data-toggle="tooltip"
              data-placement="top"
              title="{{ 'CUSTOMER_EDIT_TOOLTIP_SALES_HISTORY' | translate }}"
            >
              <i class="mdi mdi-script-text-outline color-main"></i
            ></span>
          </div>
        </div>
      </div>
    </div>

    <!--div class="clearfix margin-small"></div>

    <div class="">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <button (click)="save()" class="btn btn-default button-row">
          <span>{{ "BUTTON_SAVE" | translate }}</span
          ><i class="mdi mdi-content-save color-main"></i>
        </button>
        <span
          [hidden]="!showLinks"
          class="button-row"
          (click)="gotoAppointments()"
          data-toggle="tooltip"
          data-placement="top"
          title="{{ 'CUSTOMER_EDIT_TOOLTIP_APPOINTMENTS' | translate }}"
        >
          <i class="mdi mdi-calendar color-main"></i>
        </span>
        <span
          [hidden]="!showLinks"
          class="button-row"
          (click)="gotoPartners()"
          data-toggle="tooltip"
          data-placement="top"
          title="{{ 'CUSTOMER_EDIT_TOOLTIP_PARTNER' | translate }}"
        >
          <i class="mdi mdi-account-box-outline color-main"></i>
        </span>
        <span
          [hidden]="!showLinks"
          class="button-row"
          (click)="gotoCustomerContacts()"
          data-toggle="tooltip"
          data-placement="top"
          title="{{ 'CUSTOMER_EDIT_TOOLTIP_CONTACTS' | translate }}"
        >
          <i class="mdi mdi-forum-outline color-main"></i
        ></span>
        <span
          [hidden]="!showLinks"
          class="button-row"
          (click)="gotoOffers()"
          data-toggle="tooltip"
          data-placement="top"
          title="{{ 'CUSTOMER_EDIT_TOOLTIP_OFFERS' | translate }}"
        >
          <i class="mdi mdi-clipboard-text-outline color-main"></i
        ></span>
        <span
          [hidden]="!showLinks"
          class="button-row"
          (click)="gotoOrders()"
          data-toggle="tooltip"
          data-placement="top"
          title="{{ 'CUSTOMER_EDIT_TOOLTIP_ORDERS' | translate }}"
        >
          <i class="mdi mdi-clipboard-text-play-outline color-main"></i
        ></span>
        <span
          [hidden]="!showLinks"
          class="button-row"
          (click)="gotoSalesHistory()"
          data-toggle="tooltip"
          data-placement="top"
          title="{{ 'CUSTOMER_EDIT_TOOLTIP_SALES_HISTORY' | translate }}"
        >
          <i class="mdi mdi-script-text-outline color-main"></i
        ></span>
      </div>
    </div-->
  </div>

  <div class="x_panel" [hidden]="!showHistory">
    <div class="tab">
      <button
        [ngClass]="isSetActiveHistoryTab(1) ? 'background-active' : ''"
        [hidden]="!showHistoryTab[1]"
        class="tablinks"
        (click)="setActiveHistoryTab(1)"
      >
        {{ "CUSTOMER_EDIT_HISTORY_PARTNERS" | translate }}
      </button>
      <button
        [ngClass]="isSetActiveHistoryTab(2) ? 'background-active' : ''"
        [hidden]="!showHistoryTab[2]"
        class="tablinks"
        (click)="setActiveHistoryTab(2)"
      >
        {{ "CUSTOMER_EDIT_HISTORY_CONTACTS" | translate }}
      </button>
      <button
        [ngClass]="isSetActiveHistoryTab(3) ? 'background-active' : ''"
        [hidden]="!showHistoryTab[3]"
        class="tablinks"
        (click)="setActiveHistoryTab(3)"
      >
        {{ "CUSTOMER_EDIT_HISTORY_APPOINTMENTS" | translate }}
      </button>
      <button
        [ngClass]="isSetActiveHistoryTab(4) ? 'background-active' : ''"
        [hidden]="!showHistoryTab[4]"
        class="tablinks"
        (click)="setActiveHistoryTab(4)"
      >
        {{ "CUSTOMER_EDIT_HISTORY_OFFERS" | translate }}
      </button>
      <button
        [ngClass]="isSetActiveHistoryTab(5) ? 'background-active' : ''"
        [hidden]="!showHistoryTab[5]"
        class="tablinks"
        (click)="setActiveHistoryTab(5)"
      >
        {{ "CUSTOMER_EDIT_HISTORY_ORDERS" | translate }}
      </button>
      <button
        [ngClass]="isSetActiveHistoryTab(6) ? 'background-active' : ''"
        [hidden]="!showHistoryTab[6]"
        class="tablinks"
        (click)="setActiveHistoryTab(6)"
      >
        {{ "CUSTOMER_EDIT_HISTORY_DELIVERIES" | translate }}
      </button>
      <button
        [ngClass]="isSetActiveHistoryTab(7) ? 'background-active' : ''"
        [hidden]="!showHistoryTab[7]"
        class="tablinks"
        (click)="setActiveHistoryTab(7)"
      >
        {{ "CUSTOMER_EDIT_HISTORY_INVOICES" | translate }}
      </button>
    </div>
    <div [hidden]="!isSetActiveHistoryTab(1)">
      <div class="post-content" [innerHTML]="htmlHistoryTab[1]"></div>
    </div>
    <div [hidden]="!isSetActiveHistoryTab(2)">
      <div class="post-content" [innerHTML]="htmlHistoryTab[2]"></div>
    </div>
    <div [hidden]="!isSetActiveHistoryTab(3)">
      <div class="post-content" [innerHTML]="htmlHistoryTab[3]"></div>
    </div>
    <div [hidden]="!isSetActiveHistoryTab(4)">
      <div class="post-content" [innerHTML]="htmlHistoryTab[4]"></div>
    </div>
    <div [hidden]="!isSetActiveHistoryTab(5)">
      <div class="post-content" [innerHTML]="htmlHistoryTab[5]"></div>
    </div>
    <div [hidden]="!isSetActiveHistoryTab(6)">
      <div class="post-content" [innerHTML]="htmlHistoryTab[6]"></div>
    </div>
    <div [hidden]="!isSetActiveHistoryTab(7)">
      <div class="post-content" [innerHTML]="htmlHistoryTab[7]"></div>
    </div>
  </div>
</div>
