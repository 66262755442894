import { BaseModel } from "./base-model"
import { TaxRateModel } from "./tax-rate-model"

export class SalePositionModel extends BaseModel {

  uuidParent: string = ""
  positionType: number = 0
  uuidArticle: string = ""
  name: string = ""
  number: string = ""
  amount: number = 1
  priceUnitNet: string = ""
  priceUnitNetV: number = 0
  sumArticleNet: string = ""
  sumArticleNetV: number = 0
  uuidTaxRate: string = ""
  taxRate: TaxRateModel | null = null
  taxUnitV: number = 0
  taxSumV: number = 0
  priceSumGross: string = ""
  priceSumGrossV: number = 0
  priceSumNet: string = ""
  priceSumNetV: number = 0
  description: string = ""
  description_plain: string = "" // automatic created plain text from rtf - see BaseModel
  description2: string = ""
  description2Plain: string = ""
  weightKgTotal: number = 0
  costPriceTotal: string = ""
  costPriceTotalV: number = 0
  purchasePriceTotal: string = ""
  purchasePriceTotalV: number = 0
  db1V: number = 0
  db2V: number = 0
  purchasePriceUnit: string = ""
  purchasePriceUnitV: number = 0
  commissionLevel: number = 0
  articleGroupId: number = 0
  discount: number = 0
  db2Percent: number = 0
  priceUnitGross: string = ""
  priceUnitGrossV: number = 0
  weightKgUnit: number = 0
  costPriceUnit: string = ""
  costPriceUnitV: number = 0

  // 0 to 1
  getTaxFactor() {
    return this.taxRate ? this.taxRate.percentTotal : 0.0
  }

  constructor(params: any = null) {
    super(params)
    this.copyFromObject(params)
  }

  static override newModel(params: any = null): SalePositionModel {
    return new SalePositionModel(params)
  }
}
