import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UUID } from 'angular2-uuid';
import { CustomerModel } from 'src/app/models/customer-model';
import { DeviceModel } from 'src/app/models/device-model';
import { MaintenanceForViewModel } from 'src/app/models/maintenance-for-view-model';
import { MaintenanceModel } from 'src/app/models/maintenance-model';
import { RepositoryService } from 'src/app/repository.service';
import { CommonsService } from '../commons-service';
import { ArticleModel } from '../models/article-model';
import { MaintenanceCategoryModel } from '../models/maintenance-category-model';
import { MaintenanceStateModel } from '../models/maintenance-state-model';
import { EditHavingImagesComponent } from '../edit-having-images/edit-having-images.component';
import { ViewArticlesSelectComponent } from '../view-articles-select/view-articles-select.component';
import { ViewCustomersSelectComponent } from '../view-customers-select/view-customers-select.component';
import { OrderModel } from '../models/order-model';
declare var $: any;
declare const log: any;
declare const moment: any;

@Component({
  selector: 'app-edit-device',
  templateUrl: './edit-device.component.html',
  styleUrls: ['./edit-device.component.css']
})
export class EditDeviceComponent extends EditHavingImagesComponent implements OnInit {

  uuidDevice = ""
  device: DeviceModel = new DeviceModel()
  customer: CustomerModel = new CustomerModel()
  article: ArticleModel = new ArticleModel()
  deviceBeforeEdit: DeviceModel = new DeviceModel()
  maintenancesStates: MaintenanceStateModel[] = []
  maintenancesCategories: MaintenanceCategoryModel[] = []
  maintenances: MaintenanceForViewModel[] = []
  nameCustomer = ""
  nameArticle = ""
  showLinkCustomer = false
  showLinkArticle = false
  disableForeignMaintenances = false
  devices: DeviceModel[] = []
  parts: DeviceModel[] = []

  @ViewChild(ViewArticlesSelectComponent) viewArticlesSelect!: ViewArticlesSelectComponent;
  @ViewChild(ViewCustomersSelectComponent) viewCustomersSelect!: ViewCustomersSelectComponent;

  constructor(
    protected location: Location,
    protected route: ActivatedRoute,
    protected router: Router,
    protected translate: TranslateService,
    protected repository: RepositoryService,
  ) {
    super()
    super.setIdTable(CommonsService.ID_TABLE_DEVICES)
  }

  override ngOnInit(): void {
    this.init()
  }

  public override showErrorMessage(key: string): void {
    CommonsService.showErrorMessage(this.translate.instant(key))
  }

  async init() {
    const routeParams = this.route.snapshot.paramMap;
    this.uuidDevice = String(routeParams.get('uuidDevice'));

    let deviceTemp = await this.repository.devices.read(this.uuidDevice, false)
    this.device = <DeviceModel>deviceTemp
    this.deviceBeforeEdit = new DeviceModel(this.device)

    await this.loadMaintenances()

    this.maintenancesStates = await this.repository.maintenanceStates.readAll()
    this.maintenancesCategories = await this.repository.maintenanceCategories.readAll()

    let custTemp = <CustomerModel>await this.repository.customers.read(this.device.uuidCustomer, true)
    if (custTemp && this.device.uuidCustomer != "") {
      this.customer = custTemp
      this.nameCustomer = this.customer.firstName + " " + this.customer.lastName;
      this.showLinkCustomer = true
    } else {
      this.device.uuidCustomer = ""
      this.nameCustomer = "";
      this.showLinkCustomer = false;
    }

    let articleTemp = <ArticleModel>await this.repository.articles.read(this.device.uuidArticle, true)
    if (articleTemp && this.device.uuidArticle != "") {
      this.article = articleTemp
      this.nameArticle = this.article.name
      this.showLinkArticle = true
    } else {
      this.device.uuidArticle = ""
      this.nameArticle = ""
      this.showLinkArticle = false
    }

    let orders: OrderModel[] = await this.repository.readOrders("", "", true)
    let order: OrderModel = CommonsService.getObjectByUuid(orders, this.device.uuidOrder)
    if (order != null) {
      this.device.orderNumber = order.number;
    }

    this.devices = await this.repository.devices.readAll()
    var dummyDevice = new DeviceModel()
    dummyDevice.uuid = ""
    dummyDevice.serialNumber = ""
    dummyDevice.name = ""
    this.devices.unshift(dummyDevice)

    for (let i = 0; i < this.devices.length; i++) {
      const device = this.devices[i];
      if (device.uuidMainDevice == this.device.uuid) {
        this.parts.push(device)
      }
    }

    log("the device")
    log(this.device)
    super.setEntity(this.device)
  }

  public override async saveAfterImageAdded(): Promise<void> {
    this.repository.devices.update(this.deviceBeforeEdit, this.device)
    this.deviceBeforeEdit = new DeviceModel(this.device)

    CommonsService.showSuccessMessage(this.translate.instant("ADDED_IMAGE"))

    return Promise.resolve()
  }

  public override async saveAfterImageRemoved(): Promise<void> {
    this.repository.devices.update(this.deviceBeforeEdit, this.device)
    this.deviceBeforeEdit = new DeviceModel(this.device)

    CommonsService.showSuccessMessage(this.translate.instant("REMOVED_IMAGE"))

    return Promise.resolve()
  }

  async loadMaintenances() {
    let maintenancesR: MaintenanceModel[] = await this.repository.maintenances.readAll("begin", true)
    let maintenancesT: MaintenanceForViewModel[] = []
    for (let maintenance of maintenancesR) {
      if (maintenance.uuidDevice == this.uuidDevice) {
        maintenancesT.push(new MaintenanceForViewModel(maintenance))
      }
    }

    let customers = await this.repository.customers.readAll()

    for (let maintenance of maintenancesT) {
      let customer = CommonsService.getObjectByUuid(customers, maintenance.uuidCustomer)
      maintenance.setCustomer(customer)
      maintenance.setDevice(this.device)
      maintenance.textBegin = CommonsService.formatDate0(maintenance.begin)
    }

    this.maintenances = maintenancesT
  }

  addingMaintenance = false

  async addMaintenance() {
    if (this.addingMaintenance)
      return;
    this.addingMaintenance = true

    let newMaint = new MaintenanceModel()
    newMaint.begin = new Date();
    newMaint.begin.setHours(0);
    newMaint.begin.setMinutes(0);
    newMaint.begin = moment(newMaint.begin).utc(true).toDate();

    newMaint.uuidCustomer = this.device.uuidCustomer
    newMaint.serialNumber = this.device.serialNumber
    newMaint.uuidArticle = this.device.uuidArticle
    newMaint.uuid = UUID.UUID()

    if (this.maintenancesStates.length > 1) {
      newMaint.uuidMaintenanceStatus = this.maintenancesStates[1].uuid
      newMaint.statusName = this.maintenancesStates[1].name
    }

    if (this.maintenancesCategories.length > 1) {
      newMaint.uuidMaintenanceCategory = this.maintenancesCategories[1].uuid
      newMaint.categoryName = this.maintenancesCategories[1].name
    }

    newMaint.uuidDevice = this.device.uuid

    await this.repository.maintenances.create(newMaint)

    await this.loadMaintenances()

    await this.gotoMaintenance(newMaint.uuid)

    this.addingMaintenance = false
  }

  async gotoCustomer() {
    if (!await this.save(false))
      return
    this.router.navigateByUrl("/editCustomer/" + this.customer.uuid)
  }

  async gotoMaintenance(uuidMaintenance: string) {
    if (!await this.save(false))
      return
    this.router.navigateByUrl("/editMaintenance/" + uuidMaintenance)
  }

  async save(navigateBack: boolean = true): Promise<boolean> {
    this.repository.devices.update(this.deviceBeforeEdit, this.device)

    // if (navigateBack)
    //   this.location.back()
    CommonsService.showSuccessMessage(this.translate.instant("GENERAL_SAVED"))
    return Promise.resolve(true)
  }

  selectCustomer() {
    $('#selectCustomerModal').modal('show')
    this.viewCustomersSelect.resetSearch()
    this.viewCustomersSelect.setFocusToSearchField()
  }

  selectedCustomerModal(customer: any) {
    $('#selectCustomerModal').modal('hide')
    this.customer = customer
    this.device.uuidCustomer = customer.uuid
    this.nameCustomer = this.customer.firstName + " " + this.customer.lastName;
    this.device.street = this.customer.street
    this.device.zipCode = this.customer.zipCode
    this.device.city = this.customer.city
    this.showLinkCustomer = true
  }

  clearCustomer() {
    this.customer = new CustomerModel()
    this.device.uuidCustomer = ""
    this.nameCustomer = ""
    this.showLinkCustomer = false
  }

  selectArticle() {
    $('#selectArticleModal').modal('show')
    this.viewArticlesSelect.resetSearch()
    this.viewArticlesSelect.setFocusToSearchField()
  }

  selectedArticleModal(article: any) {
    $('#selectArticleModal').modal('hide')
    this.article = article
    this.device.uuidArticle = article.uuid
    this.nameArticle = this.article.name
    this.showLinkArticle = true
  }

  async gotoArticle() {
    if (!await this.save(false))
      return
    this.router.navigateByUrl("/viewArticle/" + this.article.uuid)
  }

  async gotoOrder() {
    if (this.device.uuidOrder == "") {
      return;
    }

    this.router.navigateByUrl("/editSale/Order/" + this.device.uuidOrder + "/false")
  }

  async gotoMainDevice() {
    if (this.device.uuidMainDevice == "") {
      return;
    }

    this.router.navigateByUrl("/editDevice/" + this.device.uuidMainDevice)
  }

  async gotoPart(part: DeviceModel) {
    this.router.navigateByUrl("/editDevice/" + part.uuid)
  }

  clearArticle() {
    this.article = new ArticleModel()
    this.device.uuidArticle = ""
    this.nameArticle = ""
    this.showLinkArticle = false
  }

}
