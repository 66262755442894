import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ParametersStartService {

  public nameUser: string = "";
  public currency: string = "?";

  public showNavbar = true;

  public buttonStartText: string = "Start"
  public buttonStartColor: string = "indicator-green"
  public buttonSyncText: string = "Sync";
  public buttonSyncEnabled: boolean = true;
  public buttonLoginLogoutEnabled: boolean = true;
  public buttonLoginLogoutVisible: boolean = true;
  public buttonLoginLogoutText: string = ""; // "Log"
  public buttonStartStopProjectTimeEnabled: boolean = true;
  public isSyncRunning: boolean = false;
  public sale: any = null

  constructor() { }

  public buttonSyncReset() {
    this.buttonSyncText = "Sync";
    this.buttonSyncEnabled = true;
    this.buttonLoginLogoutEnabled = true;
    this.buttonLoginLogoutVisible = true;
    this.buttonStartStopProjectTimeEnabled = true;
    this.isSyncRunning = false;
  }
}
