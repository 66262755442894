import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonsService } from '../commons-service';
import { Subject, Observable } from 'rxjs';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { MaintenanceForViewModel } from '../models/maintenance-for-view-model';
import { MaintenanceModel } from '../models/maintenance-model';
import { RepositoryService } from '../repository.service';
import { ImageModel } from '../models/image-model';
import { UUID } from 'angular2-uuid';
import { TimeRecordingModel } from '../models/time-recording-model';
import { ArticleModel } from '../models/article-model';
import { StockMovementModel } from '../models/stock-movement-model';
import { MaintenanceMaterialModel } from '../models/maintenance-material-model';
import { ParametersStartService } from '../parameters-start.service';
import { ViewArticlesSelectComponent } from '../view-articles-select/view-articles-select.component';
import { MaintenanceStateModel } from '../models/maintenance-state-model';
declare var $: any;
declare const SignaturePad: any;
declare const log: any;

@Component({
  selector: 'app-edit-maintenance',
  templateUrl: './edit-maintenance.component.html',
  styleUrls: ['./edit-maintenance.component.css']
})
export class EditMaintenanceComponent implements OnInit {

  uuidMaintenance = ""
  maintenanceLoaded = false
  maintenance: MaintenanceForViewModel = new MaintenanceForViewModel()
  materials: MaintenanceMaterialModel[] = []
  imagesToShow: ImageModel[] = [];

  maintenanceBeforeEdit: MaintenanceModel = new MaintenanceModel()
  timeRecordings: TimeRecordingModel[] = []
  timeRecordingsDurationTotalText = ""

  maintenancesStates: MaintenanceStateModel[] = []

  textTitle = ""
  textTimeRecording = ""

  pathImageFile = ""

  public showWebcam = false;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string = "";
  public videoOptions: MediaTrackConstraints = {
    // width: {ideal: 1024},
    // height: {ideal: 576}
  };
  signaturePad: any = null
  public errors: WebcamInitError[] = [];

  // latest snapshot
  public webcamImage: WebcamImage | null = null;

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();

  materialEdit: MaintenanceMaterialModel | null = null
  materialArticle: ArticleModel | null = null
  materialAmount = 1
  materialMaxAmount = 1

  imageToRemove: ImageModel = new ImageModel()

  @ViewChild(ViewArticlesSelectComponent) viewArticlesSelect!: ViewArticlesSelectComponent;

  constructor(
    protected location: Location,
    protected route: ActivatedRoute,
    protected router: Router,
    protected translate: TranslateService,
    protected repository: RepositoryService,
    private parametersStart: ParametersStartService
  ) {
  }

  ngOnInit(): void {
    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      });

    this.init()
  }


  async init() {
    let this_ = this
    $("#profile-tabb5").click(function (event: Event) {
      setTimeout(() => {
        this_.waitForSignature()
      }, 300)
    });

    const routeParams = this.route.snapshot.paramMap;
    this.uuidMaintenance = String(routeParams.get('uuidMaintenance'));

    this.maintenancesStates = await this.repository.maintenanceStates.readAll()

    let maintenanceTemp = await this.repository.maintenances.read(this.uuidMaintenance, false)

    this.maintenance = new MaintenanceForViewModel(maintenanceTemp)
    this.maintenanceLoaded = true
    this.maintenanceBeforeEdit = new MaintenanceModel(maintenanceTemp)

    this.maintenance.setCustomer(await this.repository.customers.read(this.maintenance.uuidCustomer, true))
    this.maintenance.setDevice(await this.repository.devices.read(this.maintenance.uuidDevice, true))

    this.maintenance.textBegin = CommonsService.formatDateTime0(this.maintenance.begin)

    let article = await this.repository.articles.read(this.maintenance.uuidArticle, true)
    if (article != null) {
      this.maintenance.article = article
      this.maintenance.articleName = article.number + " - " + article.name
    }

    await this.readTimeRecordings()

    await this.readMaterials()

    log("the maintenance")
    log(this.maintenance)

    // get state of time recording
    let uuidRecord = await this.repository.readUtilityData("timeRecordingUuidRecord")
    let recordType = await this.repository.readUtilityData("timeRecordingType")
    if (recordType == "Maintenance" && uuidRecord != "" && uuidRecord == this.maintenance.uuid) {
      this.setRunningTimeRecording(true)
    } else {
      this.setRunningTimeRecording(false)
    }

  }

  async readTimeRecordings() {
    this.timeRecordings = await this.repository.timeRecordings.readAll("begin", false)
    this.timeRecordings = this.timeRecordings.filter((tr) => tr.uuidMaintenance == this.uuidMaintenance)

    this.timeRecordingsDurationTotalText
    let durationTotal = 0
    for (let tr of this.timeRecordings)
      durationTotal += tr.duration
    this.timeRecordingsDurationTotalText = CommonsService.formatHoursMinutes0(durationTotal)
  }

  async setRunningTimeRecording(isRunning: boolean) {
    let textNumberName = this.maintenance.number
    if (this.maintenance.customerFullName != "")
      textNumberName += " - " + this.maintenance.customerFullName

    if (isRunning) {
      // time recording is running for the sale currently displayed
      this.translate.get("SALE_TIME_RECORDING_RUNNING")
        .subscribe((translated: string) => {
          this.textTimeRecording = translated
          this.translate.get("MAINTENANCE_EDIT_TITLE")
            .subscribe((translated: string) => { this.textTitle = translated + " " + textNumberName + " " + this.textTimeRecording }
            )
        }
        )
    } else {
      this.translate.get("MAINTENANCE_EDIT_TITLE")
        .subscribe((translated: string) => { this.textTitle = translated + " " + textNumberName }
        )
      await this.readTimeRecordings()
    }
  }

  waitForSignature() {
    if (!this.maintenanceLoaded)
      setTimeout(() => {
        this.waitForSignature()
      }, 200)
    else
      this.initSignaturePad()
  }

  initSignaturePad() {
    if (!this.maintenanceLoaded || this.signaturePad != null)
      return

    this.signaturePad = new SignaturePad(document.getElementById('canvas-signature-pad'), {
      backgroundColor: 'rgba(255, 255, 255, 0)',
      penColor: 'rgb(0, 0, 0)'
    });
    // scale up on devices with hight DPI
    let canvas: any = document.getElementById('canvas-signature-pad');

    var ratio = Math.max(window.devicePixelRatio || 1, 1);
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext("2d").scale(ratio, ratio);

    // redraw the signature on the sig pad
    // $('.sigPad').signaturePad().regenerate(sale.signatureArray);
    // log("signature fromDataURL: ", sale.signatureImage);

    let signature = this.maintenance.getSignature()

    if (signature) {
      //Vlog("signature prev: " + sale.signatureImage.substring(0, 200));
      log("signature fromDataURL: ", signature.data);
      this.signaturePad.fromDataURL(signature.data);
    } else {
      // signaturePad.clear();
    }
  }

  finalizeStart() {
    $('#modalConfirmFinalize').modal('show')
  }

  finalize() {
    $('#modalConfirmFinalize').modal('hide')
    this.maintenance.done = true
    this.maintenance.end = new Date()
    this.maintenance.uuidMaintenanceStatus = this.maintenancesStates[3].uuid
    this.maintenance.statusName = this.maintenancesStates[3].name
    this.save()
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public selectImageFile() {
    $("#input-select-image-file").click()
  }

  public selectedImageFile() {
  }

  selectedImageListener($event: any): void {
    this.readImageFile($event.target);
  }

  readImageFile(inputValue: any): void {
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    if (typeof file == undefined || file == null)
      return;

    let this_ = this

    myReader.onloadend = function (e) {
      // you can perform an action with readed data here
      let data = <string>myReader.result
      let tempSplits = data.split("base64")
      if (tempSplits.length < 2)
        return;
      let type = -1
      if (tempSplits[0].toLocaleLowerCase().indexOf("jpg") >= 0 || tempSplits[0].indexOf("jpeg") >= 0)
        type = CommonsService.IMAGE_TYPE_JPG
      else if (tempSplits[0].toLocaleLowerCase().indexOf("png") >= 0)
        type = CommonsService.IMAGE_TYPE_PNG
      else {
        CommonsService.showErrorMessage(this_.translate.instant("ERROR_UNKNOWN_TYPE"))
        return;
      }

      let newImage = new ImageModel()
      newImage.data = <string>myReader.result
      newImage.name = file.name
      newImage.uuidParent = CommonsService.ID_TABLE_MAINTENANCES + ":" + this_.maintenance.uuid
      newImage.isLink = false

      newImage.type = type
      newImage.uuid = UUID.UUID()
      this_.maintenance.images.push(newImage)

      if (this_.maintenance.uuidsImages != "")
        this_.maintenance.uuidsImages += ","
      this_.maintenance.uuidsImages += newImage.uuid

      this_.repository.maintenances.update(this_.maintenanceBeforeEdit, this_.maintenance)
      this_.maintenanceBeforeEdit = new MaintenanceModel(this_.maintenance)

      CommonsService.showSuccessMessage(this_.translate.instant("ADDED_IMAGE"))
    }

    myReader.readAsDataURL(file);
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }

  public handleImage(webcamImage: WebcamImage): void {
    console.info('received webcam image', webcamImage);

    this.webcamImage = webcamImage;

    let newImage = new ImageModel()
    newImage.data = webcamImage.imageAsDataUrl
    newImage.name = "Bild" + new Date().getTime() + ".jpg"
    newImage.uuidParent = CommonsService.ID_TABLE_MAINTENANCES + ":" + this.maintenance.uuid
    newImage.isLink = false
    newImage.type = 5
    newImage.uuid = UUID.UUID()
    this.maintenance.images.push(newImage)

    if (this.maintenance.uuidsImages != "")
      this.maintenance.uuidsImages += ","
    this.maintenance.uuidsImages += newImage.uuid

    this.repository.maintenances.update(this.maintenanceBeforeEdit, this.maintenance)
    this.maintenanceBeforeEdit = new MaintenanceModel(this.maintenance)

    CommonsService.showSuccessMessage(this.translate.instant("ADDED_IMAGE"))
  }

  public async removeImageStart(uuid: string) {
    let img = this.maintenance.getImage(uuid)
    if (img == null)
      return Promise.resolve()

    this.imageToRemove = img

    $('#confirmRemoveImage').modal('show')
  }

  public async removeImage() {
    if (this.imageToRemove == null)
      return Promise.resolve()

    $('#confirmRemoveImage').modal('hide')

    this.maintenance.removeImage(this.imageToRemove.uuid)

    await this.save()
  }

  public async save() {
    if (!this.signaturePad) {
      // signature remains unchanged
    } else if (this.signaturePad.isEmpty()) {
      this.maintenance.deleteSignature()
    } else {
      var signatureImage = this.signaturePad.toDataURL(); // save image as PNG;

      // scale down from devices with high DPI
      let canvas: any = document.getElementById('canvas-signature-pad');
      var ratio = Math.max(window.devicePixelRatio || 1, 1);
      console.log("Window device pixel ratio: " + window.devicePixelRatio);

      // use dummy canvas; does not work; image is not drawn on canvasTemp, only on second attempt
      var canvasTemp: any = document.createElement("canvas");
      canvasTemp.width = canvas.width / ratio;
      canvasTemp.height = canvas.height / ratio;
      var imageDummy = new Image();

      let this_ = this
      await new Promise((resolve, reject) => {
        imageDummy.onload = function () {
          //canvasTemp.getContext("2d").fillRect(canvasTemp.width - 1, 1, canvasTemp.width, canvasTemp.height);
          canvasTemp.getContext("2d").drawImage(imageDummy, 0, 0,
            canvasTemp.width,
            canvasTemp.height);
          //document.body.appendChild(canvasTemp);
          var signatureImage_ = canvasTemp.toDataURL("image/png");

          let newImage = new ImageModel()
          newImage.data = signatureImage_;
          newImage.name = CommonsService.NAME_FILE_SIGNATURE_IMAGE
          newImage.uuidParent = CommonsService.ID_TABLE_MAINTENANCES + ":" + this_.maintenance.uuid
          newImage.isLink = false
          newImage.type = 8
          newImage.uuid = UUID.UUID()
          this_.maintenance.setSignature(newImage)
          resolve(null)
        }
        imageDummy.src = signatureImage;
      })
    }

    await this.repository.maintenances.update(this.maintenanceBeforeEdit, this.maintenance)
    this.maintenanceBeforeEdit = new MaintenanceModel(this.maintenance)
    CommonsService.showSuccessMessage(this.translate.instant("GENERAL_SAVED"))
  }

  clearSignaturePad() {
    this.signaturePad.clear()
    this.maintenance.deleteSignature()
  }

  public cameraWasSwitched(deviceId: string): void {
    log('active device: ' + deviceId);
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }

  async addMaterialStart(material: MaintenanceMaterialModel | null = null) {
    if (material == null) {
      $('#selectArticleModal').modal('show')
      this.viewArticlesSelect.setFocusToSearchField()
    } else {
      if (material.article) {
        this.materialArticle = material.article
        $('#inputAddAmountModal').modal('show')
      } else {
        CommonsService.showErrorMessage(this.translate.instant("ERROR_UNKNOWN_PART"))
      }
    }
  }

  async removeMaterialStart(material: MaintenanceMaterialModel) {
    if (material.article == null) {
      CommonsService.showErrorMessage(this.translate.instant("ERROR_UNKNOWN_PART"))
    } else {
      this.materialEdit = material
      this.materialArticle = material.article
      this.materialMaxAmount = material.amount
      $('#inputRemoveAmountModal').modal('show')
    }
  }

  async selectedArticleModal(article: any) {
    $('#selectArticleModal').modal('hide')
    await CommonsService.sleep(300)
    this.materialArticle = article
    $('#inputAddAmountModal').modal('show')
  }

  async addMaterialInputDone() {
    if (this.materialAmount <= 0) {
      CommonsService.showErrorMessage(this.translate.instant("ERROR_INVALID_VALUE"))
      return Promise.resolve()
    }

    $('#inputAddAmountModal').modal('hide')

    if (this.materialArticle == null) {
      CommonsService.showErrorMessage(this.translate.instant("ERROR_UNKNOWN_PART"))
      return Promise.resolve()
    }

    // handle material
    let existingMaterial = null
    for (let material of this.materials) {
      if (material.uuidPart == this.materialArticle.uuid) {
        existingMaterial = material
        break;
      }
    }

    if (existingMaterial != null) {
      let existingMaterialBeforeEdit = new MaintenanceMaterialModel(existingMaterial)
      existingMaterial.amount += this.materialAmount
      existingMaterial.priceSumV = existingMaterial.priceUnitV * existingMaterial.amount
      CommonsService.setPrices(existingMaterial, this.parametersStart.currency)

      await this.repository.maintenancesMaterials.update(existingMaterialBeforeEdit, existingMaterial)
    } else {
      let newMaterial = new MaintenanceMaterialModel()
      newMaterial.uuid = UUID.UUID()
      newMaterial.uuidPart = this.materialArticle.uuid
      newMaterial.amount = this.materialAmount
      newMaterial.priceUnitV = this.materialArticle.priceNetV
      newMaterial.priceSumV = newMaterial.priceUnitV * newMaterial.amount
      newMaterial.uuidMaintenance = this.maintenance.uuid
      newMaterial.namePart = this.materialArticle.name
      CommonsService.setPrices(newMaterial, this.parametersStart.currency)

      await this.repository.maintenancesMaterials.create(newMaterial)
    }

    // handle stock movement
    let newStockMovement = new StockMovementModel()
    newStockMovement.uuid = UUID.UUID()
    newStockMovement.date = new Date()
    newStockMovement.amount = this.materialAmount
    newStockMovement.idStockMovementTypeAtServer = 5
    newStockMovement.uuidArticle = this.materialArticle.uuid
    newStockMovement.uuidMaintenance = this.maintenance.uuid

    await this.repository.stockMovements.create(newStockMovement)
    await this.repository.updateStockAfterStockMovement(newStockMovement.uuid)

    //
    await this.readMaterials()
  }

  async removeMaterialInputDone() {
    if (this.materialAmount <= 0 || this.materialAmount > this.materialMaxAmount) {
      CommonsService.showErrorMessage(this.translate.instant("ERROR_INVALID_VALUE"))
      return Promise.resolve()
    }

    $('#inputRemoveAmountModal').modal('hide')

    if (this.materialEdit == null || this.materialArticle == null) {
      CommonsService.showErrorMessage(this.translate.instant("ERROR_UNKNOWN_PART"))
      return Promise.resolve()
    }

    // handle material
    let materialBeforeEdit = new MaintenanceMaterialModel(this.materialEdit)
    this.materialEdit.amount -= this.materialAmount
    this.materialEdit.priceSumV = this.materialEdit.priceUnitV * this.materialEdit.amount
    CommonsService.setPrices(this.materialEdit, this.parametersStart.currency)

    await this.repository.maintenancesMaterials.update(materialBeforeEdit, this.materialEdit)

    if (this.materialEdit.amount == 0)
      await this.repository.maintenancesMaterials.delete(this.materialEdit.uuid)

    // handle stock movement
    let newStockMovement = new StockMovementModel()
    newStockMovement.uuid = UUID.UUID()
    newStockMovement.date = new Date()
    newStockMovement.amount = this.materialAmount
    newStockMovement.idStockMovementTypeAtServer = 6
    newStockMovement.uuidArticle = this.materialArticle.uuid
    newStockMovement.uuidMaintenance = this.maintenance.uuid

    await this.repository.stockMovements.create(newStockMovement)

    await this.repository.updateStockAfterStockMovement(newStockMovement.uuid)

    //
    await this.readMaterials()
  }


  async readMaterials() {
    let articles = await this.repository.articles.readAll()
    let materialsA = await this.repository.maintenancesMaterials.readAll()
    log(materialsA)
    materialsA = materialsA.filter((material) => {
      if (material.uuidMaintenance != this.maintenance.uuid)
        return false

      let article = CommonsService.getObjectByUuid(articles, material.uuidPart)
      if (article) {
        material.article = article
        material.namePart = article.name
      }

      return true
    })

    this.materials = materialsA

    log("the materials")
    log(this.materials)
  }
}
