import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core';
import { CommonsService } from '../commons-service';
import { RepositoryService } from '../repository.service';
import { NetworkService } from '../network.service';
import { DbService } from '../db.service';
declare var $: any;
declare const log: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  username: string = "";
  password: string = "";

  @ViewChild('idInputUsername') inputUsername: ElementRef | null | undefined;
  @ViewChild('idInputPassword') inputPassword: ElementRef | null | undefined;

  constructor(
    private router: Router,
    private networkService: NetworkService,
    private repository: RepositoryService) {
  }

  ngOnInit(): void {
    this.init()
  }

  async init() {
    this.username = await this.repository.readUtilityData("nameUserLoginComponent");
    if (!this.username) {
      setTimeout(() => { this.inputUsername?.nativeElement.focus() },
        CommonsService.TIME_DELAY_FOCUS_MS)
    } else {
      setTimeout(() => { this.inputPassword?.nativeElement.focus() },
        CommonsService.TIME_DELAY_FOCUS_MS)
    }
  }

  async doLogin() {
    const this_ = this;
    let success = await this.networkService.login(this.username, this.password);
    if (success) {
      this.repository.updateUtilityData("nameUserLoginComponent", this.username);
      this_.router.navigateByUrl("/start");
    }
  }

}
