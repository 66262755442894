<div role="main">
  <div class="">
    <div class="page-title">
      <div class="title_left">
        <h3>{{ "ARTICLE_DELETE_TITLE" | translate }}</h3>
      </div>
    </div>
  </div>

  <div class="clearfix"></div>

  <div class="x_panel">
    <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
      <div class="form-horizontal form-label-left">
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-article-number"
            >{{ "ARTICLE_VIEW_NUMBER" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-article-number"
              class="form-control"
              type="text"
              [(ngModel)]="article.number"
              readonly
            />
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
      <div class="form-horizontal form-label-left">
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-article-name"
            >{{ "ARTICLE_VIEW_NAME" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-article-name"
              class="form-control"
              type="text"
              [(ngModel)]="article.name"
              readonly
            />
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
      <div class="form-horizontal form-label-left">
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="textarea-description"
            >{{ "ARTICLE_VIEW_DESCRIPTION" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <textarea
              id="textarea-description"
              class="form-control"
              rows="4"
              id="subject"
              [(ngModel)]="article.description"
              readonly
            ></textarea>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
      <div class="form-horizontal form-label-left">
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-article-name"
            >{{ "ARTICLE_VIEW_EAN" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-article-ean"
              class="form-control"
              type="text"
              [(ngModel)]="article.ean"
              readonly
            />
          </div>
        </div>

        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="select-article-group"
            >{{ "ARTICLE_VIEW_GROUP" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <select
            id="select-article-group"
            class="input-block-level form-control"
            [(ngModel)]="article.uuidArticlegroup"
            disabled="true"
          >
            <option *ngFor="let articleGroup of articleGroups" [ngValue]="articleGroup.uuid">
              {{ articleGroup.name }}
            </option>
          </select>
          </div>
        </div>
      </div>
    </div>

    <div class="clearfix"></div>

    <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
      <div class="form-horizontal form-label-left">
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="select-tax-rate"
          >
            {{ "ARTICLE_VIEW_TAX_RATE" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <select
              id="select-tax-rate"
              class="input-block-level form-control"
              [(ngModel)]="article.uuidTaxRate"
              disabled="true"
            >
              <option *ngFor="let taxRate of taxRates" [ngValue]="taxRate.uuid">
                {{ taxRate.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="clearfix"></div>

    <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
      <div class="form-horizontal form-label-left">
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-article-number"
            >{{ "ARTICLE_VIEW_PRICE_NET" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-article-number"
              class="form-control"
              type="text"
              [(ngModel)]="article.priceNet"
              readonly
            />
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
      <div class="form-horizontal form-label-left">
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-article-name"
            >{{ "ARTICLE_VIEW_PRICE_GROSS" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-article-name"
              class="form-control"
              type="text"
              [(ngModel)]="article.priceGross"
              readonly
            />
          </div>
        </div>
      </div>
    </div>

    <div class="clearfix"></div>

    <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
      <div class="form-horizontal form-label-left">
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-article-number"
            >{{ "ARTICLE_VIEW_DB" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-article-number"
              class="form-control"
              type="text"
              readonly
              [(ngModel)]="article.standardDB"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
      <div class="form-horizontal form-label-left">
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-article-name"
            >{{ "ARTICLE_VIEW_DB_PERCENT" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-article-name"
              class="form-control"
              type="text"
              readonly
              [(ngModel)]="standardDBPercent100"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="clearfix"></div>

    <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
      <div class="form-horizontal form-label-left">
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-article-number"
            >{{ "ARTICLE_VIEW_PURCHASE_COSTS" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-article-number"
              class="form-control"
              type="text"
              [(ngModel)]="article.calcCostPricePerPiece"
              readonly
            />
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
      <div class="form-horizontal form-label-left">
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-article-number"
            >{{ "ARTICLE_VIEW_COSTS" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-article-number"
              class="form-control"
              type="text"
              readonly
              [(ngModel)]="article.purchasePriceUnit"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="clearfix"></div>

    <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
      <div class="form-horizontal form-label-left">
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-article-name"
          ></label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <button (click)="delete()" class="btn btn-danger button-row">
              <span>{{ "BUTTON_DELETE" | translate }} </span
              ><i class="mdi mdi-delete-outline color-bright"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

