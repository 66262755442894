import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerModel } from '../models/customer-model';
import { SexModel } from '../models/sex-model';
import { RepositoryService } from '../repository.service';
declare const log: any

@Component({
  selector: 'app-delete-customer',
  templateUrl: './delete-customer.component.html',
  styleUrls: ['./delete-customer.component.css']
})
export class DeleteCustomerComponent implements OnInit {

  uuidCustomer = ""
  customer: CustomerModel = new CustomerModel()
  sexes: SexModel[] = []

  constructor(
    private route: ActivatedRoute,
    private locaction: Location,
    private router: Router,
    private repository: RepositoryService
  ) { }

  ngOnInit(): void {
    this.init()
  }

  async init() {
    const routeParams = this.route.snapshot.paramMap;
    this.uuidCustomer = String(routeParams.get('uuidCustomer'));

    let customerTemp = await this.repository.customers.read(this.uuidCustomer, false)

    this.customer = <CustomerModel>customerTemp

    this.sexes = <SexModel[]>await this.repository.sexes.readAll()
  }

  async delete() {
    if (this.customer)
      await this.repository.customers.delete(this.customer.uuid)
    this.locaction.back()
  }
}
