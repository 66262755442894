import { SalePositionModel } from "./sale-position-model";

export class InvoicePositionModel extends SalePositionModel {
  constructor(params: any = null) {
    super(params)
    this.copyFromObject(params)
  }

  static override newModel(object: any = null) {
    return new InvoicePositionModel(object)
  }

}
