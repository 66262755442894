<div
  role="main"
  (keydown.arrowdown)="arrowDownEvent($event)"
  (keydown.arrowup)="arrowUpEvent($event)"
  (keydown.Enter)="enterEvent($event)"
>
  <div class="">
    <div class="page-title">
      <div class="title_left">
        <h3>{{ "CUSTOMERS_TITLE" | translate }}</h3>
      </div>
    </div>
  </div>

  <div class="clearfix"></div>

  <div class="x_panel">
    <div class="x_title display-flex">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding: 0px">
        <div class="col-lg-6 col-md-6 col-sm-8 col-xs-12">
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              placeholder="{{ 'CUSTOMERS_TOOLTIP_SEARCH' | translate }}..."
              [(ngModel)]="textSearch"
              (keyup)="keyUpEvent($event)"
              id="id-input-text-search"
              #idInputTextSearch
            />

            <span class="input-group-btn">
              <button
                class="btn btn-default"
                type="button"
                (click)="search()"
                data-toggle="tooltip"
                data-placement="top"
              >
                {{ "CUSTOMERS_BUTTON_SEARCH" | translate }}
                <i class="mdi mdi-magnify"></i>
              </button>
            </span>
          </div>
        </div>

        <div class="visible-xs col-xs-12" style="margin-bottom: 12px" [hidden]="!showLinks"></div>

        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-3" [hidden]="!showLinks">
          <button
            class="btn btn-default"
            type="button"
            (click)="gotoAddCustomer()"
            data-toggle="tooltip"
            data-placement="top"
            title="{{ 'CUSTOMERS_TOOLTIP_ADD' | translate }}"
          >
            {{ "CUSTOMERS_BUTTON_NEW" | translate }}
            <i class="mdi mdi-plus-circle indicator-color-green"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="x_content">
      <table class="table table-striped">
        <thead>
          <tr>
            <th class="tableColWidthM" (click)="changeSorting('firstName')">
              <div class="table-header-col">
                {{ "CUSTOMERS_TABLE_FIRST_NAME" | translate
                }}<span [hidden]="sort.column != 'firstName'">
                  <span [hidden]="!sort.ascending"> ^</span>
                  <span [hidden]="sort.ascending"> v</span></span
                >
              </div>
            </th>
            <th class="tableColWidthM" (click)="changeSorting('lastName')">
              <div class="table-header-col">
                {{ "CUSTOMERS_TABLE_LAST_NAME" | translate
                }}<span [hidden]="sort.column != 'lastName'">
                  <span [hidden]="!sort.ascending"> ^</span>
                  <span [hidden]="sort.ascending"> v</span>
                </span>
              </div>
            </th>
            <th class="tableColWidthM" (click)="changeSorting('street')">
              <div class="table-header-col">
                {{ "CUSTOMERS_TABLE_STREET" | translate
                }}<span [hidden]="sort.column != 'street'">
                  <span [hidden]="!sort.ascending"> ^</span>
                  <span [hidden]="sort.ascending"> v</span>
                </span>
              </div>
            </th>
            <th class="tableColWidthM" (click)="changeSorting('zipCode')">
              <div class="table-header-col">
                {{ "CUSTOMERS_TABLE_ZIP_CODE" | translate }}
                <span [hidden]="sort.column != 'zipCode'">
                  <span [hidden]="!sort.ascending"> ^</span>
                  <span [hidden]="sort.ascending"> v</span>
                </span>
              </div>
            </th>
            <th class="tableColWidthM" (click)="changeSorting('city')">
              <div class="table-header-col">
                {{ "CUSTOMERS_TABLE_CITY" | translate }}
                <span [hidden]="sort.column != 'city'">
                  <span [hidden]="!sort.ascending"> ^</span>
                  <span [hidden]="sort.ascending"> v</span>
                </span>
              </div>
            </th>
            <th class="tableColWidthM" [hidden]="!showLinks">
              <div
                class="table-header-col"
                data-toggle="tooltip"
                data-placement="top"
                title="{{ 'CUSTOMERS_TABLE_TOOLTIP_ADD' | translate }}"
                (click)="gotoAddCustomer()"
              >
                <i class="mdi mdi-plus-circle indicator-color-green"></i>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let customer of customers
                | limitFromTo
                  : currentPage * pageSize
                  : (currentPage + 1) * pageSize;
              let i = index
            "
            [ngClass]="iRowHighlighted == i ? 'table-row-highlight' : ''"
            id="{{ 'id-table-customers-row-' + i }}"
          >
            <td (click)="clickedOnCustomer(customer)">
              {{ customer.firstName }}
            </td>
            <td (click)="clickedOnCustomer(customer)">
              {{ customer.lastName }}
            </td>
            <td (click)="clickedOnCustomer(customer)">
              {{ customer.street }}
            </td>
            <td (click)="clickedOnCustomer(customer)">
              {{ customer.zipCode }}
            </td>
            <td (click)="clickedOnCustomer(customer)">
              {{ customer.city }}
            </td>
            <td [hidden]="!showLinks">
              <div class="dropdown">
                <button
                  class="btn btn-sm btn-default dropdown-toggle btn-action"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {{
                    "GENERAL_TABLE_DROPDOWN_ACTIONS" | translate | capitalize
                  }}
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/editCustomer/{{ customer.uuid }}"
                      ng-click="$event.stopPropagation();"
                      data-toggle="tooltip"
                      data-placement="top"
                      name="{{
                        'CUSTOMERS_TABLE_TOOLTIP_EDIT' | translate | capitalize
                      }}"
                    >
                      <i class="mdi mdi-pencil"></i>&nbsp;{{
                        "CUSTOMERS_TABLE_TOOLTIP_EDIT" | translate | capitalize
                      }}</a
                    >
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/viewAppointments/{{ customer.uuid }}"
                      ng-click="$event.stopPropagation();"
                      data-toggle="tooltip"
                      data-placement="top"
                      name="{{
                        'CUSTOMERS_TABLE_TOOLTIP_APPOINTMENTS'
                          | translate
                          | capitalize
                      }}"
                    >
                      <i class="mdi mdi-calendar"></i>&nbsp;{{
                        "CUSTOMERS_TABLE_TOOLTIP_APPOINTMENTS"
                          | translate
                          | capitalize
                      }}</a
                    >
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/viewPartners/{{ customer.uuid }}"
                      data-toggle="tooltip"
                      data-placement="top"
                      id="{{
                        'CUSTOMERS_TABLE_TOOLTIP_PARTNER'
                          | translate
                          | capitalize
                      }}"
                    >
                      <i class="mdi mdi-account-box-outline"></i>&nbsp;{{
                        "CUSTOMERS_TABLE_TOOLTIP_PARTNER"
                          | translate
                          | capitalize
                      }}</a
                    >
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/viewCustomerContacts/{{ customer.uuid }}"
                      data-toggle="tooltip"
                      data-placement="top"
                      id="{{
                        'CUSTOMERS_TABLE_TOOLTIP_CONTACTS'
                          | translate
                          | capitalize
                      }}"
                    >
                      <i class="mdi mdi-forum-outline"></i>&nbsp;{{
                        "CUSTOMERS_TABLE_TOOLTIP_CONTACTS"
                          | translate
                          | capitalize
                      }}</a
                    >
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/viewSales/Offer/{{ customer.uuid }}"
                      data-toggle="tooltip"
                      data-placement="top"
                      id="{{
                        'CUSTOMERS_TABLE_TOOLTIP_OFFERS'
                          | translate
                          | capitalize
                      }}"
                    >
                      <i class="mdi mdi-clipboard-text-outline"></i>&nbsp;{{
                        "CUSTOMERS_TABLE_TOOLTIP_OFFERS"
                          | translate
                          | capitalize
                      }}</a
                    >
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/viewSales/Order/{{ customer.uuid }}"
                      data-toggle="tooltip"
                      data-placement="top"
                      id="{{
                        'CUSTOMERS_TABLE_TOOLTIP_ORDERS'
                          | translate
                          | capitalize
                      }}"
                    >
                      <i class="mdi mdi-clipboard-text-play-outline"></i
                      >&nbsp;{{
                        "CUSTOMERS_TABLE_TOOLTIP_ORDERS"
                          | translate
                          | capitalize
                      }}</a
                    >
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/viewCustomerSalesHistory/{{ customer.uuid }}"
                      data-toggle="tooltip"
                      data-placement="top"
                      id="{{
                        'CUSTOMERS_TABLE_TOOLTIP_ORDERS'
                          | translate
                          | capitalize
                      }}"
                    >
                      <i class="mdi mdi-script-text-outline"></i>&nbsp;{{
                        "CUSTOMERS_TABLE_TOOLTIP_SALES_HISTORY"
                          | translate
                          | capitalize
                      }}</a
                    >
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/deleteCustomer/{{ customer.uuid }}"
                      ng-click="$event.stopPropagation();"
                      data-toggle="tooltip"
                      data-placement="top"
                      id="{{
                        'CUSTOMERS_TABLE_TOOLTIP_DELETE'
                          | translate
                          | capitalize
                      }}"
                    >
                      <i class="mdi mdi-delete-outline"></i>&nbsp;{{
                        "CUSTOMERS_TABLE_TOOLTIP_DELETE"
                          | translate
                          | capitalize
                      }}</a
                    >
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <button
          class="btn btn-sm btn-default btn-navigation"
          [disabled]="currentPage == 0 || numberOfPages() == 0"
          (click)="currentPage = 0; storeCurrentPage()"
        >
          {{ "NAVIGATION_PAGE_FIRST" | translate }}
        </button>
        <button
          class="btn btn-sm btn-default btn-navigation"
          [disabled]="currentPage == 0 || numberOfPages() == 0"
          (click)="currentPage = currentPage - 1; storeCurrentPage()"
        >
          {{ "NAVIGATION_PAGE_PREVIOUS" | translate }}
        </button>
        <span class="text-navigation">
          {{ currentPage + 1 }}/{{ numberOfPages() }}
        </span>
        <button
          class="btn btn-sm btn-default btn-navigation"
          [disabled]="currentPage >= numberOfPages() - 1"
          (click)="currentPage = currentPage + 1; storeCurrentPage()"
        >
          {{ "NAVIGATION_PAGE_NEXT" | translate }}
        </button>
        <button
          class="btn btn-sm btn-default btn-navigation"
          [disabled]="currentPage >= numberOfPages() - 1"
          (click)="currentPage = numberOfPages() - 1; storeCurrentPage()"
        >
          {{ "NAVIGATION_PAGE_LAST" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
