import { CommonsService } from "../commons-service";
import { InvoicePositionModel } from "./invoice-position-model";
import { ModelFactory } from "./model-factory";
import { ModelInterface } from "./model-interface";
import { SaleModel } from "./sale-model";

export class InvoiceModel extends SaleModel {

  uuidOrder: string = ""

  constructor(params: any = null) {
    super(params)
    this.copyFromObject(params)
    this.textDate = CommonsService.formatDate0(this.date)
  }

  override clone(modelFactory: ModelFactory<ModelInterface>): InvoiceModel {
    let clone: InvoiceModel = new InvoiceModel(this)
    clone.positions =
      <InvoicePositionModel[]>modelFactory.newModels(InvoicePositionModel, this.positions)
    return clone
  }

  static override newModel(object: any = null) {
    return new InvoiceModel(object)
  }

}
