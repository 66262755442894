<div role="main">
  <div class="">
    <div class="page-title">
      <div class="title_left">
        <h3>{{ "DEVICES_TITLE" | translate }}</h3>
      </div>
    </div>
  </div>

  <div class="clearfix"></div>

  <div class="x_panel">
    <div class="x_title display-flex">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding: 0px">
        <div class="col-lg-6 col-md-6 col-sm-8 col-xs-12">
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              placeholder="{{ 'CUSTOMERS_TOOLTIP_SEARCH' | translate }}..."
              type="text"
              [(ngModel)]="textSearch"
              (keyup)="keyUpEvent($event)"
            />
            <!-- (keyup.enter)="search()" -->
            <span class="input-group-btn">
              <button
                class="btn btn-default"
                type="button"
                (click)="search()"
                data-toggle="tooltip"
                data-placement="top"
              >
                {{ "DEVICES_BUTTON_SEARCH" | translate }}
                <i class="mdi mdi-magnify"></i>
              </button>
            </span>
          </div>
        </div>

        <div class="visible-xs col-xs-12" style="margin-bottom: 12px"></div>

        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-3">
          <button
            class="btn btn-default"
            type="button"
            (click)="addDevice()"
            data-toggle="tooltip"
            data-placement="top"
            title="{{ 'DEVICES_TOOLTIP_ADD' | translate }}"
          >
            {{ "BUTTON_NEW" | translate }}
            <i class="mdi mdi-plus-circle indicator-color-green"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="x_content">
      <table class="table table-striped">
        <thead>
          <tr>
            <th class="table-header-col-no-sort">
              {{ "DEVICES_TABLE_SERIAL_NUMBER" | translate }}
            </th>
            <th class="table-header-col-no-sort">
              {{ "DEVICES_TABLE_NAME" | translate }}
            </th>
            <th class="table-header-col-no-sort">
              {{ "DEVICES_TABLE_CUSTOMER" | translate }}
            </th>
            <th class="table-header-col-no-sort">
              {{ "DEVICES_TABLE_STREET" | translate }}
            </th>
            <th class="table-header-col-no-sort">
              {{ "DEVICES_TABLE_ZIP_CODE" | translate }}
            </th>
            <th class="table-header-col-no-sort">
              {{ "DEVICES_TABLE_CITY" | translate }}
            </th>
            <th class="tableColWidthIcon">
              <div
                class="table-header-col"
                (click)="addDevice()"
                data-toggle="tooltip"
                data-placement="top"
                title="{{ 'DEVICES_TOOLTIP_ADD' | translate }}"
              >
                <i class="mdi mdi-plus-circle indicator-color-green"></i>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let device of devices
                | limitFromTo
                  : currentPage * pageSize
                  : (currentPage + 1) * pageSize;
              let i = index
            "
          >
            <td routerLink="/editDevice/{{ device.uuid }}">
              {{ device.serialNumber }}
            </td>
            <td routerLink="/editDevice/{{ device.uuid }}">
              {{ device.name }}
            </td>
            <!-- td>{{ entry.begin | date:'dd.MM.yyyy HH:mm'}}</td>
                <td>{{ entry.end | date:'dd.MM.yyyy HH:mm'}}</td-->
            <td
              routerLink="/editDevice/{{ device.uuid }}"
              class="word-break-250px"
            >
              {{ device.nameCustomer }}
            </td>
            <td
              routerLink="/editDevice/{{ device.uuid }}"
              class="word-break-250px"
            >
              {{ device.street }}
            </td>
            <td
              routerLink="/editDevice/{{ device.uuid }}"
              class="word-break-250px"
            >
              {{ device.zipCode }}
            </td>

            <td
              routerLink="/editDevice/{{ device.uuid }}"
              class="word-break-250px"
            >
              {{ device.city }}
            </td>

            <td ng-show="1">
              <div class="dropdown">
                <button
                  class="btn btn-sm btn-default dropdown-toggle btn-action"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {{
                    "GENERAL_TABLE_DROPDOWN_ACTIONS" | translate | capitalize
                  }}
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/editDevice/{{ device.uuid }}"
                      data-toggle="tooltip"
                      data-placement="top"
                    >
                      <i class="mdi mdi-pencil"></i>&nbsp;{{
                        "APPOINTMENTS_TABLE_TOOLTIP_EDIT"
                          | translate
                          | capitalize
                      }}</a
                    >
                  </li>

                  <li [hidden]="!device.enableButtonCustomer">
                    <a
                      class="dropdown-item"
                      routerLink="/editCustomer/{{ device.uuidCustomer }}"
                      data-toggle="tooltip"
                      data-placement="top"
                    >
                      <i class="mdi mdi-account"></i>&nbsp;{{
                        "APPOINTMENTS_TABLE_TOOLTIP_CUSTOMER"
                          | translate
                          | capitalize
                      }}</a
                    >
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <button
          class="btn btn-sm btn-default btn-navigation"
          [disabled]="currentPage == 0 || numberOfPages() == 0"
          (click)="currentPage = 0; storeCurrentPage()"
        >
          {{ "NAVIGATION_PAGE_FIRST" | translate }}
        </button>
        <button
          class="btn btn-sm btn-default btn-navigation"
          [disabled]="currentPage == 0 || numberOfPages() == 0"
          (click)="currentPage = currentPage - 1; storeCurrentPage()"
        >
          {{ "NAVIGATION_PAGE_PREVIOUS" | translate }}
        </button>
        <span class="text-navigation">
          {{ currentPage + 1 }}/{{ numberOfPages() }}
        </span>
        <button
          class="btn btn-sm btn-default btn-navigation"
          [disabled]="currentPage >= numberOfPages() - 1"
          (click)="currentPage = currentPage + 1; storeCurrentPage()"
        >
          {{ "NAVIGATION_PAGE_NEXT" | translate }}
        </button>
        <button
          class="btn btn-sm btn-default btn-navigation"
          [disabled]="currentPage >= numberOfPages() - 1"
          (click)="currentPage = numberOfPages() - 1; storeCurrentPage()"
        >
          {{ "NAVIGATION_PAGE_LAST" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
