<div role="main">
  <div class="">
    <div class="page-title">
      <div class="title_left">
        <h3>{{ "TASKS_TITLE" | translate }}</h3>
      </div>
    </div>
  </div>

  <div class="clearfix"></div>

  <div class="x_panel">
    <div class="x_title display-flex">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding: 0px">
        <div class="col-lg-2 col-md-3 col-sm-3 col-xs-6">
          <span class="button-row" style="top: 5px; position: relative"
            >{{ "TASKS_SHOW_OPEN_ONLY" | translate }}
          </span>
          <span class="display-inline-block">
            <input
              style="top: 6px; position: relative"
              type="checkbox"
              [(ngModel)]="showOpenTasksOnly"
              (ngModelChange)="showOpenTasksOnlyChanged()"
          /></span>
        </div>

        <div class="visible-xs col-xs-12" style="margin-bottom: 8px"></div>

        <div class="col-lg-5 col-md-6 col-sm-6 col-xs-10">
          <span class="button-row no-move">
            {{ "TASKS_SHOW_CATEGORY" | translate }}
          </span>
          <span class="display-inline-block">
            <select
              id="tasks-select-category"
              class="input-block-level form-control"
              [(ngModel)]="showTaskCategoryUuid"
              (ngModelChange)="showTasksCategoryChanged()"
            >
              <option
                *ngFor="let taskCategory of taskCategories"
                [ngValue]="taskCategory.uuid"
              >
                {{ taskCategory.name }}
              </option>
            </select>
          </span>
        </div>

        <div class="visible-xs col-xs-12" style="margin-bottom: 8px"></div>

        <div class="col-lg-5 col-md-2 col-sm-2 col-xs-12">
          <button
            type="button"
            class="btn btn-default"
            routerLink="/addTask"
            title="{{ 'TASKS_TOOLTIP_ADD' | translate }}"
          >
            {{ "TASKS_BUTTON_NEW" | translate }}
            <i class="mdi mdi-plus-circle indicator-color-green"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="x_content">
      <table class="table table-striped">
        <thead>
          <tr>
            <th class="col-fixed-2" (click)="changeSorting('done')">
              <div class="table-header-col">
                {{ "TASKS_TABLE_DONE" | translate
                }}<span [hidden]="sort.column != 'done'">
                  <span [hidden]="!sort.ascending"> ^</span>
                  <span [hidden]="sort.ascending"> v</span></span
                >
              </div>
            </th>
            <th class="col-fixed-2" (click)="changeSorting('taskCategory')">
              <div class="table-header-col">
                {{ "TASKS_TABLE_CATEGORY" | translate
                }}<span [hidden]="sort.column != 'taskCategory'">
                  <span [hidden]="!sort.ascending"> ^</span>
                  <span [hidden]="sort.ascending"> v</span></span
                >
              </div>
            </th>
            <th class="col-fixed-2" (click)="changeSorting('priority')">
              <div class="table-header-col">
                {{ "TASKS_TABLE_PRIORITY" | translate
                }}<span [hidden]="sort.column != 'priority'">
                  <span [hidden]="!sort.ascending"> ^</span>
                  <span [hidden]="sort.ascending"> v</span></span
                >
              </div>
            </th>
            <th class="col-fixed-2" (click)="changeSorting('due')">
              <div class="table-header-col">
                {{ "TASKS_TABLE_DUE" | translate
                }}<span [hidden]="sort.column != 'due'">
                  <span [hidden]="!sort.ascending"> ^</span>
                  <span [hidden]="sort.ascending"> v</span></span
                >
              </div>
            </th>
            <th class="" (click)="changeSorting('title')">
              <div class="table-header-col">
                {{ "TASKS_TABLE_TITLE" | translate
                }}<span [hidden]="sort.column != 'title'">
                  <span [hidden]="!sort.ascending"> ^</span>
                  <span [hidden]="sort.ascending"> v</span></span
                >
              </div>
            </th>
            <th class="" (click)="changeSorting('employee')">
              <div class="table-header-col">
                {{ "TASKS_TABLE_EMPLOYEE" | translate
                }}<span [hidden]="sort.column != 'employee'">
                  <span [hidden]="!sort.ascending"> ^</span>
                  <span [hidden]="sort.ascending"> v</span></span
                >
              </div>
            </th>
            <th class="col-fixed-2 tableColWidthIcon">
              <div
                class="table-header-col"
                routerLink="/addTask"
                title="{{ 'TASKS_TABLE_TOOLTIP_ADD' | translate }}"
              >
                <i class="mdi mdi-plus-circle indicator-color-green"></i>
              </div>
            </th>
            <!--th class="col-fixed-2 tableColWidthIcon"></th -->
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let task of tasks">
            <td ng-click="go(task)">
              <input
                type="checkbox"
                [(ngModel)]="task.done"
                (ngModelChange)="doneChanged(task)"
              />
            </td>
            <td routerLink="/editTask/{{ task.uuid }}">
              {{ task.textCategory }}
            </td>
            <td routerLink="/editTask/{{ task.uuid }}" class="word-break-150px">
              {{ task.priority }}
            </td>
            <td routerLink="/editTask/{{ task.uuid }}">
              {{ task.textDue }}
            </td>
            <!-- td>{{ task.due | date:'dd.MM.yyyy HH:mm'}}</td -->
            <td
              routerLink="/editTask/{{ task.uuid }}"
              class="word-break-250px {{ task.classTextDecoration }}"
            >
              {{ task.title }}
            </td>
            <td
              routerLink="/editTask/{{ task.uuid }}"
              class="word-break-250px {{ task.classTextDecoration }}"
            >
              {{ task.nameEmployee }}
            </td>

            <td ng-show="1">
              <div class="dropdown">
                <button
                  class="btn btn-sm btn-default dropdown-toggle btn-action"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {{
                    "GENERAL_TABLE_DROPDOWN_ACTIONS" | translate | capitalize
                  }}
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/editTask/{{ task.uuid }}"
                    >
                      <i class="mdi mdi-pencil"></i>&nbsp;{{
                        "TASKS_TABLE_TOOLTIP_EDIT" | translate | capitalize
                      }}</a
                    >
                  </li>

                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/deleteTask/{{ task.uuid }}"
                    >
                      <i class="mdi mdi-delete-outline"></i>&nbsp;{{
                        "TASKS_TABLE_TOOLTIP_DELETE" | translate | capitalize
                      }}</a
                    >
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
