import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { StartComponent } from './start/start.component';
import { ParametersStartService } from './parameters-start.service'
declare const log: any;

@Injectable()
export class CanDeactivateStartGuard implements CanDeactivate<StartComponent> {

  constructor(
    private parametersStart: ParametersStartService) {
  }

  canDeactivate(target: StartComponent) {
    if (this.parametersStart.isSyncRunning) {
      log("prevented navigation because synchronization is running.")
      return false
    }
    return true
  }
}
