import { BaseModel } from "./base-model";

export class CompanyModel extends BaseModel {
  name: string = ""
  street: string = ""
  zipCode: string = ""
  city: string = ""
  telephone: string = ""
  fax: string = ""
  email: string = ""
  homepage: string = ""

  constructor(params: any = null) {
    super(params)
    this.copyFromObject(params)
  }

  static override newModel(object: any = null) {
    return new CompanyModel(object)
  }

}
