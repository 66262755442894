import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {

  transform(text: string): string {
    if (text != null) {
      text = text.toLowerCase();
      return text.substring(0, 1).toUpperCase() + text.substring(1);
    } else
      return "";
  }
}
