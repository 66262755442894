import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { CommonsService } from '../commons-service';
import { ParametersStartService } from '../parameters-start.service';
import { SynchronizationService } from '../synchronization.service';
import { RepositoryService } from '../repository.service';
import { NetworkService } from '../network.service';
declare var $: any;
declare const log: any;

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.css'],
  providers: []
})
export class StartComponent implements OnInit {

  constructor(
    private translate: TranslateService,
    private router: Router,
    public parametersStart: ParametersStartService,
    private repository: RepositoryService,
    private networkService: NetworkService) {
  }

  ngOnInit() {
    this.init()
  }

  async init() {
    const this_ = this;
    window.addEventListener("beforeunload", function (e) {
      // do something when the page disappears
      /*
      var confirmationMessage = "\o/";
      e.returnValue = confirmationMessage;     // Gecko, Trident, Chrome 34+
      return confirmationMessage;              // Gecko, WebKit, Chrome <34
      */
    });

    let nameUser = await this.repository.readNameUser()
    this_.parametersStart.nameUser = nameUser;

    if (nameUser) {
      this_.parametersStart.buttonLoginLogoutText = "Logout";
    } else {
      this_.parametersStart.buttonLoginLogoutText = "Login";
    }
  }

  async gotoLoginOrDoLogout() {
    let nameUser = await this.repository.readNameUser()

    if (!nameUser) {
      this.router.navigateByUrl("/login")
    } else {
      this.networkService.logout()
      this.parametersStart.buttonLoginLogoutText = "Login";
      this.parametersStart.buttonLoginLogoutEnabled = true;
      CommonsService.showSuccessMessage(this.translate.instant("GENERAL_LOGGED_OUT"))
    }
  }

  selectedCustomerModal(customer: any) {
    log("got customer from modal", customer)
    $('#confirmModal').modal('hide')
  }

  selectedArticleModal(article: any) {
    log("got article from modal", article)
    $('#confirmModal').modal('hide')
  }

  async deleteDatabase() {
    await this.repository.deleteLocalDatabase();

    // clear local storage
    localStorage.clear();

    // clear session storage
    sessionStorage.clear();

    // delete all cookies
    /*
    var cookies_ = cookies.getAll();
    cookies_.forEach(cookies_, function (v, k) {
      log("deleting cookie " + k);
      $cookies.remove(k);
    });
    */

    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }

    window.location.reload();
  }

}
