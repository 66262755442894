<div role="main">
  <div class="">
    <div class="page-title">
      <div class="title_left">
        <h3>{{ textTitle }}</h3>
      </div>
    </div>
  </div>

  <div class="clearfix"></div>

  <div class="x_panel">
    <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
      <div class="form-horizontal form-label-left">
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="select-sex"
            >{{ "PARTNER_EDIT_SEX" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <select
              id="select-sex"
              class="form-control"
              [(ngModel)]="partner.uuidSex"
            >
              <option *ngFor="let sex of sexes" [ngValue]="sex.uuid">
                {{ sex.formOfAddress }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-title"
          >
            {{ "PARTNER_EDIT_ACADEMIC_TITLE" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-title"
              class="form-control"
              type="text"
              [(ngModel)]="partner.academicTitle"
            />
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-first-name"
          >
            {{ "PARTNER_EDIT_FIRSTNAME" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-first-name"
              class="form-control"
              type="text"
              [(ngModel)]="partner.firstName"
            />
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-last-name"
          >
            {{ "PARTNER_EDIT_LASTNAME" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-last-name"
              class="form-control"
              type="text"
              [(ngModel)]="partner.lastName"
            />
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-position"
          >
            {{ "PARTNER_EDIT_POSITION" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-position"
              class="form-control"
              type="text"
              [(ngModel)]="partner.position"
            />
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-telephone-1"
          >
            {{ "PARTNER_EDIT_TELEPHONE_1" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-telephone-1"
              class="form-control"
              type="text"
              [(ngModel)]="partner.telephone1"
            />
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-telephone-2"
          >
            {{ "PARTNER_EDIT_TELEPHONE_2" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-telephone-2"
              class="form-control"
              type="text"
              [(ngModel)]="partner.telephone2"
            />
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-fax"
          >
            {{ "PARTNER_EDIT_FAX" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-fax"
              class="form-control"
              type="text"
              [(ngModel)]="partner.fax"
            />
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-email"
          >
            {{ "PARTNER_EDIT_EMAIL" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-email"
              class="form-control"
              type="text"
              [(ngModel)]="partner.email"
            />
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="textarea-remarks"
          >
            {{ "PARTNER_EDIT_REMARKS" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <textarea
              class="form-control"
              rows="3"
              id="textarea-remarks"
              [(ngModel)]="partner.remarks"
              name="task.title"
            ></textarea>
          </div>
        </div>
      </div>
    </div>

    <div class="clearfix"></div>

    <div class="">
      <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
        <div class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"></div>
        <div class="flex-nowrap">
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <button (click)="save()" class="btn btn-primary button-row">
              <span>{{ "BUTTON_SAVE" | translate }} </span
              ><i class="mdi mdi-content-save color-bright"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
