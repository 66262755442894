import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RepositoryService } from '../repository.service';
import { MaintenanceForViewModel } from '../models/maintenance-for-view-model';
import { CommonsService } from '../commons-service';

@Component({
  selector: 'app-delete-maintenance',
  templateUrl: './delete-maintenance.component.html',
  styleUrls: ['./delete-maintenance.component.css']
})
export class DeleteMaintenanceComponent implements OnInit {

  uuidMaintenance = ""
  maintenanceLoaded = false
  maintenance: MaintenanceForViewModel = new MaintenanceForViewModel()

  constructor(private route: ActivatedRoute,
    private locaction: Location,
    private router: Router,
    private repository: RepositoryService) {
  }

  ngOnInit(): void {
    this.init()
  }

  async init() {
    const routeParams = this.route.snapshot.paramMap;
    this.uuidMaintenance = String(routeParams.get('uuidMaintenance'));
    let maintenanceTemp = await this.repository.maintenances.read(this.uuidMaintenance, false)

    this.maintenance = new MaintenanceForViewModel(maintenanceTemp)

    this.maintenance.textBegin = CommonsService.formatDateTime0(this.maintenance.begin)

    this.maintenance.setCustomer(await this.repository.customers.read(this.maintenance.uuidCustomer, true))
    this.maintenance.setDevice(await this.repository.devices.read(this.maintenance.uuidDevice, true))

    let article = await this.repository.articles.read(this.maintenance.uuidArticle, true)
    if (article != null) {
      this.maintenance.article = article
      this.maintenance.articleName = article.number + " - " + article.name
    }
  }

  async delete() {
    if (this.maintenance)
      await this.repository.maintenances.delete(this.maintenance.uuid)
    this.locaction.back()
  }
}
