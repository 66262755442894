import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StartComponent } from './start/start.component';
import { LoginComponent } from './login/login.component';
import { ViewCustomersComponent } from './view-customers/view-customers.component';
import { EditCustomerComponent } from './edit-customer/edit-customer.component';
import { AddCustomerComponent } from './add-customer/add-customer.component';
import { ViewArticlesComponent } from './view-articles/view-articles.component';
import { ViewOpenSalesComponent } from './view-open-sales/view-open-sales.component';
import { CanDeactivateStartGuard } from './can-deactivate-start';
import { EditSaleComponent } from './edit-sale/edit-sale.component'
import { EditSalePositionComponent } from './edit-sale-position/edit-sale-position.component';
import { DeleteSalePositionComponent } from './delete-sale-position/delete-sale-position.component';
import { PrintSaleComponent } from './print-sale/print-sale.component';
import { ViewSalesComponent } from './view-sales/view-sales.component';
import { DeleteSaleComponent } from './delete-sale/delete-sale.component';
import { ViewCustomerSalesHistoryComponent } from './view-customer-sales-history/view-customer-sales-history.component';
import { ViewArticleComponent } from './view-article/view-article.component';
import { ViewAppointmentsComponent } from './view-appointments/view-appointments.component';
import { EditAppointmentComponent } from './edit-appointment/edit-appointment.component';
import { AddAppointmentComponent } from './add-appointment/add-appointment.component';
import { DeleteAppointmentComponent } from './delete-appointment/delete-appointment.component';
import { ViewTasksComponent } from './view-tasks/view-tasks.component';
import { EditTaskComponent } from './edit-task/edit-task.component';
import { AddTaskComponent } from './add-task/add-task.component';
import { DeleteTaskComponent } from './delete-task/delete-task.component';
import { ViewCustomersSuggNextComponent } from './view-customers-sugg-next/view-customers-sugg-next.component';
import { ViewCustomersSuggFreqComponent } from './view-customers-sugg-freq/view-customers-sugg-freq.component';
import { ViewEnquiriesComponent } from './view-enquiries/view-enquiries.component';
import { ViewPartnersComponent } from './view-partners/view-partners.component';
import { EditPartnerComponent } from './edit-partner/edit-partner.component';
import { AddPartnerComponent } from './add-partner/add-partner.component';
import { DeletePartnerComponent } from './delete-partner/delete-partner.component';
import { ViewCustomerContactsComponent } from './view-customer-contacts/view-customer-contacts.component';
import { AddCustomerContactComponent } from './add-customer-contact/add-customer-contact.component';
import { EditCustomerContactComponent } from './edit-customer-contact/edit-customer-contact.component';
import { DeleteCustomerComponent } from './delete-customer/delete-customer.component';
import { DeleteCustomerContactComponent } from './delete-customer-contact/delete-customer-contact.component';
import { ViewDevicesComponent } from './view-devices/view-devices.component';
import { EditDeviceComponent } from './edit-device/edit-device.component';
import { EditMaintenanceComponent } from './edit-maintenance/edit-maintenance.component';
import { ViewMaintenancesComponent } from './view-maintenances/view-maintenances.component';
import { DeleteMaintenanceComponent } from './delete-maintenance/delete-maintenance.component';
import { AddArticleComponent } from './add-article/add-article.component';
import { DeleteArticleComponent } from './delete-article/delete-article.component';

const routes: Routes = [
  { path: "", pathMatch: "full", redirectTo: "start" },
  {
    path: "start", component: StartComponent, canDeactivate: [
      CanDeactivateStartGuard
    ]
  },
  { path: "login", component: LoginComponent },
  { path: "viewCustomers", component: ViewCustomersComponent },
  { path: "addCustomer", component: AddCustomerComponent },
  { path: "editCustomer/:uuidCustomer", component: EditCustomerComponent },
  { path: "deleteCustomer/:uuidCustomer", component: DeleteCustomerComponent },
  { path: "viewArticles", component: ViewArticlesComponent },
  { path: "viewArticle/:uuidArticle", component: ViewArticleComponent },
  { path: "deleteArticle/:uuidArticle", component: DeleteArticleComponent },
  { path: "viewOpenSales/:type", component: ViewOpenSalesComponent },
  { path: "editSale/:type/:uuidSale/:isNew", component: EditSaleComponent },
  { path: "editSalePosition/:type/:uuidSalePosition/:isSaleNew", component: EditSalePositionComponent },
  { path: "deleteSalePosition/:type/:uuidSalePosition/:isSaleNew", component: DeleteSalePositionComponent },
  { path: "printSale/:type/:uuidSale", component: PrintSaleComponent },
  { path: "viewSales/:type/:uuidCustomer", component: ViewSalesComponent },
  { path: "deleteSale/:type/:uuidSale", component: DeleteSaleComponent },
  { path: "viewCustomerSalesHistory/:uuidCustomer", component: ViewCustomerSalesHistoryComponent },
  { path: "viewAppointments/:uuidCustomer", component: ViewAppointmentsComponent },
  { path: "editAppointment/:uuidAppointment", component: EditAppointmentComponent },
  { path: "addAppointment/:uuidCustomer", component: AddAppointmentComponent },
  { path: "deleteAppointment/:uuidAppointment", component: DeleteAppointmentComponent },
  { path: "viewTasks", component: ViewTasksComponent },
  { path: "editTask/:uuidTask", component: EditTaskComponent },
  { path: "addTask", component: AddTaskComponent },
  { path: "deleteTask/:uuidTask", component: DeleteTaskComponent },
  { path: "viewCustomersSuggNext", component: ViewCustomersSuggNextComponent },
  { path: "viewCustomersSuggFreq", component: ViewCustomersSuggFreqComponent },
  { path: "viewEnquiries", component: ViewEnquiriesComponent },
  { path: "viewPartners/:uuidCustomer", component: ViewPartnersComponent },
  { path: "editPartner/:uuidPartner", component: EditPartnerComponent },
  { path: "addPartner/:uuidCustomer", component: AddPartnerComponent },
  { path: "deletePartner/:uuidPartner", component: DeletePartnerComponent },
  { path: "viewCustomerContacts/:uuidCustomer", component: ViewCustomerContactsComponent },
  { path: "addCustomerContact/:uuidCustomer", component: AddCustomerContactComponent },
  { path: "editCustomerContact/:uuidCustomerContact", component: EditCustomerContactComponent },
  { path: "deleteCustomerContact/:uuidCustomerContact", component: DeleteCustomerContactComponent },
  { path: "viewDevices", component: ViewDevicesComponent },
  { path: "editDevice/:uuidDevice", component: EditDeviceComponent },
  { path: "viewMaintenances/:type", component: ViewMaintenancesComponent },
  { path: "editMaintenance/:uuidMaintenance", component: EditMaintenanceComponent },
  { path: "deleteMaintenance/:uuidMaintenance", component: DeleteMaintenanceComponent },
  { path: "addArticle", component: AddArticleComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
