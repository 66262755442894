import { SalePositionModel } from "./sale-position-model";

export class OfferPositionModel extends SalePositionModel{
  constructor(params: any = null) {
    super(params)
    this.copyFromObject(params)
  }

  static override newModel(object: any = null) {
    return new OfferPositionModel(object)
  }

}
