<div role="main">
  <div class="">
    <div class="page-title">
      <div class="title_left">
        <h3>{{ textTitle }}</h3>
      </div>
    </div>
  </div>

  <div class="clearfix"></div>

  <div class="x_panel">
    <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
      <div class="form-horizontal form-label-left">
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-date"
          >
            {{ "CONTACT_ADD_DATE" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <div class="input-group date" id="div-date">
              <input
                onkeydown="return false"
                id="input-date"
                type="text"
                class="form-control"
                placeholder="dd.mm.yyyy HH:MM"
                value="{{ textDate }}"
              />
              <span class="input-group-addon">
                <span class="glyphicon glyphicon-calendar"></span>
                <!--i class="mdi mdi-calendar icon-datepicker"></i-->
              </span>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="select-type"
            >{{ "CONTACT_ADD_TYPE" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <select
              id="select-type"
              class="form-control"
              [(ngModel)]="customerContact.uuidContactType"
            >
              <option
                *ngFor="let contactType of contactTypes"
                [ngValue]="contactType.uuid"
              >
                {{ contactType.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="textarea-subject"
          >
            {{ "CONTACT_ADD_SUBJECT" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <textarea
              class="form-control"
              rows="3"
              id="textarea-subject"
              [(ngModel)]="customerContact.subject"
            ></textarea>
          </div>
        </div>

        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="textarea-assurances"
          >
            {{ "CONTACT_ADD_ASSURANCES" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <textarea
              class="form-control"
              rows="3"
              id="textarea-assurances"
              [(ngModel)]="customerContact.assurances"
            ></textarea>
          </div>
        </div>

        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="textarea-actions"
          >
            {{ "CONTACT_ADD_ACTIONS" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <textarea
              class="form-control"
              rows="3"
              id="textarea-actions"
              [(ngModel)]="customerContact.actions"
            ></textarea>
          </div>
        </div>
      </div>
    </div>

    <div class="clearfix"></div>

    <div class="">
      <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
        <div class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"></div>
        <div class="flex-nowrap">
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <button (click)="save()" class="btn btn-primary button-row">
              <span>{{ "BUTTON_SAVE" | translate }} </span
              ><i class="mdi mdi-content-save color-bright"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
