import { BaseModel } from "./base-model"

export class TaskModel extends BaseModel {
  title: string = ""
  due: Date = new Date(0)
  uuidEmployee: string = ""
  uuidTaskCategory: string = ""
  priority: number = 0
  description: string = ""
  done: boolean = false
  dateDone: Date = new Date(0)

  constructor(params: any = null) {
    super(params)
    this.copyFromObject(params)
  }

  static override newModel(params: any = null): TaskModel {
    return new TaskModel(params)
  }
}
