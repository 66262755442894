<div role="main">
  <div class="">
    <div class="page-title">
      <div class="title_left">
        <h3>{{ "CUSTOMER_DELETE_TITLE" | translate }}</h3>
      </div>
    </div>
  </div>

  <div class="clearfix"></div>

  <div class="x_panel">
    <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
      <div class="form-horizontal form-label-left">
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="select-sex"
            >{{ "CUSTOMER_DELETE_SEX" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <select
              id="select-sex"
              class="form-control"
              [(ngModel)]="customer.uuidSex"
              name="customer.uuidSex"
              [disabled]="'disabled'"
            >
              <option *ngFor="let sex of sexes" [ngValue]="sex.uuid">
                {{ sex.formOfAddress }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-academic-title"
          >
            {{ "CUSTOMER_DELETE_ACADEMIC_TITLE" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-academic-title"
              class="form-control"
              type="text"
              [(ngModel)]="customer.academicTitle"
              name="customer.academicTitle"
              readonly
            />
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-first-name"
          >
            {{ "CUSTOMER_DELETE_FIRST_NAME" | translate }}
          </label>
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7">
            <input
              id="input-first-name"
              class="form-control"
              type="text"
              [(ngModel)]="customer.firstName"
              name="customer.firstName"
              readonly
            />
          </div>
          <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 move-down">
            <input
              type="checkbox"
              id="ceckbox-familiar"
              name="customer.familiar"
              [checked]="customer.familiar"
              (change)="customer.familiar = !customer.familiar"
              disabled="disabled"
            />
            {{ "CUSTOMER_DELETE_FAMILIAR" | translate }}
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-last-name"
          >
            {{ "CUSTOMER_DELETE_LAST_NAME" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-last-name"
              class="form-control"
              type="text"
              [(ngModel)]="customer.lastName"
              name="customer.lastName"
              readonly
            />
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-street"
          >
            {{ "CUSTOMER_DELETE_STREET" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-street"
              class="form-control"
              type="text"
              [(ngModel)]="customer.street"
              name="customer.street"
              readonly
            />
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-zip-code"
          >
            {{ "CUSTOMER_DELETE_ZIP_CODE" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-zip-code"
              class="form-control"
              type="text"
              [(ngModel)]="customer.zipCode"
              name="customer.zipCode"
              readonly
            />
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-city"
          >
            {{ "CUSTOMER_DELETE_CITY" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-city"
              class="form-control"
              type="text"
              [(ngModel)]="customer.city"
              name="customer.city"
              readonly
            />
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-telephone-1"
          >
            {{ "CUSTOMER_DELETE_TELEPHONE_1" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-telephone-1"
              class="form-control"
              type="text"
              [(ngModel)]="customer.telephone1"
              name="customer.telephone1"
              readonly
            />
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-telephone-2"
          >
            {{ "CUSTOMER_DELETE_TELEPHONE_2" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-telephone-2"
              class="form-control"
              type="text"
              [(ngModel)]="customer.telephone2"
              name="customer.telephone2"
              readonly
            />
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-fax"
          >
            {{ "CUSTOMER_DELETE_FAX" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-fax"
              class="form-control"
              type="text"
              [(ngModel)]="customer.fax"
              name="customer.fax"
              readonly
            />
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-email"
          >
            {{ "CUSTOMER_DELETE_EMAIL" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-email"
              class="form-control"
              type="text"
              [(ngModel)]="customer.email"
              name="customer.email"
              readonly
            />
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-homepage"
          >
            {{ "CUSTOMER_DELETE_HOMEPAGE" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-homepage"
              class="form-control"
              type="text"
              [(ngModel)]="customer.homepage"
              name="customer.homepage"
              readonly
            />
          </div>
        </div>
      </div>
    </div>

    <div class="clearfix"></div>

    <div class="">
      <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
        <div class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"></div>
        <div class="flex-nowrap">
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <button (click)="delete()" class="btn btn-danger button-row">
              <span>{{ "BUTTON_DELETE" | translate }} </span
              ><i class="mdi mdi-delete-outline color-bright"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
