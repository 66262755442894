import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonsService } from '../commons-service';
import { EditCustomerComponent } from '../edit-customer/edit-customer.component';
import { UUID } from 'angular2-uuid';
import { RepositoryService } from '../repository.service';
import { NetworkService } from '../network.service';
declare const log: any;

@Component({
  selector: 'app-add-customer',
  templateUrl: '../edit-customer/edit-customer.component.html',
  styleUrls: ['./add-customer.component.css'],
  providers: []
})
export class AddCustomerComponent extends EditCustomerComponent implements OnInit {

  override showLinks = false

  constructor(
    router: Router,
    route: ActivatedRoute,
    translate: TranslateService,
    commons: CommonsService,
    repository: RepositoryService,
    networkService: NetworkService) {
    super(router, route, translate, commons, repository, networkService)
  }

  override ngOnInit(): void {
    super.ngOnInit()
  }

  override async initSpecific() {
    this.translate.get("CUSTOMER_ADD_TITLE").subscribe((translated: string) => {
      this.textHeaderPage = translated
    })

    this.customer.uuidSex = this.sexes[0].uuid
    this.customer.uuid = UUID.UUID();
    this.customer.academicTitle = ""
    this.customer.firstName = ""
    this.customer.lastName = ""
    this.customer.number = ""
    this.customer.street = ""
    this.customer.zipCode = ""
    this.customer.city = ""
    this.customer.uuidCountry = this.countries[0].uuid
    this.customer.telephone1 = ""
    this.customer.telephone2 = ""
    this.customer.fax = ""
    this.customer.email = ""
    this.customer.birthday = CommonsService.parseDate("01.01.1990")
    this.customer.homepage = ""
    this.uuidsCustomerCategoriesArr = [this.uuidCustomerCategoryDefault]
    this.customer.numberUID = ""
    this.customer.remarks = ""
    this.customer.keywords = ""
    this.customer.lastContact = new Date()
    this.customer.contactFrequencyDays = 30
    this.customer.nextContact = new Date()
    this.customer.nextContact.setDate(new Date().getDate() + this.customer.contactFrequencyDays)
    this.customer.nextContactTopic = ""
    this.customer.discount = 0
    this.discountPercent = 0

    this.textDateBirthday = ""; // CommonsService.formatDate0(this.customer.birthday)
    this.textDateLastContact = CommonsService.formatDate0(this.customer.lastContact)
    this.textDateNextContact = CommonsService.formatDate0(this.customer.nextContact)
  }

  override async saveSpecific(navigateToList: boolean) {
    await this.repository.customers.create(this.customer)
    // if (navigateToList)
    //   return this.router.navigateByUrl("/viewCustomers")
    CommonsService.showSuccessMessage(this.translate.instant("GENERAL_SAVED"))
    this.router.navigateByUrl("/editCustomer/" + this.customer.uuid)
    return Promise.resolve(true)
  }
}
