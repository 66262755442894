import { Component, OnInit } from '@angular/core';
import { CommonsService } from 'src/app/commons-service';
import { Router } from '@angular/router';
import { DeviceForViewModel } from 'src/app/models/device-for-view-model';
import { DeviceModel } from 'src/app/models/device-model';
import { RepositoryService } from 'src/app/repository.service';
import { environment } from 'src/environments/environment';
import { UUID } from 'angular2-uuid';
declare const log: any;

@Component({
  selector: 'app-view-devices',
  templateUrl: './view-devices.component.html',
  styleUrls: ['./view-devices.component.css']
})
export class ViewDevicesComponent implements OnInit {

  environment = environment
  textSearch = ""
  currentPage: number = 0
  devices: DeviceModel[] = []
  pageSize = environment.entriesPerPage;

  constructor(
    protected router: Router,
    private repository: RepositoryService
  ) { }

  ngOnInit(): void {
    this.init()
  }

  async init() {
    this.textSearch = await this.repository.readUtilityData("searchDevicesText")
    this.currentPage = +await this.repository.readUtilityData("showDevicesPage")
    this.search();
  }

  private keyTimeout: any = null;

  keyUpEvent(event: any) {
    if (event.key.length != 1 && event.key != "Backspace") {
      event.preventDefault()
      return false;
    }

    if (this.keyTimeout) {
      clearTimeout(this.keyTimeout);
      this.keyTimeout = null;
    }

    this.keyTimeout = setTimeout(() => {
      this.search();
    }, 300)

    return true
  }

  async search() {
    return this.loadDevicesSearch().then(async () => {
      if (this.currentPage > this.numberOfPages() - 1) {
        this.currentPage = this.numberOfPages() - 1;
        this.storeCurrentPage();
      }

      await this.repository.updateUtilityData("searchDevicesText", this.textSearch)
    })
  }

  numberOfPages() {
    if (this.devices && this.devices.length > 0)
      return Math.ceil(this.devices.length / this.pageSize);
    else
      return 1;
  }

  async loadDevicesSearch() {
    let customers = await this.repository.customers.readAll("name", true)

    var textSearchLC = "";
    if (this.textSearch && typeof this.textSearch != 'undefined' && this.textSearch != "")
      textSearchLC = this.textSearch.toLowerCase();

    let devicesL = <DeviceModel[]>await this.repository.devices.readAll("name", true)

    let devicesLV: DeviceForViewModel[] = []
    for (let deviceL of devicesL) {
      let deviceLV = new DeviceForViewModel(deviceL)
      let customer = CommonsService.getObjectByUuid(customers, deviceL.uuidCustomer)
      if (customer) {
        deviceLV.nameCustomer = customer.firstName + " " + customer.lastName;
        deviceLV.enableButtonCustomer = true;
      } else {
        deviceLV.nameCustomer = "";
        deviceLV.enableButtonCustomer = false;
      }
      devicesLV.push(deviceLV)
    }

    devicesLV = devicesLV.filter((device: any) => {
      if (textSearchLC != "") {
        if ((device.name.toLowerCase().indexOf(textSearchLC) >= 0) ||
          (device.serialNumber.toLowerCase().indexOf(textSearchLC) >= 0) ||
          (device.nameCustomer.toLowerCase().indexOf(textSearchLC) >= 0) ||
          (device.city.toLowerCase().indexOf(textSearchLC) >= 0) ||
          (device.zipCode.toLowerCase().indexOf(textSearchLC) >= 0))
          return true
        else
          return false
      } else
        return true
    })
    this.devices = devicesLV;
  }

  storeCurrentPage() {
    this.repository.updateUtilityData("showDevicesPage", "" + this.currentPage);
  }

  async addDevice() {
    let newDevice = new DeviceModel()
    newDevice.uuid = UUID.UUID()

    await this.repository.devices.create(newDevice)

    this.router.navigateByUrl("/editDevice/" + newDevice.uuid)
  }
}
