import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NavigationBarComponent } from './navigation-bar/navigation-bar.component';
import { StartComponent } from './start/start.component';
import { CapitalizePipe } from './capitalize.pipe';
import { LimitFromToPipe } from './limit-from-to.pipe';
import { ViewCustomersComponent } from './view-customers/view-customers.component';
import { ViewCustomersSelectComponent } from './view-customers-select/view-customers-select.component';
import { EditCustomerComponent } from './edit-customer/edit-customer.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AddCustomerComponent } from './add-customer/add-customer.component';
import { ViewArticlesComponent } from './view-articles/view-articles.component';
import { ViewArticlesSelectComponent } from './view-articles-select/view-articles-select.component';
import { ViewOpenSalesComponent } from './view-open-sales/view-open-sales.component';
import { CanDeactivateStartGuard } from './can-deactivate-start';
import { EditSaleComponent } from './edit-sale/edit-sale.component';
import { EditSalePositionComponent } from './edit-sale-position/edit-sale-position.component';
import { DeleteSalePositionComponent } from './delete-sale-position/delete-sale-position.component';
import { PrintSaleComponent } from './print-sale/print-sale.component';
import { ViewSalesComponent } from './view-sales/view-sales.component';
import { DeleteSaleComponent } from './delete-sale/delete-sale.component';
import { ViewCustomerSalesHistoryComponent } from './view-customer-sales-history/view-customer-sales-history.component';
import { ViewArticleComponent } from './view-article/view-article.component';
import { ViewAppointmentsComponent } from './view-appointments/view-appointments.component';
import { EditAppointmentComponent } from './edit-appointment/edit-appointment.component';
import { AddAppointmentComponent } from './add-appointment/add-appointment.component';
import { DeleteAppointmentComponent } from './delete-appointment/delete-appointment.component';
import { ViewTasksComponent } from './view-tasks/view-tasks.component';
import { EditTaskComponent } from './edit-task/edit-task.component';
import { AddTaskComponent } from './add-task/add-task.component';
import { DeleteTaskComponent } from './delete-task/delete-task.component';
import { ViewCustomersSuggNextComponent } from './view-customers-sugg-next/view-customers-sugg-next.component';
import { ViewCustomersSuggFreqComponent } from './view-customers-sugg-freq/view-customers-sugg-freq.component';
import { ViewEnquiriesComponent } from './view-enquiries/view-enquiries.component';
import { ViewPartnersComponent } from './view-partners/view-partners.component';
import { EditPartnerComponent } from './edit-partner/edit-partner.component';
import { AddPartnerComponent } from './add-partner/add-partner.component';
import { DeletePartnerComponent } from './delete-partner/delete-partner.component';
import { ViewCustomerContactsComponent } from './view-customer-contacts/view-customer-contacts.component';
import { AddCustomerContactComponent } from './add-customer-contact/add-customer-contact.component';
import { EditCustomerContactComponent } from './edit-customer-contact/edit-customer-contact.component';
import { ViewOpenSalesSelectComponent } from './view-open-sales-select/view-open-sales-select.component';
import { DeleteCustomerContactComponent } from './delete-customer-contact/delete-customer-contact.component';
import { DeleteCustomerComponent } from './delete-customer/delete-customer.component';
import { ErrorHandlerService } from './error-handler.service';
import { ViewDevicesComponent } from './view-devices/view-devices.component';
import { EditDeviceComponent } from './edit-device/edit-device.component';
import { EditMaintenanceComponent } from './edit-maintenance/edit-maintenance.component';
import { WebcamModule } from 'ngx-webcam';
import { ViewMaintenancesComponent } from './view-maintenances/view-maintenances.component';
import { EditHavingImagesComponent } from './edit-having-images/edit-having-images.component';
import { DeleteMaintenanceComponent } from './delete-maintenance/delete-maintenance.component';
import { AddArticleComponent } from './add-article/add-article.component';
import { DeleteArticleComponent } from './delete-article/delete-article.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NavigationBarComponent,
    StartComponent,
    CapitalizePipe,
    LimitFromToPipe,
    ViewCustomersComponent,
    ViewCustomersSelectComponent,
    EditCustomerComponent,
    AddCustomerComponent,
    ViewArticlesComponent,
    ViewArticlesSelectComponent,
    ViewOpenSalesComponent,
    EditSaleComponent,
    EditSalePositionComponent,
    DeleteSalePositionComponent,
    PrintSaleComponent,
    ViewSalesComponent,
    DeleteSaleComponent,
    ViewCustomerSalesHistoryComponent,
    ViewArticleComponent,
    ViewAppointmentsComponent,
    EditAppointmentComponent,
    AddAppointmentComponent,
    DeleteAppointmentComponent,
    ViewTasksComponent,
    EditTaskComponent,
    AddTaskComponent,
    DeleteTaskComponent,
    ViewCustomersSuggNextComponent,
    ViewCustomersSuggFreqComponent,
    ViewEnquiriesComponent,
    ViewPartnersComponent,
    EditPartnerComponent,
    AddPartnerComponent,
    DeletePartnerComponent,
    ViewCustomerContactsComponent,
    AddCustomerContactComponent,
    EditCustomerContactComponent,
    ViewOpenSalesSelectComponent,
    DeleteCustomerContactComponent,
    DeleteCustomerComponent,
    ViewDevicesComponent,
    EditDeviceComponent,
    ViewMaintenancesComponent,
    EditMaintenanceComponent,
    EditHavingImagesComponent,
    DeleteMaintenanceComponent,
    AddArticleComponent,
    DeleteArticleComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    WebcamModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [CanDeactivateStartGuard, { provide: ErrorHandler, useClass: ErrorHandlerService }],
  bootstrap: [AppComponent]
})
export class AppModule { }
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
