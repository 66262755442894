import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { CommonsService } from '../commons-service';
import { ParametersStartService } from '../parameters-start.service'
import { UUID } from 'angular2-uuid';
import { RepositoryService } from '../repository.service';
import { SaleModel } from '../models/sale-model';
import { OfferModel } from '../models/offer-model';
import { OrderModel } from '../models/order-model';
import { InvoiceModel } from '../models/invoice-model';
import { PaymentTypeModel } from '../models/payment-type-model';
import { CustomerModel } from '../models/customer-model';
import { StateOrderModel } from '../models/state-order-model';
import { CancellationReasonModel } from '../models/cancellation-reason-model';
declare const $: any;
declare const log: any;

@Component({
  selector: 'app-view-sales',
  templateUrl: './view-sales.component.html',
  styleUrls: ['./view-sales.component.css']
})
export class ViewSalesComponent implements OnInit {

  typeSales = "Offer"
  uuidCustomer = ""

  textTitle = ""

  customer: CustomerModel = new CustomerModel()
  sales: SaleModel[] = [];
  paymentTypes: PaymentTypeModel[] = []

  currentPage = 0
  pageSize = environment.entriesPerPage;

  constructor(
    protected router: Router,
    private route: ActivatedRoute,
    protected translate: TranslateService,
    protected repository: RepositoryService,
    private parametersStart: ParametersStartService
  ) { }

  ngOnInit() {
    this.init()
  }

  async init() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    const routeParams = this.route.snapshot.paramMap;
    this.typeSales = String(routeParams.get('type'));
    this.uuidCustomer = String(routeParams.get('uuidCustomer'));

    if (this.typeSales == "Offer") {
      this.sales = await this.repository.readOffers(this.uuidCustomer, "date", false)
      this.translate.get("SALES_TITLE_OFFER").subscribe((translated: string) => {
        this.textTitle = translated
      })
    } else if (this.typeSales == "Order") {
      this.sales = await this.repository.readOrders(this.uuidCustomer, "date", false)
      this.translate.get("SALES_TITLE_ORDER").subscribe((translated: string) => {
        this.textTitle = translated
      })
    } else if (this.typeSales == "Invoice") {
      this.sales = await this.repository.readInvoices(this.uuidCustomer, "date", false)
      this.translate.get("SALES_TITLE_INVOICE").subscribe((translated: string) => {
        this.textTitle = translated
      })
    } else
      throw Error("unknown type of sales " + this.typeSales)

    await this.repository.updateUtilityData("locationOriginSales", "viewSales/" + this.typeSales + "/" + this.uuidCustomer)

    this.customer = <CustomerModel>await this.repository.customers.read(this.uuidCustomer)

    this.paymentTypes = <PaymentTypeModel[]>await this.repository.paymentTypes.readAll()
  }

  clickedOnSale(sale: any) {
    this.router.navigateByUrl("/editSale/" + this.typeSales + "/" + sale.uuid + "/false")
  }

  async addSale() {
    let sale: SaleModel = new SaleModel()
    if (this.typeSales == "Offer")
      sale = new OfferModel()
    else if (this.typeSales == "Order")
      sale = new OrderModel()
    else if (this.typeSales == "Invoice")
      sale = new InvoiceModel()

    sale.uuid = UUID.UUID()

    let cancellationReasons = <CancellationReasonModel[]>await this.repository.cancellationReasons.readAll()
    sale.uuidCancellationReason = cancellationReasons[0].uuid

    sale.uuidCustomer = this.customer.uuid

    if (this.customer.firstName)
      sale.name = this.customer.firstName + " " + this.customer.lastName;
    else
      sale.name = this.customer.lastName;
    sale.date = new Date();
    sale.number = '';
    sale.street = this.customer.street;
    sale.zipCode = this.customer.zipCode;
    sale.city = this.customer.city;
    sale.uuidCountry = this.customer.uuidCountry
    sale.discount = 0.0;
    sale.sumNet = '0';
    sale.sumGross = '0';
    sale.taxExempt = this.customer.taxExempt;

    if (this.typeSales == "Order") {
      let statesOrder: StateOrderModel[] = <StateOrderModel[]>await this.repository.statesOrder.readAll();
      (<OrderModel>sale).uuidStateOrder = statesOrder[0].uuid
    }

    sale.costPriceTotalV = 0.0;

    sale.uuidPaymentType = this.customer.uuidPaymentType;
    var paymentType: PaymentTypeModel = CommonsService.getObjectByUuid(this.paymentTypes, sale.uuidPaymentType);
    if (paymentType)
      sale.uuidPaymentType = paymentType.uuid;
    else {
      sale.uuidPaymentType = this.paymentTypes[0].uuid
    }

    sale.uuidsSalePositions = ""

    if (this.typeSales == "Offer")
      await this.repository.createOffer(<OfferModel>sale)
    else if (this.typeSales == "Order")
      await this.repository.createOrder(<OrderModel>sale)
    else if (this.typeSales == "Invoice")
      await this.repository.createInvoice(<InvoiceModel>sale)

    CommonsService.showSuccessMessage(this.translate.instant("SALES_CREATED_SALE"))

    this.router.navigateByUrl("editSale/" + this.typeSales + "/" + sale.uuid + "/true")
  }

  numberOfPages() {
    if (this.sales && this.sales.length > 0)
      return Math.ceil(this.sales.length / this.pageSize);
    else
      return 1;
  }

  storeCurrentPage() {
    // no page memorization
  }
}
