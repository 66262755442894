import { CommonsService } from "./commons-service"
import { DataNotAvailableError } from "./data-not-available-error"
import { DbService } from "./db.service"
import { DeviceModel } from "./models/device-model"
import { EmployeeModel } from "./models/employee-model"
import { ModelBuilder } from "./models/model-builder"
import { ModelFactory } from "./models/model-factory"
import { ModelInterface } from "./models/model-interface"
import { NetworkService } from "./network.service"
import { ParametersStartService } from "./parameters-start.service"
declare const log: any;

export class BaseModelRepository<T extends ModelInterface> {

  constructor(
    private clazz: ModelBuilder<T>,
    private nameModel: string,
    private networkService: NetworkService,
    private dbService: DbService,
    private modelFactory: ModelFactory<ModelInterface>,
    private parametersStart: ParametersStartService) {
  }

  async create(model: T) {
    let reachedServer = await this.networkService.createTableEntry(this.nameModel, model)
    await this.dbService.saveNewObjectLocally(model, this.nameModel)

    if (!reachedServer)
      await this.dbService.saveNewModelManipulation(model, this.nameModel)
  }

  async readAll(sortColumn: string = "", sortAscending: boolean = true): Promise<T[]> {
    let models: T[] = []
    let modelsFromServer = await this.networkService.readTable(this.nameModel)

    if (modelsFromServer) {
      if (this.nameModel == "Projektzeiterfassung")
        modelsFromServer = modelsFromServer.filter((model: any) =>
          (typeof model.uuidOrder != "undefined" && model.uuidOrder != null && model.uuidOrder != "") ||
          (typeof model.uuidMaintenance != "undefined" && model.uuidMaintenance != null && model.uuidMaintenance != ""))

      models = <T[]>this.modelFactory.newModels(this.clazz, modelsFromServer)
      this.dbService.updateTable(this.nameModel, models) // no await! just initiate write
    } else {
      let columnDefinitionActive = CommonsService.getModelColumnDefinitionByNameLocal(this.nameModel, "active")
      let objs: any[] = []
      if (typeof columnDefinitionActive == "undefined") {
        objs = await this.dbService.loadTable(this.nameModel)
      } else {
        objs = await this.dbService.loadTableWithActiveFlag(this.nameModel)
      }
      models = <T[]>this.modelFactory.newModels(this.clazz, objs)
    }

    for (let model of models) {
      CommonsService.setPrices(model, this.parametersStart.currency)
    }

    if (sortColumn && sortColumn != "")
      CommonsService.sort(models, sortColumn, sortAscending)
    else if (this.nameModel == "Land") {
      models.sort((a: any, b: any) => {
        if (a.name == b.name)
          return 0
        if (a.name == null)
          return -1
        if (b.name == null)
          return 1
        if (a.name == "Österreich")
          return -1
        if (b.name == "Österreich")
          return 1
        if (a.name == "Deutschland")
          return -1
        if (b.name == "Deutschland")
          return 1
        if (a.name == "Schweiz")
          return -1
        if (b.name == "Schweiz")
          return 1
        if (a.name > b.name)
          return 1;
        if (a.name < b.name)
          return -1;
        return 0
      })
    } else if (this.nameModel == "Mitarbeiter") {
      models.sort((a: any, b: any) => {
        return a.fullName().toLowerCase().localeCompare(b.fullName().toLowerCase());
      });
    } else if (this.nameModel == "Gerät") {
      models.sort((a: any, b: any) => {
        return a.getSerialNumberAndName().toLowerCase().localeCompare(b.getSerialNumberAndName().toLowerCase());
      });
    }

    return Promise.resolve(models)
  }

  async read(uuid: string, nullAllowed = false): Promise<T | null> {
    let model = <T>this.modelFactory.newModel(this.clazz)
    let modelFromServer = await this.networkService.readTableEntry(this.nameModel, uuid)

    if (modelFromServer) {
      model = <T>this.modelFactory.newModel(this.clazz, modelFromServer)
      this.dbService.updateEditedModelByUuid(model, this.nameModel) // no await! just initiate write
    } else {
      let obj = await this.dbService.loadTableEntryByUuid(this.nameModel, uuid)
      if (!nullAllowed && !obj)
        throw new DataNotAvailableError("Data not available - " + this.nameModel + " " + uuid)
      model = <T>(obj ? this.modelFactory.newModel(this.clazz, obj) : null)
    }

    return Promise.resolve(model)
  }

  async update(modelBeforeEdit: T, model: T): Promise<boolean> {
    let reachedServer = await this.networkService.updateTableEntry(this.nameModel, model)
    await this.dbService.updateEditedModelByUuid(model, this.nameModel)

    if (!reachedServer)
      await this.dbService.saveEditedModelManipulation(modelBeforeEdit, model, this.nameModel)

    return Promise.resolve(reachedServer)
  }

  async delete(uuid: string) {
    let reachedServer = await this.networkService.deleteTableEntry(this.nameModel, uuid)
    await this.dbService.deleteTableEntryByUuid(this.nameModel, uuid)

    if (!reachedServer)
      await this.dbService.saveDeleteModelManipulations(this.nameModel, uuid);
  }
}
