import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ParametersStartService } from '../parameters-start.service';
import { RepositoryService } from '../repository.service';
import { SalePositionModel } from '../models/sale-position-model';
import { SaleModel } from '../models/sale-model';
import { OfferModel } from '../models/offer-model';
import { OrderModel } from '../models/order-model';
import { InvoiceModel } from '../models/invoice-model';
import { ModelFactory } from '../models/model-factory';
import { ModelInterface } from '../models/model-interface';
declare const log: any;

@Component({
  selector: 'app-delete-sale-position',
  templateUrl: './delete-sale-position.component.html',
  styleUrls: ['./delete-sale-position.component.css']
})
export class DeleteSalePositionComponent implements OnInit {

  typeSale = "Offer"
  uuidSalePosition = "";
  isSaleNew = false

  salePosition: SalePositionModel = new SalePositionModel()
  discount = "0"

  nameModel = "Angebot"
  nameModelPosition = "Angebotspositionen"

  constructor(
    protected router: Router,
    private route: ActivatedRoute,
    protected translate: TranslateService,
    protected modelFactory: ModelFactory<ModelInterface>,
    private repository: RepositoryService,
    private parametersStart: ParametersStartService
  ) {
  }

  ngOnInit(): void {
    this.init()
  }

  async init() {
    const routeParams = this.route.snapshot.paramMap;
    this.typeSale = String(routeParams.get('type'));
    this.uuidSalePosition = String(routeParams.get('uuidSalePosition'));
    this.isSaleNew = (routeParams.get('isSaleNew')) == "true";

    if (this.typeSale === "Offer") {
      this.nameModel = "Angebot";
      this.nameModelPosition = "Angebotspositionen";
      this.salePosition = await this.repository.readOfferPosition(this.uuidSalePosition)
    } else if (this.typeSale === "Order") {
      this.nameModel = "Auftrag";
      this.nameModelPosition = "Auftragspositionen";
      this.salePosition = await this.repository.readOrderPosition(this.uuidSalePosition)
    } else if (this.typeSale === "Invoice") {
      this.nameModel = "Rechnung";
      this.nameModelPosition = "Rechnungspositionen";
      this.salePosition = await this.repository.readInvoicePosition(this.uuidSalePosition)
    } else
      throw new Error("Unknown type sale " + this.typeSale)

    this.discount = "" + 100.0 * this.salePosition.discount; // convert into percent
  }

  async delete() {
    // update sale
    let sale = new SaleModel()
    if (this.typeSale == "Offer") {
      sale = await this.repository.readOffer(this.salePosition.uuidParent)
    } else if (this.typeSale == "Order") {
      sale = await this.repository.readOrder(this.salePosition.uuidParent)
    } else if (this.typeSale == "Invoice") {
      sale = await this.repository.readInvoice(this.salePosition.uuidParent)
    }

    let saleBeforeDelete = sale.clone(this.modelFactory)
    sale.removePosition(this.salePosition.uuid, this.parametersStart.currency)

    if (this.typeSale == "Offer")
      await this.repository.updateOffer(<OfferModel>saleBeforeDelete, <OfferModel>sale)
    else if (this.typeSale == "Order")
      await this.repository.updateOrder(<OrderModel>saleBeforeDelete, <OrderModel>sale)
    else if (this.typeSale == "Invoice")
      await this.repository.updateInvoice(<InvoiceModel>saleBeforeDelete, <InvoiceModel>sale)

    this.router.navigateByUrl("/editSale/" + this.typeSale + "/" + this.salePosition.uuidParent + "/" + this.isSaleNew)
  }

}
