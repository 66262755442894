<div role="main" class="print-page-header">
  <div class="x_panel">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <button onClick="print()" class="btn btn-default button-row">
        <span>{{ "SALE_PRINT_BUTTON_PRINT" | translate }}</span
        ><i class="mdi mdi-printer color-main"></i>
      </button>
      <button (click)="goBack()" class="btn btn-default button-row">
        {{ "SALE_PRINT_BUTTON_BACK" | translate }}
      </button>
    </div>
  </div>
</div>

<form class="print-page-body">
  <STYLE TYPE="text/css">
    .button{ float: right; } .navbar { display: none; } body
    {margin:13;padding:13} .line { line-height: 15pt; margin: 0cm; } .font10 {
    font-family: Verdana,Geneva,sans-serif; font-size: 10pt; } .font8 {
    font-family: Verdana,Geneva,sans-serif; font-size: 8pt; } .font14 {
    font-family: Verdana,Geneva,sans-serif; font-size: 14pt; font-weight: bold;
    } .bold { font-weight: bold; } .right { text-align: right; } table{
    table-layout: fixed; width:643; } .pageWidthTotal { width:643; } .table1col1
    { width:414; } .table1col2 { width:132; } .table1col3 { width:97; }
    .table2col1 { width:321; } .table2col2 { width:322; }
  </STYLE>
  <TABLE
    WIDTH="643"
    CLASS="pageWidthTotal"
    BORDER="0"
    CELLPADDING="0"
    CELLSPACING="0"
  >
    <COL WIDTH="450" />
    <COL WIDTH="193" />

    <TR VALIGN="TOP" HEIGHT="5">
      <TD CLASS="table1col1" HEIGHT="5">
        <DIV CLASS="line font14">{{ company.name }}</DIV>
      </TD>
      <TD CLASS="table1col2" HEIGHT="5" HALIGN="right"> </TD>
    </TR>
    <TR VALIGN="TOP" HEIGHT="3">
      <TD CLASS="table1col1" HEIGHT="5">
        <DIV CLASS="line font8">{{ company.street }}</DIV>
      </TD>
      <TD CLASS="table1col2" HEIGHT="5"> </TD>
    </TR>
    <TR VALIGN="TOP" HEIGHT="5">
      <TD CLASS="table1col1" HEIGHT="5">
        <DIV CLASS="line font8">{{ company.zipCode }} {{ company.city }}</DIV>
      </TD>
      <TD CLASS="table1col2" HEIGHT="5"> </TD>
    </TR>
    <TR VALIGN="TOP" HEIGHT="5">
      <TD CLASS="table1col1" HEIGHT="5">
        <DIV CLASS="line font8">Tel: {{ company.telephone }}</DIV>
      </TD>
      <TD CLASS="table1col2" HEIGHT="5"> </TD>
    </TR>
    <TR VALIGN="TOP" HEIGHT="5">
      <TD CLASS="table1col1" HEIGHT="5">
        <DIV CLASS="line font8">Fax: {{ company.fax }}</DIV>
      </TD>
      <TD CLASS="table1col2" HEIGHT="5"> </TD>
    </TR>
    <TR VALIGN="TOP" HEIGHT="5">
      <TD CLASS="table1col1" HEIGHT="5">
        <DIV CLASS="line font8"
          >email:
          <A href="mailto:{{ company.email }}" CLASS="line font10"
            >{{ company.email }}
          </A>
        </DIV>
      </TD>
      <TD CLASS="table1col2" HEIGHT="5"> </TD>
    </TR>
    <TR VALIGN="TOP">
      <TD CLASS="table1col1" HEIGHT="5">
        <DIV CLASS="line font8"
          >www:
          <A href="{{ company.homepage }}" CLASS="line font10"
            >{{ company.homepage }}
          </A>
        </DIV>
      </TD>
      <TD CLASS="table1col2" HEIGHT="5"> </TD>
    </TR>
  </TABLE>

  <canvas id="canvas1" width="643" height="1" align="left"></canvas>
  <script>
    var canvas = document.getElementById("canvas1");
    var context = canvas.getContext("2d");

    context.beginPath();
    context.moveTo(0, 0);
    context.lineTo(643, 0);
    context.stroke();
  </script>

  <P CLASS="line"><BR /> </P>
  <TABLE
    WIDTH="643"
    CLASS="pageWidthTotal"
    BORDER="0"
    CELLPADDING="0"
    CELLSPACING="0"
  >
    <COL WIDTH="414" />
    <COL WIDTH="132" />
    <COL WIDTH="97" />

    <TR VALIGN="TOP">
      <TD CLASS="table1col1" HEIGHT="5">
        <P CLASS="line font8"
          ><U
            >{{ company.name }}, {{ company.street }}, {{ company.zipCode }}
            {{ company.city }}</U
          ></P
        >
      </TD>
      <TD CLASS="table1col2" HEIGHT="5" HALIGN="right">
        <P CLASS="line font8">{{ "SALE_PRINT_CUSTOMER" | translate }}</P>
      </TD>
      <TD CLASS="table1col3" HEIGHT="5">
        <P CLASS="line font8" ALIGN="RIGHT">{{ customer.number }}</P>
      </TD>
    </TR>
    <TR VALIGN="TOP">
      <TD CLASS="table1col1" HEIGHT="5">
        <P><BR /> </P>
      </TD>
      <TD CLASS="table1col2" HEIGHT="5" HALIGN="right">
        <P CLASS="line font8">{{ "SALE_PRINT_YOUR_UID" | translate }}</P>
      </TD>
      <TD CLASS="table1col3" HEIGHT="5">
        <P CLASS="line font8" ALIGN="RIGHT">{{ customer.numberUID }}</P>
      </TD>
    </TR>
    <!-- <TR VALIGN="TOP">
      <TD CLASS="table1col1" HEIGHT="5">
        <P><BR /> </P>
      </TD>
      <TD CLASS="table1col2" HEIGHT="5">
        <P CLASS="font8">{{ "SALE_PRINT_YOUR_UID" | translate }}</P>
      </TD>
      <TD CLASS="table1col3" HEIGHT="5">
        <P CLASS="font8 right">{{ customer.numberUID }}</P>
      </TD>
    </TR> -->
  </TABLE>

  <P CLASS="line font10">{{ sale.fullName() }}</P>
  <P CLASS="line font10">{{ sale.street }}</P>
  <P CLASS="line font10">{{ sale.zipCode }} {{ sale.city }}</P>
  <P CLASS="line font10">{{ textCountry }}</P>
  <P CLASS="line font10"><BR /></P>
  <P CLASS="line font10"><BR /></P>
  <P CLASS="line font10"><BR /></P>

  <TABLE WIDTH="643" BORDER="0" CELLPADDING="0" CELLSPACING="0">
    <TR VALIGN="TOP">
      <TD CLASS="table2col1">
        <P CLASS="line font14">{{ textTitle }} {{ sale.number }}</P>
      </TD>
      <TD CLASS="table2col2">
        <P CLASS="line font10" ALIGN="RIGHT"
          >{{ company.city }}, {{ sale.date | date: "dd.MM.yyyy" }}</P
        >
      </TD>
    </TR>
  </TABLE>
  <P CLASS="line"><BR /> </P>
  <P CLASS="line"><BR /> </P>
  <P CLASS="line font10">{{ "SALE_PRINT_FORM_OF_ADDRESS" | translate }}</P>
  <P CLASS="line font10"><BR /></P>
  <P CLASS="line font10"><BR /> </P>
  <TABLE
    WIDTH="643"
    BORDER="0"
    BORDERCOLOR="#000000"
    CELLPADDING="4"
    CELLSPACING="0"
    FRAME="NONE"
    RULES="NONE"
  >
    <COL WIDTH="29" />
    <COL WIDTH="64" />
    <COL WIDTH="183" />
    <COL WIDTH="63" />
    <COL WIDTH="98" />
    <COL WIDTH="48" />
    <COL WIDTH="102" />
    <TR VALIGN="TOP">
      <TH WIDTH="29">
        <P CLASS="line font10">{{ "SALE_PRINT_TABLE_POS" | translate }}</P>
      </TH>
      <TH WIDTH="64">
        <P CLASS="line font10">{{
          "SALE_PRINT_TABLE_ARTICLE_NUM" | translate
        }}</P>
      </TH>
      <TH WIDTH="183">
        <P CLASS="line font10">{{
          "SALE_PRINT_TABLE_ARTICLE_NAME" | translate
        }}</P>
      </TH>
      <TH WIDTH="63">
        <P CLASS="line font10" ALIGN="RIGHT">{{
          "SALE_PRINT_TABLE_AMOUNT" | translate
        }}</P>
      </TH>
      <TH WIDTH="98">
        <P CLASS="line font10" ALIGN="RIGHT">{{
          "SALE_PRINT_TABLE_PRICE_UNIT" | translate
        }}</P>
      </TH>
      <TH WIDTH="48">
        <P CLASS="line font10" ALIGN="RIGHT">{{
          "SALE_PRINT_TABLE_DISCOUNT" | translate
        }}</P>
      </TH>
      <TH WIDTH="102">
        <P CLASS="line font10" ALIGN="RIGHT">{{
          "SALE_PRINT_TABLE_PRICE_TOTAL" | translate
        }}</P>
      </TH>
    </TR>

    <TR *ngFor="let position of sale.positions; let i = index" VALIGN="TOP">
      <TD WIDTH="29">
        <P CLASS="font10">{{ i + 1 }}</P>
      </TD>
      <TD WIDTH="64">
        <P CLASS="font10">{{ position.number }}</P>
      </TD>
      <TD WIDTH="183">
        <P CLASS="font10">{{ position.name }}</P>
      </TD>
      <TD WIDTH="63">
        <P CLASS="font10" ALIGN="RIGHT">{{ position.amount }}</P>
      </TD>
      <TD WIDTH="98">
        <P CLASS="font10" ALIGN="RIGHT">{{ position.priceUnitNet }}</P>
      </TD>
      <TD WIDTH="48">
        <P CLASS="font10" ALIGN="RIGHT">{{ 100.0 * position.discount }}%</P>
      </TD>
      <TD WIDTH="102">
        <P CLASS="font10" ALIGN="RIGHT">{{ position.priceSumNet }}</P>
      </TD>
    </TR>
  </TABLE>
  <HR
    CLASS="line"
    WIDTH="643"
    ALIGN="left"
    STYLE="border-top: double"
    COLOR="#000000"
  />
  <TABLE WIDTH="643" BORDER="0" CELLPADDING="4" CELLSPACING="0">
    <COL WIDTH="264" />
    <COL WIDTH="201" />
    <COL WIDTH="154" />
    <TR VALIGN="TOP">
      <TD WIDTH="264">
        <P><BR /> </P>
      </TD>
      <TD WIDTH="201">
        <P CLASS="font10 bold line">{{ "SALE_PRINT_SUM_NET" | translate }}</P>
      </TD>
      <TD WIDTH="154">
        <P CLASS="font10 bold line" ALIGN="RIGHT">{{
          sale.sumNetBeforeDiscount
        }}</P>
      </TD>
    </TR>
    <TR ng-show="sale.discount != 0" VALIGN="TOP">
      <TD WIDTH="264">
        <P><BR /> </P>
      </TD>
      <TD WIDTH="201">
        <P CLASS="font10 line">-{{ 100 * sale.discount }}% {{ "SALE_PRINT_DISCOUNT" | translate }}</P>
      </TD>
      <TD WIDTH="154">
        <P CLASS="font10 line" ALIGN="RIGHT">{{ sale.sumNetDiscount }}</P>
      </TD>
    </TR>
    <TR ng-show="sale.discount != 0" VALIGN="TOP">
      <TD WIDTH="264">
        <P><BR /> </P>
      </TD>
      <TD WIDTH="201">
        <P CLASS="font10 bold line">{{ "SALE_PRINT_TOTAL_NET" | translate }}</P>
      </TD>
      <TD WIDTH="154">
        <P CLASS="font10 bold line" ALIGN="RIGHT">{{ sale.sumNet }}</P>
      </TD>
    </TR>
    <TR [ngClass]="sale.taxExempt ? 'hidden' : ''" *ngFor="let item of mapNetVTaxVByTaxFactor | keyvalue" VALIGN="TOP">
      <TD WIDTH="264">
        <P><BR /> </P>
      </TD>
      <TD WIDTH="201">
        <P CLASS="font10 line"
          >+{{ item.key }}% {{ "SALE_PRINT_TAX_FROM" | translate }}
          {{ item.value.net }}</P
        >
      </TD>
      <TD WIDTH="154">
        <P CLASS="font10 line" ALIGN="RIGHT">{{ item.value.tax }}</P>
      </TD>
    </TR>
    <TR VALIGN="TOP">
      <TD WIDTH="264">
        <P><BR /> </P>
      </TD>
      <TD WIDTH="201">
        <P CLASS="font10 bold">{{ "SALE_PRINT_SUM_GROSS" | translate }}</P>
      </TD>
      <TD WIDTH="154">
        <P CLASS="font10 bold" ALIGN="RIGHT">{{ sale.sumGross }}</P>
      </TD>
    </TR>
  </TABLE>

  <P CLASS="line font10"><BR /> </P>
  <P CLASS="line font10"><BR /> </P>
  <P CLASS="line font10"><BR /> </P>
  <P CLASS="line font10">{{ "SALE_PRINT_PAYABLE" | translate }}</P>
  <P CLASS="line font10"
    >{{ "SALE_PRINT_TYPE_PAYMENT" | translate }} {{ textPaymentType }}</P
  >
  <P CLASS="line font10"><BR /> </P>
  <P CLASS="line font10">{{ "SALE_PRINT_GREETINGS" | translate }}</P>
  <P CLASS="line font10"><BR /> </P>
  <img
    [hidden]="!showSignatureImage"
    id="signatureImage-print"
    alt="Embedded Image"
    src="{{ sale.signatureImage }}"
    width="360"
    height="150"
  />
</form>

<div role="main" class="print-page-footer">
  <div class="x_panel">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <button onClick="print()" class="btn btn-default button-row">
        <span>{{ "SALE_PRINT_BUTTON_PRINT" | translate }}</span
        ><i class="mdi mdi-printer color-main"></i>
      </button>
      <button (click)="goBack()" class="btn btn-default button-row">
        {{ "SALE_PRINT_BUTTON_BACK" | translate }}
      </button>
    </div>
  </div>
</div>
