import { BaseModel } from "./base-model"

export class EmployeeModel extends BaseModel {
  active: boolean = true
  lastName: string = ""
  title: string = ""
  firstName: string = ""
  number: string = ""
  socialSecurityNumber: string = ""

  constructor(params: any = null) {
    super(params)
    this.copyFromObject(params)
  }

  fullName() {
    return (this.firstName + " " + this.lastName).trim();
  }

  static override newModel(object: any = null) {
    return new EmployeeModel(object)
  }
}
