<div role="main">
  <div class="">
    <div class="page-title">
      <div class="title_left">
        <h3>{{ "START_TITLE" | translate }}</h3>
      </div>
    </div>
  </div>

  <div class="clearfix"></div>

  <div class="x_panel">
    <div
      [hidden]="parametersStart.buttonLoginLogoutText == ''"
      class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
      style="margin-bottom: 8px"
    >
      <button
        class="btn btn-default"
        type="button"
        (click)="gotoLoginOrDoLogout()"
        [disabled]="
          !parametersStart.buttonLoginLogoutEnabled ||
          parametersStart.isSyncRunning
        "
      >
        {{ parametersStart.buttonLoginLogoutText }}
      </button>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <button
        class="btn btn-default"
        type="button"
        data-toggle="modal"
        data-target="#confirmModal"
        [disabled]="parametersStart.isSyncRunning"
      >
        {{ "START_DELETE_DATABASE" | translate }}
      </button>
    </div>
  </div>
</div>

<!-- Modal confirm dialog -->
<div
  class="modal fade"
  id="confirmModal"
  tabindex="-1"
  aria-labelledby="conrimModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-m">
    <div class="modal-content">
      <div class="modal-header display-flex">
        <h5 class="modal-title" id="confirmModalLabel">
          {{ "START_MODAL_DELETE_CONFIRM_TITLE" | translate }}
        </h5>
        <button
          type="button"
          class="btn-close-modal"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i class="mdi mdi-close color-main"></i>
        </button>
      </div>
      <div class="modal-body">
        <span>{{ "START_MODAL_DELETE_CONFIRM_TEXT" | translate }}</span>

        <!-- demos of selection from modal list -->

        <!-- app-view-customers-select
        (selectedCustomerModal)="selectedCustomerModal($event)"
      >
      </app-view-customers-select -->

        <!-- app-view-articles-select (selectedArticleModal)="selectedArticleModal($event)">
      </app-view-articles-select -->
      </div>
      <div class="modal-footer">
        <button
          type="button"
          (click)="deleteDatabase()"
          class="btn btn-danger"
          data-dismiss="modal"
        >
          {{ "BUTTON_DELETE" | translate }}
        </button>
        <button type="button" class="btn btn-default" data-dismiss="modal">
          {{ "BUTTON_CANCEL" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
