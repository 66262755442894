<div role="main">
  <div class="">
    <div class="page-title">
      <div class="title_left">
        <h3>{{ textTitle }}</h3>
      </div>
    </div>
  </div>

  <div class="clearfix"></div>

  <div class="x_panel">
    <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
      <div class="form-horizontal form-label-left">
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-title"
          >
            {{ "SALE_DELETE_DATE" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-title"
              class="form-control"
              readonly
              type="text"
              [(ngModel)]="sale.textDate"
            />
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-first-name"
          >
            {{ "SALE_DELETE_NUMBER" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-first-name"
              class="form-control"
              type="text"
              readonly
              [(ngModel)]="sale.number"
            />
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-last-name"
          >
            {{ "SALE_DELETE_NAME" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-last-name"
              class="form-control"
              type="text"
              readonly
              [(ngModel)]="sale.name"
            />
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-position"
          >
            {{ "SALE_DELETE_STREET" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-position"
              class="form-control"
              type="text"
              readonly
              [(ngModel)]="sale.street"
            />
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-telephone-1"
          >
            {{ "SALE_DELETE_CITY" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-telephone-1"
              class="form-control"
              type="text"
              readonly
              [(ngModel)]="sale.city"
            />
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-telephone-2"
          >
            {{ "SALE_DELETE_SUM_NET" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-telephone-2"
              class="form-control"
              type="text"
              readonly
              [(ngModel)]="sale.sumNet"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="clearfix"></div>

    <div class="">
      <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
        <div class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"></div>
        <div class="flex-nowrap">
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <button (click)="delete()" class="btn btn-danger button-row">
              <span>{{ "BUTTON_DELETE" | translate }} </span
              ><i class="mdi mdi-delete-outline color-bright"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

