import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonsService } from '../commons-service';
import { ParametersStartService } from '../parameters-start.service'
import { RepositoryService } from '../repository.service';
declare const $: any;
declare const log: any;
import { ViewOpenSalesComponent } from '../view-open-sales/view-open-sales.component';

@Component({
  selector: 'app-view-open-sales-select',
  templateUrl: '../view-open-sales/view-open-sales.component.html',
  styleUrls: ['./view-open-sales-select.component.css']
})
export class ViewOpenSalesSelectComponent extends ViewOpenSalesComponent implements OnInit {

  override showLinks = false

  @Input() type: string = "Order";
  @Output() selectedSale = new EventEmitter<string>();

  constructor(
    router: Router,
    route: ActivatedRoute,
    translate: TranslateService,
    commons: CommonsService,
    repository: RepositoryService,
    parametersStart: ParametersStartService
  ) {
    super(router, route, translate, commons, repository, parametersStart)
  }

  override getTypeSales() {
    return this.type
  }

  override ngOnInit(): void {
    super.ngOnInit()
  }

  override clickedOnSale(sale: any) {
    this.selectedSale.emit(sale)
  }

}
