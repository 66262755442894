import { CommonsService } from "../commons-service";
import { BaseModel } from "./base-model";
import { ImageModel } from "./image-model";
import { ModelInterface } from "./model-interface";
declare const log: any;


export class HavingImagesModel extends BaseModel {

  images: ImageModel[] = []
  uuidsImages: string = ""
  uuidImageSignature: string = ""

  getImagesWithoutSignature(): ImageModel[] {
    let imagesNew: ImageModel[] = [];
    for (let image of this.images) {
      if (image.uuid != this.uuidImageSignature)
        imagesNew.push(image)
    }
    return imagesNew;
  }

  getImage(uuid: string): ImageModel | null {
    for (let image of this.images) {
      if (image.uuid == uuid)
        return image
    }
    return null
  }

  removeImage(uuid: string) {
    let imgsTemp: ImageModel[] = []
    let uuidsTemp = ""
    for (let image of this.images) {
      if (image.uuid != uuid) {
        imgsTemp.push(image)
        if (uuidsTemp != "")
          uuidsTemp += ","
        uuidsTemp += image.uuid
      }
    }
    this.images = imgsTemp
    this.uuidsImages = uuidsTemp
  }

  // getSignature(): ImageModel | null {
  //   for (let image of this.images) {
  //     if (image.name == CommonsService.NAME_FILE_SIGNATURE_IMAGE)
  //       return image
  //   }
  //   return null
  // }

  getSignature(): ImageModel | null {
    for (let image of this.images) {
      if (image.uuid == this.uuidImageSignature)
        return image
    }
    return null
  }

  // deleteSignature() {
  //   let imgsTemp: ImageModel[] = []
  //   let uuidsTemp = ""
  //   for (let image of this.images) {
  //     if (image.name != CommonsService.NAME_FILE_SIGNATURE_IMAGE) {
  //       imgsTemp.push(image)
  //       if (uuidsTemp != "")
  //         uuidsTemp += ","
  //       uuidsTemp += image.uuid
  //     }
  //   }
  //   this.images = imgsTemp
  //   this.uuidsImages = uuidsTemp
  // }

  deleteSignature() {
    let imgsTemp: ImageModel[] = []
    let uuidsTemp = ""
    for (let image of this.images) {
      if (image.uuid != this.uuidImageSignature) {
        imgsTemp.push(image)
        if (uuidsTemp != "")
          uuidsTemp += ","
        uuidsTemp += image.uuid
      }
    }
    this.images = imgsTemp
    this.uuidsImages = uuidsTemp
    this.uuidImageSignature = "";
  }

  // setSignature(signatureImage: ImageModel) {
  //   let imgsTemp: ImageModel[] = []
  //   let uuidsTemp = ""
  //   let foundSignature = false
  //   for (let image of this.images) {
  //     if (image.name != CommonsService.NAME_FILE_SIGNATURE_IMAGE) {
  //       imgsTemp.push(image)
  //       if (uuidsTemp != "")
  //         uuidsTemp += ","
  //       uuidsTemp += image.uuid
  //     } else {
  //       foundSignature = true
  //       // replace signature image
  //       if (signatureImage) {
  //         if (signatureImage.data == image.data) {
  //           // signature has not changed
  //           signatureImage = image
  //         }

  //         imgsTemp.push(signatureImage)
  //         if (uuidsTemp != "")
  //           uuidsTemp += ","
  //         uuidsTemp += signatureImage.uuid
  //       }
  //     }
  //   }
  //   if (!foundSignature && signatureImage) {
  //     // append signature
  //     imgsTemp.push(signatureImage)
  //     if (uuidsTemp != "")
  //       uuidsTemp += ","
  //     uuidsTemp += signatureImage.uuid
  //   }
  //   this.images = imgsTemp
  //   this.uuidsImages = uuidsTemp
  // }

  setSignature(signatureImage: ImageModel) {
    this.deleteSignature()

    this.images.push(signatureImage);
    this.uuidImageSignature = signatureImage.uuid;
  }

  override copyFromObject(object: any): ModelInterface {
    super.copyFromObject(object)

    if (object == null)
      return this;

    let imgsTemp = []
    let uuidsTemp = ""

    if ("uuidsImages" in object && "images" in object) {
      uuidsTemp = object.uuidsImages
      for (let image of object.images)
        imgsTemp.push(new ImageModel(image));
    }
    this.images = imgsTemp
    this.uuidsImages = uuidsTemp

    return this
  }

}
