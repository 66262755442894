import { CommonsService } from "../commons-service";
import { BaseModel } from "./base-model";
import { TaxRateModel } from "./tax-rate-model";
declare const log: any;

export class ArticleModel extends BaseModel {

  number: string = ""
  name: string = ""
  articleGroupId: number = 0
  description: string = ""
  description_plain: string = "" // automatic created plain text from rtf - see BaseModel
  description2: string = ""
  description2_plain: string = "" // automatic created plain text from rtf - see BaseModel
  ean: string = ""
  weightKgUnit: number = 0
  uuidTaxRate: string = ""
  taxRate: TaxRateModel | null = null
  taxFactor: number = 0
  commissionLevel: number = 0
  priceGross: string = ""
  priceGrossV: number = 0
  priceNet: string = ""
  priceNetV: number = 0
  standardDB: string = ""
  standardDBV: number = 0
  standardDBPercent: number = 0
  costPriceV: number = 0
  purchasePriceUnit: string = ""
  purchasePriceUnitV: number = 0
  articleGroupName: string = ""
  uuidArticlegroup: string = ""
  calcCostPricePerPiece: string = ""
  calcCostPricePerPieceV: number = 0
  purchaseCostsV: number = 0
  materialCostsV: number = 0
  costsLabourV: number = 0
  costsRessourcesV: number = 0
  costsOtherV: number = 0
  overheadShare: number = 0

  constructor(params: any = null) {
    super(params)
    this.copyFromObject(params)
  }

  static override newModel(object: any = null) {
    return new ArticleModel(object)
  }

  // 0 to 1
  getTaxFactor() {
    return this.taxRate ? this.taxRate.percentTotal : 0.0
  }

  calcStandardDBFromValues(currency: string) {
    this.standardDBV = this.priceNetV - this.purchasePriceUnitV
    this.standardDB = CommonsService.convertValueToPrice(this.standardDBV, currency);
    if (this.priceNetV != 0)
      this.standardDBPercent = this.standardDBV / this.priceNetV
    else
      this.standardDBPercent = 1
  }

  calcCosts(currency: string) {
    // 106=[103]+[104]+[105]
    this.costPriceV = this.calcCostPricePerPieceV + this.purchaseCostsV + this.materialCostsV

    // 110=([106]+[107]+[108]+[109])*(1.0 + [126])
    this.purchasePriceUnitV = (this.costPriceV + this.costsLabourV + this.costsRessourcesV + this.costsOtherV) * (1.0 + this.overheadShare)
    this.purchasePriceUnit = CommonsService.convertValueToPrice(this.purchasePriceUnitV, currency);

    this.calcStandardDBFromValues(currency)
  }
}
