<div role="main">
  <div class="">
    <div class="page-title">
      <div class="title_left">
        <h3>{{ textTitle }}</h3>
      </div>
    </div>
  </div>

  <div class="clearfix"></div>

  <div class="x_panel">
    <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
      <div class="form-horizontal form-label-left">
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-date"
          >
            {{ "SALE_EDIT_DATE" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <div class="input-group date" id="div-date">
              <input
                id="input-date"
                type="text"
                class="form-control"
                placeholder="dd.mm.yyyy"
                value="{{ textDate }}"
              />
              <!-- onkeydown="return false" -->
              <span class="input-group-addon">
                <span class="glyphicon glyphicon-calendar"></span>
                <!--i class="mdi mdi-calendar icon-datepicker"></i-->
              </span>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3">
            {{ "SALE_EDIT_NUMBER" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              class="form-control"
              type="text"
              [(ngModel)]="sale.number"
              name="customer.nummber"
              readonly
            />
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-name"
          >
            {{ "SALE_EDIT_NAME" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-name"
              class="form-control"
              type="text"
              [(ngModel)]="sale.name"
              name="sale.name"
            />
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-street"
          >
            {{ "SALE_EDIT_STREET" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-street"
              class="form-control"
              type="text"
              [(ngModel)]="sale.street"
              name="sale.street"
            />
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-zip-code"
          >
            {{ "SALE_EDIT_ZIP" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-zip-code"
              class="form-control"
              type="text"
              [(ngModel)]="sale.zipCode"
              name="sale.zipCode"
            />
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-city"
          >
            {{ "SALE_EDIT_CITY" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-city"
              class="form-control"
              type="text"
              [(ngModel)]="sale.city"
              name="sale.city"
            />
          </div>
        </div>
        <div class="form-group">
          <label
          class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="select-country"
          >
            {{ "SALE_EDIT_COUNTRY" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <select
              id="select-country"
              class="form-control"
              [(ngModel)]="sale.uuidCountry"
              name="sale.uuidCountry"
            >
              <option
                *ngFor="let country of countries"
                [ngValue]="country.uuid"
              >
                {{ country.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-discount"
          >
            {{ "SALE_EDIT_DISCOUNT" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              id="input-discount"
              class="form-control"
              type="text"
              (change)="changeDiscount()"
              [(ngModel)]="discount"
              name="discount"
            />
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="select-payment-type"
          >
            {{ "SALE_EDIT_PAYMENTTYPE" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <select
              id="select-payment-type"
              class="input-block-level form-control"
              [(ngModel)]="sale.uuidPaymentType"
              name="sale.uuidPaymentType"
            >
              <option
                *ngFor="let paymentType of paymentTypes"
                [ngValue]="paymentType.uuid"
              >
                {{ paymentType.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group" [hidden]="!enableCancellationReasons">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="select-cancellation-reason"
          >
            {{ "OPEN_OFFER_VIEW_REJECTION_REASON" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <select
              id="select-cancellation-reason"
              class="input-block-level form-control"
              [(ngModel)]="sale.uuidCancellationReason"
              (ngModelChange)="cancellationReasonChanged()"
            >
              <option
                *ngFor="let cancellationReason of cancellationReasons"
                [ngValue]="cancellationReason.uuid"
              >
                {{ cancellationReason.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group" [hidden]="!enableStatesOrder">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="select-state-order"
          >
            {{ "OPEN_OFFER_VIEW_STATE_ORDER" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <select
              id="select-state-order"
              class="input-block-level form-control"
              [(ngModel)]="saleAsOrder.uuidStateOrder"
            >
              <option
                *ngFor="let stateOrder of statesOrder"
                [ngValue]="stateOrder.uuid"
              >
                {{ stateOrder.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="clearfix"></div>

        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
          ></label>
          <div class="flex-nowrap">
            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
              <button
                (click)="saveSale(true)"
                class="btn btn-primary button-row"
              >
                <span>{{ "BUTTON_SAVE" | translate }} </span
                ><i class="mdi mdi-content-save color-bright"></i>
              </button>
              <button
                (click)="printSale()"
                class="btn btn-primary button-row"
                title="{{ 'SALE_EDIT_BUTTON_PRINT_TOOLTIP' | translate }}"
              >
                <span>{{ "SALE_EDIT_BUTTON_PRINT" | translate }} </span>
                <i class="mdi mdi-printer color-bright"></i>
              </button>
            </div>
          </div>
        </div>

        <div
          class="form-group"
          [hidden]="!showButtonMakeOrder && !showButtonMakeInvoice"
        >
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
          ></label>
          <div class="flex-nowrap">
            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
              <button
                [hidden]="!showButtonMakeOrder"
                (click)="makeOrder()"
                [ngClass]="
                  enableButtonMakeOrder
                    ? 'btn btn-primary button-row'
                    : 'btn btn-dark button-row'
                "
                data-toggle="tooltip"
                data-placement="top"
                title="{{ 'SALE_EDIT_BUTTON_MAKE_ORDER_TOOLTIP' | translate }}"
              >
                <span>{{ "SALE_EDIT_BUTTON_MAKE_ORDER" | translate }} </span>
                <i class="mdi mdi-clipboard-text-play-outline color-bright"></i>
              </button>
              <button
                [hidden]="!showButtonMakeInvoice"
                (click)="makeInvoice()"
                [ngClass]="
                  enableButtonMakeInvoice
                    ? 'btn btn-primary button-row'
                    : 'btn btn-dark button-row'
                "
                data-toggle="tooltip"
                data-placement="top"
                title="{{
                  'SALE_EDIT_BUTTON_MAKE_INVOICE_TOOLTIP' | translate
                }}"
              >
                <span>{{ "SALE_EDIT_BUTTON_MAKE_INVOICE" | translate }} </span>
                <i class="mdi mdi-script-text-outline color-bright"></i>
              </button>
            </div>
          </div>
        </div>

        <div class="clearfix"></div>

        <div
          class="form-group"
          [hidden]="
            !showButtonCustomer &&
            !showLinkOffer &&
            !showLinkOrder &&
            !showLinkInvoice
          "
        >
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
          ></label>
          <div class="flex-nowrap">
            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
              <span
                class="button-row"
                [hidden]="!showButtonCustomer"
                (click)="gotoCustomer()"
                data-toggle="tooltip"
                data-placement="top"
                title="{{ 'SALE_EDIT_TOOLTIP_CUSTOMER' | translate }}"
              >
                <i
                  [ngClass]="
                    enableButtonCustomer
                      ? 'mdi mdi-account color-main'
                      : 'mdi mdi-account aero'
                  "
                ></i
              ></span>
              <span
                class="button-row"
                [hidden]="!showLinkOffer"
                (click)="gotoOffer(); $event.stopPropagation()"
                data-toggle="tooltip"
                data-placement="top"
                title="{{ 'SALE_EDIT_BUTTON_OFFER_TOOLTIP' | translate }}"
              >
                <i
                  [ngClass]="
                    enableLinkOffer
                      ? 'mdi mdi-clipboard-text-outline color-main'
                      : 'mdi mdi-clipboard-text-outline aero'
                  "
                ></i>
              </span>
              <span
                class="button-row"
                [hidden]="!showLinkOrder"
                (click)="gotoOrder(); $event.stopPropagation()"
                data-toggle="tooltip"
                data-placement="top"
                title="{{ 'SALE_EDIT_BUTTON_ORDER_TOOLTIP' | translate }}"
              >
                <i
                  [ngClass]="
                    enableLinkOrder
                      ? 'mdi mdi-clipboard-text-play-outline color-main'
                      : 'mdi mdi-clipboard-text-play-outline aero'
                  "
                ></i>
              </span>
              <span
                class="button-row"
                [hidden]="!showLinkInvoice"
                (click)="gotoInvoice(); $event.stopPropagation()"
                data-toggle="tooltip"
                data-placement="top"
                title="{{ 'SALE_EDIT_BUTTON_INVOICE_TOOLTIP' | translate }}"
              >
                <i
                  [ngClass]="
                    enableLinkInvoice
                      ? 'mdi mdi-script-text-outline color-main'
                      : 'mdi mdi-script-text-outline aero'
                  "
                ></i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <table class="table table-striped">
      <thead>
        <tr>
          <th class="table-header-col-no-sort">
            {{ "SALE_EDIT_TABLE_NAME" | translate }}
          </th>
          <th class="tableColWidthM table-header-col-no-sort">
            {{ "SALE_EDIT_TABLE_AMOUNT" | translate }}
          </th>
          <th class="tableColWidthM table-header-col-no-sort">
            {{ "SALE_EDIT_TABLE_PRICE_UNIT_NET" | translate }}
          </th>
          <th class="tableColWidthM table-header-col-no-sort">
            {{ "SALE_EDIT_TABLE_PRICE_UNIT_GROSS" | translate }}
          </th>
          <th class="tableColWidthM table-header-col-no-sort">
            {{ "SALE_EDIT_TABLE_PRICE_SUM_NET" | translate }}
          </th>
          <th class="tableColWidthM table-header-col-no-sort">
            {{ "SALE_EDIT_TABLE_PRICE_SUM_GROSS" | translate }}
          </th>
          <!--th class="tableColWidthIcon"></th>
              <th class="tableColWidthIcon"></th -->
          <th class="tableColWidthIcon">
            <div
              class="table-header-col"
              (click)="addArticle()"
              data-toggle="tooltip"
              data-placement="top"
              title="{{ 'SALE_EDIT_TABLE_TOOLTIP_ADD' | translate }}"
            >
              <i class="mdi mdi-plus-circle indicator-color-green"></i>
            </div>
          </th>
          <!--  th class="tableColWidthIcon"></th -->
        </tr>
      </thead>
      <tbody>
        <tr
          class="position item"
          *ngFor="let position of sale.positions; let i = index"
        >
          <td (click)="clickedOnPosition(position)">{{ position.name }}</td>
          <td (click)="clickedOnPosition(position)">{{ position.amount }}</td>
          <td (click)="clickedOnPosition(position)" class="noWrap">
            {{ position.priceUnitNet }}
          </td>
          <td (click)="clickedOnPosition(position)" class="noWrap">
            {{ position.priceUnitGross }}
          </td>
          <td (click)="clickedOnPosition(position)" class="noWrap">
            {{ position.priceSumNet }}
          </td>
          <td (click)="clickedOnPosition(position)" class="noWrap">
            {{ position.priceSumGross }}
          </td>

          <td ng-show="1">
            <div class="dropdown">
              <button
                class="btn btn-sm btn-default dropdown-toggle btn-action"
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {{ "GENERAL_TABLE_DROPDOWN_ACTIONS" | translate | capitalize }}
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <li [hidden]="i == 0">
                  <a
                    class="dropdown-item cursor-pointer"
                    (click)="movePositionUp(i); $event.stopPropagation()"
                    data-toggle="tooltip"
                    data-placement="top"
                  >
                    <i class="mdi mdi-arrow-up"></i>&nbsp;{{
                      "SALE_EDIT_TABLE_TOOLTIP_MOVE_UP" | translate | capitalize
                    }}</a
                  >
                </li>

                <li [hidden]="i == sale.getNumPositions() - 1">
                  <a
                    class="dropdown-item cursor-pointer"
                    (click)="movePositionDown(i); $event.stopPropagation()"
                    data-toggle="tooltip"
                    data-placement="top"
                  >
                    <i class="mdi mdi-arrow-down"></i>&nbsp;{{
                      "SALE_EDIT_TABLE_TOOLTIP_MOVE_DOWN"
                        | translate
                        | capitalize
                    }}</a
                  >
                </li>

                <li>
                  <a
                    class="dropdown-item"
                    routerLink="/editSalePosition/{{ typeSale }}/{{
                      position.uuid
                    }}/{{ isSaleNew }}"
                    ng-click="$event.stopPropagation();"
                    data-toggle="tooltip"
                    data-placement="top"
                  >
                    <i class="mdi mdi-pencil"></i>&nbsp;{{
                      "SALE_EDIT_TABLE_TOOLTIP_EDIT" | translate | capitalize
                    }}</a
                  >
                </li>

                <li>
                  <a
                    class="dropdown-item"
                    (click)="deleteSalePosition(position)"
                    ng-click="$event.stopPropagation();"
                    data-toggle="tooltip"
                    data-placement="top"
                  >
                    <i class="mdi mdi-delete-outline"></i>&nbsp;{{
                      "SALE_EDIT_TABLE_TOOLTIP_DELETE" | translate | capitalize
                    }}</a
                  >
                </li>
              </ul>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
      <div class="container-column-left">
        <button
          class="btn btn-default button-row"
          (click)="addArticle()"
          data-toggle="tooltip"
          data-placement="top"
          title="{{ 'SALE_EDIT_TOOLTIP_NEW_POSITION' | translate }}"
        >
          {{ "SALE_EDIT_BUTTON_NEW_POSITION" | translate
          }}<i class="mdi mdi-plus-circle indicator-color-green"></i>
        </button>
      </div>
    </div>

    <div class="col-lg-6 col-md-4 col-sm-4 col-xs-12">
      <div class="container-column-right">
        <div class="row moveup30px">
          <div class="align-right" align="right">
            {{ "SALE_EDIT_SUM_NET" | translate }}: {{ sale.sumNet }}
          </div>
        </div>

        <div class="row moveup10px">
          <div class="align-right" align="right">
            {{ "SALE_EDIT_SUM_GROSS" | translate }}: {{ sale.sumGross }}
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
      <div class="form-horizontal form-label-left">
        <div class="form-group" [hidden]="!enableSignaturePad">
          <!-- <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-0"
            for=""
          >
          </label> -->
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div id="signatureWrapper" class="signatureWrapper">
              <!-- canvas id="signatureShadowCanvas" ng-disabled="true" class="shadowCanvas"></canvas -->
              <!-- div id="signaturePadWrapper" class="wrapperSignaturePad" -->
              <!-- img class="img2" src="http://www.licensing.biz/cimages/c0f1a3a97bc0de97271f9813f7715bb3.png" width=400 height=200 / -->
              <img
                class="img-signature-pad"
                id="img-signature-pad"
                src="../../assets/Images/backgroundSignaturePad.png"
                width="400"
                height="200"
                style="height: 300px"
              />
              <canvas id="canvas-signature-pad" class="signature-pad"></canvas>
              <!-- /div -->
            </div>
          </div>
        </div>

        <div class="form-group" [hidden]="!enableSignaturePad">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
          ></label>
          <div class="flex-nowrap">
            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
              <button
                (click)="saveSale(true)"
                class="btn btn-primary button-row"
              >
                <span>{{ "BUTTON_SAVE" | translate }} </span
                ><i class="mdi mdi-content-save color-bright"></i>
              </button>
              <button
                class="btn btn-default button-row"
                (click)="clearSignaturePad()"
                type="button"
                title="{{
                  'SALE_EDIT_BUTTON_CLEAR_SIGNATURE_PAD_TOOLTIP' | translate
                }}"
              >
                <span>{{ "BUTTON_CLEAR" | translate }} </span>
                <i class="mdi mdi-cancel color-main"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
  </div>

  <!-- Modal select article dialog -->
  <div
    class="modal fade"
    id="selectArticleModal"
    tabindex="-1"
    aria-labelledby="conrimModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header display-flex">
          <h5 class="modal-title" id="confirmModalLabel">
            {{ "SALE_EDIT_SELECT_ARTICLE_TITLE" | translate }}
          </h5>
          <button
            type="button"
            class="btn-close-modal"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i class="mdi mdi-close color-main"></i>
          </button>
        </div>
        <div class="modal-body modal-body-select">
          <app-view-articles-select
            (selectedArticleModal)="selectedArticleModal($event)"
          >
          </app-view-articles-select>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" data-dismiss="modal">
            {{ "BUTTON_CANCEL" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
