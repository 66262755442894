<div role="main">
  <div class="">
    <div class="page-title">
      <div class="title_left">
        <h3>{{ "TASK_DELETE_TITLE" | translate }}</h3>
      </div>
    </div>
  </div>

  <div class="clearfix"></div>

  <div class="x_panel">
    <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
      <div class="form-horizontal form-label-left">
        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="checkbox-done"
          >
            {{ "TASK_DELETE_DONE" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              readonly
              [disabled]="true"
              style="top: 6px; position: relative"
              id="checkbox-done"
              class=""
              type="checkbox"
              [(ngModel)]="doneBool"
            />
          </div>
        </div>

        <div class="form-group">
          <div class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3">
            {{ "TASK_DELETE_DUE" | translate }}:
          </div>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              class="form-control"
              type="text"
              readonly
              [(ngModel)]="textDue"
            />
          </div>
        </div>

        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="input-priority"
          >
            {{ "TASK_DELETE_PRIORITY" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <input
              readonly
              id="input-priority"
              class="form-control"
              type="number"
              min="0.0"
              max="100.0"
              step="1"
              [(ngModel)]="task.priority"
              name="task.priority"
            />
          </div>
        </div>

        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="textarea-title"
          >
            {{ "TASK_DELETE_SUBJECT" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <textarea
              class="form-control"
              rows="3"
              id="textarea-title"
              [(ngModel)]="task.title"
              name="task.title"
              readonly
            ></textarea>
          </div>
        </div>

        <div class="form-group">
          <label
            class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"
            for="textarea-description"
          >
            {{ "TASK_DELETE_DESCRIPTION" | translate }}
          </label>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <textarea
              class="form-control"
              rows="3"
              id="textarea-description"
              [(ngModel)]="task.description"
              name="task.description"
              readonly
            ></textarea>
          </div>
        </div>
      </div>
    </div>

    <div class="clearfix"></div>

    <div class="">
      <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
        <div class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-3"></div>
        <div class="flex-nowrap">
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9">
            <button (click)="delete()" class="btn btn-danger button-row">
              <span>{{ "BUTTON_DELETE" | translate }} </span
              ><i class="mdi mdi-delete-outline color-bright"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

