import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CommonsService } from '../commons-service';
import { ParametersStartService } from '../parameters-start.service'
import { RepositoryService } from '../repository.service';
import { ArticleModel } from '../models/article-model';
import { ArticleGroupModel } from '../models/article-group-model';
declare const log: any;

@Component({
  selector: 'app-view-articles',
  templateUrl: './view-articles.component.html',
  styleUrls: ['./view-articles.component.css']
})
export class ViewArticlesComponent implements OnInit {

  articleGroups: ArticleGroupModel[] = []
  articles: ArticleModel[] = [];
  showLinks = true;
  currentPage: number = 0
  textSearch = ""
  sort = {
    column: "Artikelnr_5",
    ascending: true
  }
  pageSize = environment.entriesPerPage;

  iRowHighlighted = -1

  @ViewChild('idInputTextSearch') inputTextSearch!: ElementRef;

  constructor(
    private router: Router,
    private repository: RepositoryService,
    private parametersStart: ParametersStartService
  ) {
  }

  ngOnInit(): void {
    this.init()
  }

  async init() {
    this.textSearch = await this.repository.readUtilityData("searchArticles")
    this.sort.column = await this.repository.readUtilityData("sortArticlesColumn")
    this.currentPage = +await this.repository.readUtilityData("showArticlesPage")
    this.sort.ascending = "true" == await this.repository.readUtilityData("sortArticlesAscending")

    this.articleGroups = await this.repository.articleGroups.readAll()

    this.search();
  }

  async search() {
    await this.loadArticlesSearch()

    this.setFocusToSearchField()

    if (this.currentPage > this.numberOfPages() - 1) {
      this.currentPage = this.numberOfPages() - 1;
      this.storeCurrentPage();
    }

    await this.repository.updateUtilityData("searchArticles", this.textSearch)
  }

  setFocusToSearchField() {
    setTimeout(() => {
      this.inputTextSearch?.nativeElement.focus()
    }, CommonsService.TIME_DELAY_FOCUS_MS)
    this.iRowHighlighted = -1
  }

  resetSearch() {
    this.textSearch = ""
    this.sort.column = "number"
    this.currentPage = 0
    this.sort.ascending = true
    this.search();
  }

  numberOfPages() {
    if (this.articles && this.articles.length > 0)
      return Math.ceil(this.articles.length / this.pageSize);
    else
      return 1;
  }

  storeCurrentPage() {
    this.repository.updateUtilityData("showArticlesPage", "" + this.currentPage);
    this.setFocusToSearchField()
  }

  async loadArticlesSearch() {
    var textSearchLC = "";
    if (this.textSearch && typeof this.textSearch != 'undefined' && this.textSearch != "")
      textSearchLC = this.textSearch.toLowerCase();

    let articlesL = await this.repository.articles.readAll(this.sort.column, this.sort.ascending)

    articlesL.forEach((article: ArticleModel) => {
      CommonsService.setPrices(article, this.parametersStart.currency)

      let articleGroup: ArticleGroupModel = CommonsService.getObjectByUuid(this.articleGroups, article.uuidArticlegroup)
      if (articleGroup != null) {
        article.articleGroupName = articleGroup.name
      }
    });

    // in case the sort column is the article group name
    CommonsService.sort(articlesL, this.sort.column, this.sort.ascending)

    articlesL = articlesL.filter((article: ArticleModel) => {
      if (
        textSearchLC == "" ||
        article.number.toLowerCase().indexOf(textSearchLC) >= 0 ||
        article.ean.toLowerCase().indexOf(textSearchLC) >= 0 ||
        article.name.toLowerCase().indexOf(textSearchLC) >= 0 ||
        article.articleGroupName.toLowerCase().indexOf(textSearchLC) >= 0)
        return true
      else
        return false
    })

    this.articles = articlesL

    this.setFocusToSearchField()
  }

  timeLastSorting = new Date();
  async changeSorting(column: string) {
    var timeNow = new Date();
    if (timeNow.getTime() - this.timeLastSorting.getTime() < CommonsService.TIME_TRIGGER_SORTING_MS)
      return;
    this.timeLastSorting = new Date();

    var sort = this.sort;

    if (sort.column == column) {
      sort.ascending = !sort.ascending;
    } else {
      sort.column = column;
      sort.ascending = false;
    }

    const this_ = this;
    await this.repository.updateUtilityData("sortArticlesColumn", this.sort.column)
    await this.repository.updateUtilityData("sortArticlesAscending", "" + this.sort.ascending);

    setTimeout(function () {
      this_.loadArticlesSearch();
    }, 100);
  };

  clickedOnArticle(article: any) {
    this.selectedArticle(article)
  }

  enterEvent(event: any) {
    if (this.iRowHighlighted == -1)
      return

    let iArticle = this.currentPage * this.pageSize + this.iRowHighlighted

    let article = this.articles[iArticle]

    this.selectedArticle(article)

    event.preventDefault()
    return false
  }

  selectedArticle(article: any) {
    this.router.navigateByUrl("/viewArticle/" + article.uuid);
  }

  private keyTimeout: any = null;

  keyUpEvent(event: any) {
    if (event.key.length != 1 && event.key != "Backspace") {
      event.preventDefault()
      return false;
    }

    if (this.keyTimeout) {
      clearTimeout(this.keyTimeout);
      this.keyTimeout = null;
    }

    this.keyTimeout = setTimeout(() => {
      this.search();
    }, 300)

    return true
  }

  arrowDownEvent(event: any) {
    if (this.iRowHighlighted < this.pageSize - 1 &&
      this.pageSize * this.currentPage + this.iRowHighlighted < this.articles.length - 1) {
      this.iRowHighlighted++
      this.scrollRowIntoView()
    } else if (this.iRowHighlighted == this.pageSize - 1 &&
      this.currentPage < this.numberOfPages() - 1) {
      this.currentPage++
      this.storeCurrentPage()
      this.iRowHighlighted = 0
      this.scrollRowIntoView()
    }

    event.preventDefault()
    return false
  }

  arrowUpEvent(event: any) {
    if (this.iRowHighlighted > 0) {
      this.iRowHighlighted--
      this.scrollRowIntoView()
    } else if (this.currentPage > 0) {
      this.currentPage--
      this.storeCurrentPage()
      this.iRowHighlighted = this.pageSize - 1
      setTimeout(() => {
        this.scrollRowIntoView();
      }, 200)
    }

    event.preventDefault()
    return false
  }

  scrollRowIntoView() {
    const element = document.getElementById("id-table-articles-row-" + this.iRowHighlighted);
    if (element != null)
      element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
  }

  gotoAddArticle() {
    this.router.navigateByUrl("/addArticle")
  }
}
