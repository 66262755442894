import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from '../custom-date-parser-formatter'
import { CommonsService } from '../commons-service';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { UUID } from 'angular2-uuid';
import { RepositoryService } from '../repository.service';
import { CustomerModel } from '../models/customer-model';
import { ContactTypeModel } from '../models/contact-type-model';
import { CustomerContactModel } from '../models/customer-contact-model';
declare const log: any
declare const dataModels: any
declare const $: any

@Component({
  selector: 'app-add-customer-contact',
  templateUrl: './add-customer-contact.component.html',
  styleUrls: ['./add-customer-contact.component.css'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
  ]
})
export class AddCustomerContactComponent implements OnInit {

  textTitle = ""
  uuidCustomer = ""
  customerContact: CustomerContactModel = new CustomerContactModel()
  contactTypes: ContactTypeModel[] = []
  textDate = CommonsService.formatDate0(new Date())

  constructor(
    protected location: Location,
    protected route: ActivatedRoute,
    protected router: Router,
    protected translate: TranslateService,
    protected repository: RepositoryService,
  ) { }

  ngOnInit(): void {
    this.init0()
  }

  async init0() {
    this.contactTypes = <ContactTypeModel[]>await this.repository.contactTypes.readAll()
    await this.initSpecific()
  }

  async initSpecific() {
    this.translate.get("CONTACT_ADD_TITLE").subscribe((translated) => this.textTitle = translated)

    const routeParams = this.route.snapshot.paramMap;
    this.uuidCustomer = String(routeParams.get('uuidCustomer'));

    this.customerContact.uuid = UUID.UUID(),
      this.customerContact.uuidContactType = this.contactTypes[0].uuid
    this.customerContact.uuidCustomer = this.uuidCustomer

    await this.init()
  }

  async init() {

    $('#div-date').datetimepicker({
      format: 'DD.MM.YYYY'
    });

    this.textDate = CommonsService.formatDate0(this.customerContact.date)
  }

  async save() {
    this.customerContact.date = CommonsService.parseDate($("#input-date").val())

    await this.saveSpecific()
  }

  async saveSpecific() {
    await this.repository.createCustomerContact(this.customerContact)

    this.location.back()
  }
}
