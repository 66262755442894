import { Component, OnInit } from '@angular/core';
import { CommonsService } from '../commons-service';
import { CustomerModel } from '../models/customer-model';
import { RepositoryService } from '../repository.service';

@Component({
  selector: 'app-view-customers-sugg-freq',
  templateUrl: './view-customers-sugg-freq.component.html',
  styleUrls: ['./view-customers-sugg-freq.component.css']
})
export class ViewCustomersSuggFreqComponent implements OnInit {

  customers: CustomerModel[] = []

  constructor(
    private repository: RepositoryService
  ) { }

  ngOnInit(): void {
    this.init()
  }

  async init() {
    let customersR = <CustomerModel[]>await this.repository.customers.readAll("lastName", true)

    let dateMin = new Date(0);

    let dateCompare = new Date();
    dateCompare.setHours(23);
    dateCompare.setMinutes(59);
    dateCompare.setSeconds(59);
    dateCompare.setMilliseconds(999);

    customersR = customersR.filter((customer: CustomerModel) => {
      if (customer.lastContact &&
        customer.lastContact.getTime() > dateMin.getTime() &&
        customer.contactFrequencyDays) {

        if (Number.isInteger(customer.contactFrequencyDays) && customer.contactFrequencyDays > 0) {
          var dateNextFreq = new Date(customer.lastContact);

          dateNextFreq.setDate(dateNextFreq.getDate() + customer.contactFrequencyDays);

          if (dateNextFreq.getTime() <= dateCompare.getTime()) {
            customer.dateNextFreq = dateNextFreq;
            customer.textNextContact = CommonsService.formatDate0(dateNextFreq);
            return true
          }
        }
      }

      return false
    })

    customersR.sort(function (customerA, customerB) {
      return customerA.dateNextFreq.getTime() - customerB.dateNextFreq.getTime();
    });

    this.customers = customersR
  }

}
