import { BaseModel } from "./base-model";

export class CancellationReasonModel extends BaseModel {
  name: string = ""

  constructor(params: any = null) {
    super(params)
    this.copyFromObject(params)
  }

  static override newModel(params: any): CancellationReasonModel {
    return new CancellationReasonModel(params)
  }
}
