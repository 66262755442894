import { CommonsService } from "../commons-service";
import { ModelFactory } from "./model-factory";
import { ModelInterface } from "./model-interface";
import { OrderPositionModel } from "./order-position-model";
import { SaleModel } from "./sale-model";

export class OrderModel extends SaleModel {
  uuidOffer: string = ""
  uuidInvoice: string = ""
  uuidStateOrder: string = ""
  booked: boolean = false

  constructor(params: any = null) {
    super(params)
    this.copyFromObject(params)
    this.textDate = CommonsService.formatDate0(this.date)
  }

  static override newModel(object: any = null) {
    return new OrderModel(object)
  }

  override clone(modelFactory: ModelFactory<ModelInterface>): OrderModel {
    let clone: OrderModel = new OrderModel(this)
    clone.positions =
      <OrderPositionModel[]>modelFactory.newModels(OrderPositionModel, this.positions)
    return clone
  }
}
