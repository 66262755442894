<div class="">
  <div class="page-title">
    <div class="title_left">
      <h3>{{ textTitle }}</h3>
    </div>
  </div>
</div>

<div class="clearfix"></div>

<div class="x_panel">
  <div class="x_title display-flex">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding: 0px">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            placeholder="{{ 'CUSTOMERS_TOOLTIP_SEARCH' | translate }}..."
            type="text"
            [(ngModel)]="textSearch"
            (keyup)="keyUpEvent($event)"
          />
          <!-- (keyup.enter)="search()" -->
          <span class="input-group-btn">
            <button
              class="btn btn-default"
              type="button"
              (click)="search()"
              data-toggle="tooltip"
              data-placement="top"
            >
              {{ "BUTTON_SEARCH" | translate }}
              <i class="mdi mdi-magnify"></i>
            </button>
          </span>
        </div>
      </div>

      <div class="visible-xs col-xs-12" style="margin-bottom: 8px"></div>

      <div class="col-lg-2 col-md-3 col-sm-3 col-xs-6">
        <button type="button" class="btn btn-default" (click)="reload()">
          {{ "BUTTON_RELOAD" | translate }}
          <i class="mdi mdi-refresh"></i>
        </button>
      </div>

      <div class="col-lg-2 col-md-2 col-sm-3 col-xs-6">
        <span class="button-row" style="top: 5px; position: relative"
          >{{ "MAINTENANCES_CHECK_OWN_ONLY" | translate }}
        </span>
        <span class="display-inline-block">
          <input
            style="top: 6px; position: relative"
            type="checkbox"
            [(ngModel)]="showOwnOnly"
            (ngModelChange)="showOwnOnlyChanged()"
        /></span>
      </div>
    </div>
  </div>

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <div class="x_content">
      <table class="table table-striped">
        <thead>
          <tr>
            <th class="table-header-col-no-sort">
              {{ "MAINTENANCES_TABLE_NUMBER" | translate }}
            </th>
            <th class="table-header-col-no-sort">
              {{ "MAINTENANCES_TABLE_BEGIN" | translate }}
            </th>
            <th class="table-header-col-no-sort">
              {{ "MAINTENANCES_TABLE_CUSTOMER_NAME" | translate }}
            </th>
            <th class="table-header-col-no-sort">
              {{ "MAINTENANCES_TABLE_CUSTOMER_FIRST_NAME" | translate }}
            </th>
            <th class="table-header-col-no-sort">
              {{ "MAINTENANCES_TABLE_CUSTOMER_STREET" | translate }}
            </th>
            <th class="table-header-col-no-sort">
              {{ "MAINTENANCES_TABLE_CUSTOMER_ZIP_CODE" | translate }}
            </th>
            <th class="table-header-col-no-sort">
              {{ "MAINTENANCES_TABLE_CUSTOMER_CITY" | translate }}
            </th>
            <th class="table-header-col-no-sort">
              {{ "MAINTENANCES_TABLE_CUSTOMER_TELEPHONE" | translate }}
            </th>
            <th class="table-header-col-no-sort">
              {{ "MAINTENANCES_TABLE_ARTICLE_NAME" | translate }}
            </th>
            <th class="table-header-col-no-sort">
              {{ "MAINTENANCES_TABLE_DEVICE_NAME" | translate }}
            </th>
            <th class="table-header-col-no-sort">
              {{ "MAINTENANCES_TABLE_IS_OWN" | translate }}
            </th>
            <th class="table-header-col-no-sort"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let maintenance of maintenances; let i = index">
            <td routerLink="/editMaintenance/{{ maintenance.uuid }}">
              {{ maintenance.number }}
            </td>
            <td routerLink="/editMaintenance/{{ maintenance.uuid }}">
              {{ maintenance.textBegin }}
            </td>
            <td routerLink="/editMaintenance/{{ maintenance.uuid }}">
              {{ maintenance.customerName }}
            </td>
            <td routerLink="/editMaintenance/{{ maintenance.uuid }}">
              {{ maintenance.customerFirstName }}
            </td>
            <td routerLink="/editMaintenance/{{ maintenance.uuid }}">
              {{ maintenance.customerStreet }}
            </td>
            <td routerLink="/editMaintenance/{{ maintenance.uuid }}">
              {{ maintenance.customerZipCode }}
            </td>
            <td routerLink="/editMaintenance/{{ maintenance.uuid }}">
              {{ maintenance.customerCity }}
            </td>
            <td routerLink="/editMaintenance/{{ maintenance.uuid }}">
              {{ maintenance.customerTelephone }}
            </td>
            <td routerLink="/editMaintenance/{{ maintenance.uuid }}">
              {{ maintenance.articleName }}
            </td>
            <td routerLink="/editMaintenance/{{ maintenance.uuid }}">
              {{ maintenance.deviceName }}
            </td>
            <td
              [class.disabled]="!maintenance.isOwn"
              routerLink="/editMaintenance/{{ maintenance.uuid }}"
            >
              <input type="checkbox" disabled [checked]="maintenance.isOwn" />
            </td>

            <td ng-show="1">
              <div class="dropdown">
                <button
                  class="btn btn-sm btn-default dropdown-toggle btn-action"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {{
                    "GENERAL_TABLE_DROPDOWN_ACTIONS" | translate | capitalize
                  }}
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/editMaintenance/{{ maintenance.uuid }}"
                    >
                      <i class="mdi mdi-pencil"></i>&nbsp;{{
                        "MAINTENANCES_TABLE_TOOLTIP_EDIT"
                          | translate
                          | capitalize
                      }}</a
                    >
                  </li>
                  <li [hidden]="maintenance.customer == null">
                    <a
                      class="dropdown-item"
                      routerLink="/editCustomer/{{ maintenance.uuidCustomer }}"
                    >
                      <i class="mdi mdi-account"></i>&nbsp;{{
                        "MAINTENANCES_TABLE_TOOLTIP_CUSTOMER"
                          | translate
                          | capitalize
                      }}</a
                    >
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/deleteMaintenance/{{ maintenance.uuid }}"
                      ng-click="$event.stopPropagation();"
                      data-toggle="tooltip"
                      data-placement="top"
                      id="{{
                        'MAINTENANCES_TABLE_TOOLTIP_DELETE'
                          | translate
                          | capitalize
                      }}"
                    >
                      <i class="mdi mdi-delete-outline"></i>&nbsp;{{
                        "MAINTENANCES_TABLE_TOOLTIP_DELETE"
                          | translate
                          | capitalize
                      }}</a
                    >
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
